import React from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Card,
  Col,
  Row,
  InputGroup,
  FormControl
} from "react-bootstrap";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,
  AddPackageHeading
} from "./styled";
import "./index.css";
import { Redirect } from "react-router-dom";
import {
  MdPermIdentity,
  MdHome,
  MdFiberPin,
  MdCreditCard,
  MdRefresh,
  MdAddCircle,
  MdRemoveCircle,
  MdPowerSettingsNew,
  MdArrowBack,
  MdPersonPin
} from "react-icons/md";
import loadinggif from "../../assets/loding-gif.gif";
import API from "../../Api";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import DialogBoxs from "./DialodBoxs";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
import GeneralValues from '../../GobelElements/Values';
import { FaPlus,FaCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';

export default class index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      lcoaddress: "",
      lcocity: "",
      lcocode: "",
      lcocountry: "",
      lcodistrict: "",
      lcoemail: "",
      lcomandal: "",
      lcomobile: "",
      lcoName: "",
      lcopincode: "",
      lcoBalance: "",
      isShowChangePasswordDialog: false,
      newPassword: "",
      isShowSuccessPasswordChangedDialog: false,
      isShowErrorPasswordChangedDialog: false,
      inStockListSearchText: "",
           inStockDataList: [],
           lcoTaxNumber:0
    };

    this.getLCO_Details();
    //  this.getdata1()
  }

  hindChangePasswordDialog() {
    this.setState({ isShowChangePasswordDialog: false });
  }
  hindErrorPasswordChangedDialog() {
    this.setState({ isShowErrorPasswordChangedDialog: false });
  }

  getLCO_Details() {
    debugger;
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then(response => response.json())
      .then(responsejson => {
        this.setState({
          lcoName: responsejson.lconame,
          lcoBalance: responsejson.balance,
          isSpinnerVisible: false,
          lcoaddress: responsejson.lcoaddress,
          lcocity: responsejson.lcocity,
          lcomobile: responsejson.lcomobile,
          lcocode: responsejson.lcocode,
          lcocountry: responsejson.lcocountry,
          lcodistrict: responsejson.lcodistrict,
          lcoemail: responsejson.lcoemail,
          lcomandal: responsejson.lcomandal,
          lcopincode: responsejson.lcopincode,
          lcoTaxNumber: responsejson.lcotaxnumber ? responsejson.lcotaxnumber : 0
        });
        console.log("rsponse", responsejson);
      });
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  backTOTrandactions() {
    this.props.onMoveToTransactions();
  }

  changePasswordFunc(text) {
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName, newPassword } = this.state;
    API.changePasswordAPI(
      lcoID,
      lcoPassword,
      "changepassword",
      text,
      domainName
    )
      .then(response => response.json())
      .then(resposeJson => {
        console.log("res", resposeJson);
        if (resposeJson.message != "error user not authenticated") {
          this.setState({ isShowSuccessPasswordChangedDialog: true });
        } else {
          this.setState({ isShowErrorPasswordChangedDialog: true });
        }

        this.setState({ isSpinnerVisible: false });
        console.log("local", localStorage.getItem("userPassword"));
      })
      .catch(error => {
        console.log("error", error);
        this.setState({
          isShowErrorPasswordChangedDialog: true,
          isSpinnerVisible: false
        });
      });
  }
  setNewPassword(text) {
    this.setState({ newPassword: text });
    this.changePasswordFunc(text);
  }
  stockListSearch(text) {
 
    var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
    if (text.length > 2) {
        this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

        API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson);
                if (responseJson.count) {


                    this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                    console.log("In stock list", this.state.inStockDataList);
                } else {
                    this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                }
            });
    } else {
        this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
    }
}
getSTBDetails(item) {
    console.log("item", item);
    if (item.Customer) {
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, domainName, searchText } = this.state;
      API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("response", responsejson);
          if (responsejson.message == "no data") {
            this.setState({ spinnerVisible: false });
          } else {
            localStorage.setItem("currentlco", item.Lcocode);
            this.setState({ spinnerVisible: false });
            this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
          }
        });
    }
    else {
      this.setState({ isShowNodataDialog: true })
    }

  }


  render() {
    return (
      <Rootcontainer>
         <LoadingOverlay


className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
overlay={true}
active={this.state.isSpinnerVisible}
spinner={<img src={loadinggif} className="spinnerContent" color="green"/>}
styles={{ content: { width:"80%",minHeight:"65%" } }}

></LoadingOverlay>
        <Drawer
          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you want{" "}
                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                ?
              </div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowLogOutDialog: false });
                }}
              >
                Cancel
              </Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger"
                className="mt-3 pull-right"
                onClick={() => {
                  // this.props.hindNodataDialog();
                  this.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>
        <DialogBoxs
          isShowChangePasswordDialog={this.state.isShowChangePasswordDialog}
          hindChangePasswordDialog={this.hindChangePasswordDialog.bind(this)}
          setNewPassword={this.setNewPassword.bind(this)}
          logOut={this.logOut.bind(this)}
          isShowSuccessPasswordChangedDialog={
            this.state.isShowSuccessPasswordChangedDialog
          }
          isShowErrorPasswordChangedDialog={
            this.state.isShowErrorPasswordChangedDialog
          }
          hindErrorPasswordChangedDialog={this.hindErrorPasswordChangedDialog.bind(
            this
          )}
        />

      
        {/* <Loader
                           visible={this.state.isShowStockLoader}
                           style={{ textAlign: "center", marginBottom: 10, }}
                           type="ThreeDots"
                           color="#00BFFF"
                           height="10"
                           width="20"
                       /> */}

        <MainContainer style={{ background: "#f1f1f1", height: "100vh" }}>
          {/* <div style={{ width: "45%", height: 500, borderBottom: "2px solid gray", boxShadow: "4px 4px 5px #888888", backgroundColor: "white", margin: "35px auto",justifyContent:"center",overflow:"auto" }}>

                    <MdPerson size={70} color="gray" style={{alignSelf:"center",position:"relative",marginLeft:"43%",marginTop:20}}/>
                   <div style={{textAlign:"center",fontSize:18,fontWeight:"bold",color:"#d61818"}}> {this.state.lcoName}</div>
                   <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>Balance </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: {'\u20B9'} {this.state.lcoBalance}</label>
                    </div>
                    <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>Mobile Number </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: {this.state.lcomobile}</label>
                    </div>
                    <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>Email </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: {this.state.lcoemail}</label>
                    </div>
                    <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>Address </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: {this.state.lcoaddress}</label>
                    </div>
                    <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>Mandal </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: {this.state.lcomandal}</label>
                    </div>
                    <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>City </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: {this.state.lcocity}</label>
                    </div>
                    <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>District </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: {this.state.lcodistrict}</label>
                    </div>

                    <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>Country </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: {this.state.lcocountry}</label>
                    </div>
                    <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>Pin code </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: {this.state.lcopincode}</label>
                    </div>
                    <div style={{marginTop:15,width:"100%"}}>
                        <label style={{color:"rgb(26, 172, 195)",fontSize:15,marginLeft:"15%",flex:1,width:"40%"}}>Password </label>
                       <label style={{color:"#d61818",fontSize:18,fontWeight:"bold",flex:1}}>: *********        <label style={{color:"blue",marginLeft:25,cursor:"pointer"}} onClick={()=>{this.setState({isShowChangePasswordDialog:true})}}>Edit</label></label>
                    </div>
                    </div>

                */}
          <Container className="profile">
            <div className="circle">
              <MdPersonPin />
            </div>
            <Row>
              <Col
                md={12}
                sm={12}
                style={{ color: "white" }}
                className="text-center my-3"
              >
                <h4>Lco Details </h4>
              </Col>
              <Col md={4} sm={12}>
                <label>Name: </label>
                {this.state.lcoName}
              </Col>

              <Col md={4} sm={12}>
                <label>Balance : </label>
                {"\u20B9"} {this.state.lcoBalance}
              </Col>
              <Col md={4} sm={12}>
                <label>Mobile Number : </label>
                {this.state.lcomobile}
              </Col>
              <Col md={4} sm={12}>
                <label>Email : </label>
                {this.state.lcoemail}
              </Col>
              <Col md={4} sm={12}>
                <label>Mandal : </label>
                {this.state.lcomandal}
              </Col>
              <Col md={4} sm={12}>
                <label>City : </label>
                {this.state.lcocity}
              </Col>
              <Col md={4} sm={12}>
                <label>District : </label>
                {this.state.lcodistrict}
              </Col>
              <Col md={4} sm={12}>
                <label>Country: </label>
                {this.state.lcocountry}
              </Col>
              <Col md={4} sm={12}>
                <label>Pin code : </label>
                {this.state.lcopincode}
              </Col>
              <Col md={4} sm={12}>
                <label>Address : </label>
                {this.state.lcoaddress}
              </Col>
              <Col md={4} sm={12}>
                <label>Lcocode : </label>
                {this.state.lcocode}
              </Col>
              <Col md={4} sm={12}>
                <label>Password : </label>
                ******
                <label
                  className="edit"
                  onClick={() => {
                    this.setState({ isShowChangePasswordDialog: true });
                  }}
                >
                  Edit
                </label>
              </Col>
              <Col md={4} sm={12}>
                <label>Tax number : </label>
                {this.state.lcoTaxNumber}
              </Col>
            </Row>
          </Container>
        </MainContainer>

        {this.logOutFunc()}
      </Rootcontainer>
    );
  }
}
