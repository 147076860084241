import React, { Component } from 'react';
import { Button, Form, Container, Row, Col, Card } from 'react-bootstrap';
import logo from '../../assets/logo-3.png';
import loadinggif from '../../assets/loding-gif.gif';
import LoadingOverlay from 'react-loading-overlay';
import { Redirect } from 'react-router-dom';
import API from '../../Api';
import '../../screens/CustomerAddPackage/index.css'
import { Rootcontainer, RootDiv, RootTitle, RootBal, RootRight, RootLeft, RootValue, FooterUl, FooterLi, FaSearch, SearchDiv, MainContainer } from './styled'
import {
  MdPowerSettingsNew,
  MdArrowBack, MdAddCircle
} from 'react-icons/md';
import Drawer from 'react-drag-drawer';
import AddPackConfirmDialog from './AddPackConfirmDialog';
import DialogBoxes from './DialogBoxes';
import GeneralValues from '../../GobelElements/Values';
import { FaPlus, FaCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';

export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloggedOut: false,
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      unmappedSTB: [],
      errorMessage: "",
      spinnerVisible: false,
      stbName: "",
      stbNumber: "",
      customerNo: props.customerNo,
      STB: props.STBNo,
      packageAvailable: [],
      addPackageSearchResultList: [],
      searchText: "",
      isVisibleConformADD_Dialog: false,
      conformADD_PackageList: [],
      valuePack: 0,
      isSuccess: false,
      isShowLogOutDialog: false,
      isShowAddPackageWarning: false,
      proDataValue: 0,
      inStockListSearchText: "",
      inStockDataList: []



    };
    console.log("customer Number", this.props.customerNo);
    // this.getDashboardDetails();
    this.getLCO_Details();

  }
  componentWillMount() {
    this.getUnmappedPackage();
  }

  hindAddPackageWarning() {
    this.setState({ isShowAddPackageWarning: false })
  }
  showAddPackageWarningFunc() {
    this.setState({ isShowAddPackageWarning: true })
  }
  getUnmappedPackage() {
    this.setState({ spinnerVisible: true });
    const { lcoID, lcoPassword, domainName, STB } = this.state;
    console.log("working12345");
    API.getPackageAPI(lcoID, lcoPassword, "unmapped", STB, domainName)
      .then((response) => response.json())
      .then((responsejson) => {
        console.log("Success", responsejson);
        debugger;

        let arrayPackageDetails = Object.keys(responsejson.products).reduce((prevVal, key) => {
          let o = { ...responsejson.products[key], toggleMode: false };
          prevVal.push(o);
          return prevVal;
        }, []);


        console.log("Arraypackage", arrayPackageDetails)
        this.setState({ spinnerVisible: false, packageAvailable: arrayPackageDetails, addPackageSearchResultList: arrayPackageDetails, errorMessage: "" });


      })
      .catch((err) => {
        console.log("error getPackageAPI", err);
        debugger;
        this.setState({ spinnerVisible: false, errorMessage: err });
      });
  }
  toggleChangeInAddPackDialog(index, id) {
    console.log("working toggle", id, index);

    let oldPackage = this.state.addPackageSearchResultList;
    let oldToggleValue = oldPackage[index].toggleMode;
    oldPackage[index].toggleMode = !oldToggleValue;

    this.setState({ addPackageSearchResultList: oldPackage });
    console.log("finish");
    console.log("Selected package", this.state.addPackageSearchResultList[index]);
    var length = this.state.packageAvailable.length;
    var i;
    for (i = 0; i < length; i++) {
      if (id == this.state.packageAvailable[i]) {
        var oldPackageList = this.state.packageAvailable;
        var oldToggle = this.state.packageAvailable[i].toggleMode;
        oldPackageList[i].toggleMode = !oldToggle;
        this.setState({ packageAvailable: oldPackageList });

      }
    }
    console.log("selected data", this.state.packageAvailable);
    debugger;
  }
  getLCO_Details() {
    // this.setState({ isSpinnerVisible: true })
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then((response) => response.json())
      .then((responsejson) => {
        this.setState({ lcoName: responsejson.lconame, lcoBalance: responsejson.balance, isSpinnerVisible: false });

      });
  }
  search(searchQuery) {
    if (searchQuery) {
      this.setState({ spinnerVisible: true })
      var arr = []

      var length = this.state.packageAvailable.length;
      var i;
      console.log("query", searchQuery);
      console.log("length", length);

      for (i = 0; i < length; i++) {
        console.log("count", i);


        var name = this.state.packageAvailable[i].name.toLowerCase();
        var searchCapitals = searchQuery.toLowerCase()
        var number = name.search(searchCapitals);

        console.log("couunt", i, name.search(searchQuery));
        if (number >= 0) {

          arr.push(this.state.packageAvailable[i]);


        }
      }
      console.log("array123", arr);
      this.setState({ addPackageSearchResultList: arr, spinnerVisible: false })
    } else {
      this.setState({ addPackageSearchResultList: this.state.packageAvailable, spinnerVisible: false })
    }

  }
  enableAddPageConformDialog() {


    let length = this.state.packageAvailable.length;
    let totalPackageValue = 0.00;
    let packageArray = [];
    for (var i = 0; i < length; i++) {
      if (this.state.packageAvailable[i].toggleMode) {
        packageArray.push(this.state.packageAvailable[i])
        totalPackageValue = parseFloat(totalPackageValue) + parseFloat(this.state.packageAvailable[i].baseprice);

      }
    }
    console.log("total amount", totalPackageValue);
    this.setState({ valuePack: totalPackageValue });


    console.log("conformation Package list", packageArray);
    this.setState({ conformADD_PackageList: packageArray, isVisibleConformADD_Dialog: true });
    console.log("total", this.state.valuePack);
  }
  inVisibleConfirmPackDialog() {
    this.setState({ isVisibleConformADD_Dialog: false })
  }
  addPackage() {
    this.setState({ spinnerVisible: true });
    let length = this.state.conformADD_PackageList.length;

    let allowToAddPackage = false;
    let basePackList = [];



    //  if(allowToAdd){
    let packageArray = [];
    let basePackIDList = []
    for (var i = 0; i < length; i++) {
      if (this.state.conformADD_PackageList[i].toggleMode) {
        if (this.state.conformADD_PackageList[i].base) {
          basePackIDList.push(this.state.conformADD_PackageList[i].id);

        } else {
          packageArray.push(this.state.conformADD_PackageList[i].id);
        }
        // packageArray.push(this.state.conformADD_PackageList[i].id);
        basePackList.push(this.state.conformADD_PackageList[i].base);
      }
    }
    console.log("arr", packageArray);
    packageArray = basePackIDList.concat(packageArray);
    console.log("arr", packageArray);
    debugger;

    let lengthBasePack = basePackList.length;
    console.log("length", lengthBasePack);
    console.log("true or false", basePackList);
    for (var j = 0; j < lengthBasePack; j++) {

      if (basePackList[j]) {
        allowToAddPackage = true;

      }
    }

    console.log("data", allowToAddPackage);
    debugger;
    if (allowToAddPackage) {
      console.log("arr", packageArray);
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, customerNo, STB } = this.state;
      // const customerNo = this.state.userMetaData.Customernum;
      // const STBNumber = this.state.userSTBDetails.SNo;
      API.addPackageAPI(lcoID, lcoPassword, customerNo, "bulkaddpackage", STB, packageArray, this.state.domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("Added", responsejson);
          this.setState({ spinnerVisible: false, isVisibleConformADD_Dialog: false, isSuccess: true });
          // this.getLCO_Details(); 
          // this.onRefreshData();
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ spinnerVisible: false, isShowAddPackageDialogBox: false, errorMessage: "", errorMessageForAddPackage: "Unable to Process !" });
        });
    } else {
      debugger;
      this.setState({ isError: true, spinnerVisible: false, isVisibleConformADD_Dialog: false });
    }
  }
  toggleChangeInAddPackConfirmDialog() {

    let oldPackage = this.state.conformADD_PackageList;
    let oldToggleValue = oldPackage[index].toggleMode;
    oldPackage[index].toggleMode = !oldToggleValue;

    this.setState({ conformADD_PackageList: oldPackage });
    console.log("finish");
    console.log("Selected package", this.state.conformADD_PackageList[index]);
    debugger;
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  calculateProDataPrice() {
    const { conformADD_PackageList } = this.state;
    var totalPackageValues = 0;
    for (var data of conformADD_PackageList) {
      if (data.toggleMode) {
        totalPackageValues = (parseFloat(data.baseprice) + parseFloat(totalPackageValues)).toFixed(2);
      }
    }
    console.log("ProDate value", totalPackageValues);
    debugger;
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    //         var d = new Date();
    // var day = d.getDay();

    console.log("date", today, dd, mm, yyyy);
    var numberOfDayInMonth = this.daysInMonth(parseInt(mm), parseInt(yyyy))
    var remaingDay = numberOfDayInMonth - parseInt(dd);
    totalPackageValues = ((totalPackageValues / numberOfDayInMonth) * parseInt(remaingDay)).toFixed(2);
    this.setState({ proDataValue: totalPackageValues })
  }

  showPackage() {
    if (this.state.addPackageSearchResultList) {
      return (
        <Row>
          {this.state.addPackageSearchResultList.map((data, i) => {
            return (
              <Col xs={12} sm={6} md={3} lg={2} className="mb-2 mt-2 pr-0 packDiv">
                <Card>
                  <Card.Body>
                    <Card.Title className="packNM mb-3">{data.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{'\u20B9'} {data.baseprice}</Card.Subtitle>

                    <Form.Group controlId="formBasicChecbox" className="check-pack">
                      <Form.Check
                        onClick={() => { this.toggleChangeInAddPackDialog(i, data.id) }}
                        type="checkbox" checked={data.toggleMode} />
                    </Form.Group>
                    <label>{data.base ? "base" : ""}</label>
                    <label>{data.Alacarte ? "Alacarte" : ""}</label>

                  </Card.Body>
                </Card>
              </Col>
            );
          })}






        </Row>
      );
    }
  }
  stockListSearch(text) {

    var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
    if (text.length > 2) {
      this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

      API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("response", responseJson);
          if (responseJson.count) {


            this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
            console.log("In stock list", this.state.inStockDataList);
          } else {
            this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
          }
        });
    } else {
      this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
    }
  }
  getSTBDetails(item) {
    console.log("item", item);
    if (item.Customer) {
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, domainName, searchText } = this.state;
      API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("response", responsejson);
          if (responsejson.message == "no data") {
            this.setState({ spinnerVisible: false });
          } else {
            localStorage.setItem("currentlco", item.Lcocode);
            this.setState({ spinnerVisible: false });
            this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
          }
        });
    }
    else {
      this.setState({ isShowNodataDialog: true })
    }

  }

  render() {
    return (
      <Rootcontainer>
      <LoadingOverlay

className={this.state.spinnerVisible  ? "displaySpinner" : ""}
overlay={true}
active={this.state.spinnerVisible }
spinner={<img src={loadinggif} className="spinnerContent" color="green" />}
styles={{ content: { width:"80%",minHeight:"65%" } }}
></LoadingOverlay>

        <Drawer

          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: 'bold', }}>Do you want <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span> ?</div>
              <Button
                style={{ marginLeft: 20 }}
                className="success" className="mt-3 pull-right"
                onClick={() => {


                  this.setState({ isShowLogOutDialog: false })

                }}
              >Cancel</Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger" className="mt-3 pull-right"
                onClick={() => {

                  // this.props.hindNodataDialog();
                  this.logOut();

                }}
              >OK</Button>



            </Card.Body>
          </Card>

        </Drawer>
        <Drawer

          open={this.state.isSuccess}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >

            <Card.Body>
              <div style={{ fontSize: 18, fontWeight: 'bold' }}>Customer Created Successfully, Please wait for updation</div>

              <button
                onClick={() => this.props.backToUnMappedSTB()}
                style={{ background: "green", color: 'white', padding: 15, marginTop: 20, textAlign: "right", display: "block", width: 60, height: 60, marginLeft: 270, borderRadius: 20 }}>OK</button>

            </Card.Body>
          </Card>

        </Drawer>
        <Drawer

          open={this.state.isError}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body>
              <div style={{ fontSize: 18, fontWeight: 'bold' }}>Select one <span style={{ color: "red" }}>base </span>package to process</div>

              <button
                onClick={() => { this.setState({ isError: false }) }}
                style={{ background: "green", color: 'white', padding: 15, marginTop: 20, textAlign: "right", display: "block", width: 60, height: 60, marginLeft: 270, borderRadius: 20 }}>OK</button>

            </Card.Body>
          </Card>

        </Drawer>
        <AddPackConfirmDialog
          isVisibleConformADD_Dialog={this.state.isVisibleConformADD_Dialog}
          conformADD_PackageList={this.state.conformADD_PackageList}
          toggleChangeInAddPackConfirmDialog={this.toggleChangeInAddPackConfirmDialog.bind(this)}
          addPackage={this.addPackage.bind(this)}
          inVisibleConfirmPackDialog={this.inVisibleConfirmPackDialog.bind(this)}
          showAddPackageWarningFunc={this.showAddPackageWarningFunc.bind(this)}
          calculateProDataPrice={this.calculateProDataPrice.bind(this)}
        >

        </AddPackConfirmDialog>
       
        {/* <Loader
              visible={this.state.isShowStockLoader}
              style={{ textAlign: "center", marginBottom: 10, }}
              type="ThreeDots"
              color="#00BFFF"
              height="10"
              width="20"
            /> */}

        <RootDiv>
          <RootLeft>
            <RootTitle>
              Name :
                                    </RootTitle>

            <RootValue >
              {this.state.lcoName}
            </RootValue>
          </RootLeft>
          <RootRight>
            <RootTitle>
              Balance :
                </RootTitle>

            <RootValue>
              {'\u20B9'} {this.state.lcoBalance}
            </RootValue>

          </RootRight>
        </RootDiv>
        <Button variant="success" onClick={() => { this.enableAddPageConformDialog() }} className="pull-right mt-1 mr-3 ml-3"
        > <MdAddCircle /> Add Package</Button>
        <MainContainer>


          <SearchDiv>
            <input
              type="text" className="textBox" placeholder="Search..." style={{ lineHeight: 2.3, background: "#bfec99", border: 0, width: "100%", borderRadius: 35 }}
              //    onPaste={this.renderTheSearchResult()}
              //    onKeyPress={this.renderTheSearchResult()}
              onChange={(e) => { this.setState({ searchText: e.target.value }) }}
              onKeyPress={(e) => {

                if (e.keyCode == 13 || e.which == 13) {
                  this.search(this.state.searchText)
                }
              }}
            />
            <FaSearch className="search-icons"></FaSearch>
          </SearchDiv>
          {this.showPackage()}

          <DialogBoxes
            isShowAddPackageWarning={this.state.isShowAddPackageWarning}
            hindAddPackageWarning={this.hindAddPackageWarning.bind(this)}
            addPackage={this.addPackage.bind(this)}
            proDataValue={this.state.proDataValue}
            calculateProDataPrice={this.calculateProDataPrice.bind(this)}
            lcoBalance={this.state.lcoBalance}
          />
        </MainContainer>
      </Rootcontainer>

    )
  }
}
