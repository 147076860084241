import React from "react";
import Drawer from "react-drag-drawer";
import "./index.css";
import {
  Button,
  ButtonGroup,
  Container,
  ButtonToolbar,
  Jumbotron,
  Card
} from "react-bootstrap";
import Undraw from "react-undraw";
import Checkboxs from 'muicss/lib/react/checkbox';
import {

  MdClose
} from 'react-icons/md';
export default class DialogBoxs extends React.Component {
  render() {
    return (
      <div>
        <Drawer
          open={this.props.showTermAndCondition}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3" style={{ width: "50vw", height: "70vh" }}>

              <MdClose
              onClick={()=>{
                this.props.hindShowTermAndCondition();
              }}
              size={30} color="gray" className="mt-1 pull-right" />


              <center>
                <div style={{ fontSize: 18, fontWeight: "bold" }} >
                  Terms And Conditions

                </div></center>
              <div style={{ marginTop: 20, overflow: "auto", height: "62vh" }}>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 40, lineHeight: 1 }}>Privacy Policy for Axom Communications & Cable Pvt Ltd</label>
                  <label style={{ color: "#878787" }}>At Axom, accessible from ebiz.axom.co.in, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Axom and how we use it.</label>
                  <label style={{ color: "#878787" }}> If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</label>
                  <label style={{ color: "#878787" }}>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Axom. This policy is not applicable to any information collected offline or via channels other than this website.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Consent</label>
                  <br></br>
                  <label style={{ color: "#878787" }}>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Information we collect</label>
                  <label style={{ color: "#878787" }}>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</label>
                  <label style={{ color: "#878787" }}>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</label>
                  <label style={{ color: "#878787" }}>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>How we use your information</label>
                  <label style={{ color: "#878787" }}>We use the information we collect in various ways, including to:</label>
                  <ul>
                    <li>Provide, operate, and maintain our website</li>
                    <li>Improve, personalize, and expand our website</li>
                    <li>Understand and analyze how you use our website</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                    <li>Send you emails</li>
                    <li>Find and prevent fraud</li>
                  </ul>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Log Files</label>
                  <label style={{ color: "#878787" }}>Axom follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Cookies and Web Beacons</label>
                  <label style={{ color: "#878787" }}>Like any other website, Axom uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Advertising Partners Privacy Policies</label>
                  <label style={{ color: "#878787" }}>You may consult this list to find the Privacy Policy for each of the advertising partners of Axom.</label>
                  <label style={{ color: "#878787" }}>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Axom, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</label>
                  <label style={{ color: "#878787" }}>Note that Axom has no access to or control over these cookies that are used by third-party advertisers.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Third Party Privacy Policies</label>
                  <label style={{ color: "#878787" }}>Axom's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</label>
                  <label style={{ color: "#878787" }}>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>CCPA Privacy Rights (Do Not Sell My Personal Information)</label>
                  <label style={{ color: "#878787" }}>Under the CCPA, among other rights, California consumers have the right to:</label>
                  <label style={{ color: "#878787" }}>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</label>
                  <label style={{ color: "#878787" }}>Request that a business delete any personal data about the consumer that a business has collected.</label>
                  <label style={{ color: "#878787" }}>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</label>
                  <label style={{ color: "#878787" }}>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>GDPR Data Protection Rights</label>
                  <label style={{ color: "#878787" }}>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</label>
                  <label style={{ color: "#878787" }}>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</label>
                  <label style={{ color: "#878787" }}>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</label>
                  <label style={{ color: "#878787" }}>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</label>
                  <label style={{ color: "#878787" }}>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</label>
                  <label style={{ color: "#878787" }}>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</label>
                  <label style={{ color: "#878787" }}>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</label>
                  <label style={{ color: "#878787" }}>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Children's Information</label>
                  <label style={{ color: "#878787" }}>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</label>
                  <label style={{ color: "#878787" }}>Axom does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Return and Refund Policy</label>
                  <br></br><label style={{ color: "#878787" }}>Last updated: September 5, 2023</label>
                  <br></br><label style={{ color: "#878787" }}>Thank you for shopping at Axom.</label>
                  <label style={{ color: "#878787" }}>If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns.</label>
                  <label style={{ color: "#878787" }}>The following terms are applicable for any products that You purchased with Us.</label>
                  <label style={{ color: "#878787" }}></label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Interpretation and Definitions Interpretation</label>
                  <label style={{ color: "#878787" }}>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</label>
                </div>

                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Definitions</label>
                  <br></br> <label style={{ color: "#878787" }}>For the purposes of this Return and Refund Policy:</label>
                  <ul>
                    <li>
                      <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Axom Communications & Cable Pvt Ltd. 1st Floor, Surya Tower B Baruah Road Ulubari Guwahati, Assam. Pin-781007.</p>
                    </li>
                    <li>
                      <p><strong>Goods</strong> refer to the items offered for sale on the Service.</p>
                    </li>
                    <li>
                      <p><strong>Orders</strong> mean a request by You to purchase Goods from Us.</p>
                    </li>
                    {/* <li>
                      <p><strong>Service</strong> refers to the Website.</p>
                    </li> */}
                    
                    <li>
                      <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                    </li>
                  </ul>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Your Order Cancellation Rights</label>
                  <label style={{ color: "#878787" }}>You are entitled to cancel Your Order within 7 days without giving any reason for doing so.</label>
                  <label style={{ color: "#878787" }}>The deadline for cancelling an Order is 7 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.</label>
                  <label style={{ color: "#878787" }}>In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:</label>
                  <ul>
                    <li>By email: assamcable@gmail.com , By phone: +91 7086086087</li>
                  </ul>
                  <label style={{ color: "#878787" }}>We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Conditions for Returns</label>
                  <label style={{ color: "#878787" }}>In order for the Goods to be eligible for a return, please make sure that:</label>
                  <ul>
                    <li>The Goods were purchased in the last 7 days</li>
                    <li>The Goods are in the original packaging</li>
                  </ul>
                  <label style={{ color: "#878787" }}>The following Goods cannot be returned:</label>
                  <ul>
                    <li>The supply of Goods made to Your specifications or clearly personalized.</li>
                    <li>The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over.</li>
                    <li>The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</li>
                    <li>The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
                  </ul>
                  <label style={{ color: "#878787" }}>We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.</label>
                  <label style={{ color: "#878787" }}>Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Returning Goods</label>
                  <label style={{ color: "#878787" }}>You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:</label>
                  <label style={{ color: "#878787" }}>1st Floor, Surya Tower. B Baruah Road.Ulubari.Guwahati, Assam. Pin-781007</label>
                  <label style={{ color: "#878787" }}>We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Gifts</label>
                  <label style={{ color: "#878787" }}>If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.</label>
                  <label style={{ color: "#878787" }}>If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.</label>
                </div>
                <div>
                  <label style={{ fontWeight: "bold", fontSize: 30, lineHeight: 1 }}>Contact Us</label>
                  <label style={{ color: "#878787" }}>If you have any questions about our Returns and Refunds Policy, please contact us:</label>
                  <ul>
                    <li>By email: assamcable@gmail.com , By phone: +91 7086086087</li>
                  </ul>
                </div>
                <div className="d-inline-flex mt-3 pull-right" style={{marginTop:10}}>
                      <Checkboxs
                        style={{ fontSize: 20, color: "green" }}
                        color="green"

                        onClick={() => {this.props.changeAdress() }}
                        checked={this.props.agressTerm}
                        
                        /> 
                        <label style={{fontSize:13,marginTop:6,marginLeft:5}}>I AGREE</label>
                      </div>
                
              </div>

            
            </Card.Body>
          </Card>
        </Drawer>
      </div>
    );
  }
}
