import React from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Col,
  Row,
  Card
} from "react-bootstrap";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,
  AddPackageHeading
} from "./styled";
import GeneralValues from '../../GobelElements/Values';
import { Redirect } from "react-router-dom";
import {
  MdPermIdentity,
  MdHome,
  MdFiberPin,
  MdCreditCard,
  MdRefresh,
  MdAddCircle,
  MdRemoveCircle,
  MdPowerSettingsNew,
  MdArrowBack,
  MdAddShoppingCart,
  MdMotorcycle,
  MdAccessTime,
  MdPerson,
  MdError
} from "react-icons/md";
import loadinggif from "../../assets/loding-gif.gif";
import API from "../../Api";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import "./index.css";
import BounceLoader from "react-spinners/BounceLoader";

import { FaPlus,FaCircle,FaUserCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';

import { MdTrendingUp } from "react-icons/md";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
export default class index extends React.Component {
  constructor(props) {
    super(props);
    console.log("data from search", this.props.specifyUserDetails);
    const data = this.props.specifyUserDetails;
    console.log("data in customer details", data);

    this.state = {
      userMetaData: data,
      userSTBDetails: {},

      isShowAddPackageDialogBox: false,
      isShowRemovePackageDialogBox: false,

      spinnerVisible: false,
      pageCount: 3,
      totalPage: 0,
      count: "",
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      pageNo: 3,
      tableDataValue: "",
      isVisibleConformADD_Dialog: false,
      isVisibleConformRemove_Dialog: false,
      isVisibleTransactions_Dialog: false,
      isVisibleRemove_Dialog: false,
      valuePack: 0,
      removeValuePack: 0,
      isShowSuccessDialog: false,
      creditRow: [],
      totalCount: 1,
      isShowLogOutDialog: false,
      // spinnerVisible: true,
      inStockListSearchText: "",
           inStockDataList: []
    };

    this.getLCO_Details();

    console.log("try", this.props.tableData);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillMount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  nextPageData(data, pageNo) {
    let lengthOfACustomerList = this.state.creditRow.length;
    // console.log("lenght of the list", this.state.totalCount);
    if (lengthOfACustomerList < this.state.totalCount) {
      // console.log("pageCount", pageNo);
      // this.setState({ animating: true });
      const { lcoID, lcoPassword, domainName } = this.state;
      API.getNotificationData(
        lcoID,
        lcoPassword,
        "lconotify",
        pageNo,
        domainName
      )

        .then(response => response.json())
        .then(responsejson => {
          if (responsejson.count) {
            console.log("SearchResponse", responsejson);
            let oldData = data;
            let newData = oldData.concat(responsejson.content);
            let count = this.state.pageCount + 1;

            this.setState({
              animating: false,
              isShowSearchList: true,
              creditRow: newData,
              pageCount: count
            });
            // console.log("data", this.state.customerList);
            // if (newData.length < responsejson.count) {

            //     this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
            // }
          }
        })
        .catch(err => {
          console.log("error", err);
          this.setState({ isSpinnerVisible: false });
          // break
        });
    }
  }
  getdataPage3(data) {
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getNotificationData(lcoID, lcoPassword, "lconotify", 3, domainName)
      .then(response => response.json())
      .then(responsejson => {
        // console.log("SearchResponse", responsejson);
        let oldData = data;
        let newData = oldData.concat(responsejson.content);
        // if (newData.length < responsejson.count) {

        // this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
        this.setState({
          isSpinnerVisible: false,
          isShowSearchList: true,
          creditRow: newData
        });
        // } else {
        //     this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: newData });
        // }
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  getdataPage2(data) {
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getNotificationData(lcoID, lcoPassword, "lconotify", 2, domainName)

      .then(response => response.json())
      .then(responsejson => {
        // console.log("SearchResponse", responsejson);
        let oldData = data;
        let newData = oldData.concat(responsejson.content);
        if (newData.length < responsejson.count) {
          this.getdataPage3(newData);
        } else {
          this.setState({
            isSpinnerVisible: false,
            isShowSearchList: true,
            creditRow: newData
          });
        }
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  getdata1() {
    this.setState({ isSpinnerVisible: true });
    let data = [];
    debugger;
    // if(this.state.initialPageCount <=this.state.totalPageCount){

    const { lcoID, lcoPassword, domainName } = this.state;
    API.getNotificationData(lcoID, lcoPassword, "lconotify", 1, domainName)

      .then(response => response.json())
      .then(responsejson => {
        console.log("SearchResponse", responsejson);
        if (responsejson.message != "no data") {
          let page1Data = [];
          this.setState({ isSpinnerVisible: true });
          page1Data = responsejson.content;
          console.log("count", page1Data.length);
          this.setState({ totalCount: responsejson.count });
          if (page1Data.length < responsejson.count) {
            this.getdataPage2(page1Data);
          } else {
            this.setState({
              isSpinnerVisible: false,
              isShowSearchList: true,
              creditRow: responsejson.content
            });
          }

          //  this.setState({ isSpinnerVisible: false, isShowSearchList: true,customerList:responsejson.content });
        } else {
          this.setState({ isSpinnerVisible: false, isShowSearchList: false });
          // break
        }
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  handleScroll(e) {
    const bottom =
      e.target.scrollHeight - Math.round(e.target.scrollTop) ===
      e.target.clientHeight;
    console.log(
      "bottom",
      bottom,
      "e.target.scrollHeight:",
      e.target.scrollHeight,
      "e.target.scrollTop:",
      e.target.scrollTop,
      "e.target.clientHeight:",
      e.target.clientHeight
    );
    if (bottom) {
      this.nextPageData(this.state.creditRow, this.state.pageCount + 1);
    }
  }

  getLCO_Details() {
    debugger;
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then(response => response.json())
      .then(responsejson => {
        this.setState({
          lcoName: responsejson.lconame,
          lcoBalance: responsejson.balance,
          lcocode: responsejson.lcocode
        });
        debugger;
        this.getdata1();
      });
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  backTOTrandactions() {
    this.props.onMoveToTransactions();
  }
  stockListSearch(text) {
 
    var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
    if (text.length > 2) {
        this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

        API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson);
                if (responseJson.count) {


                    this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                    console.log("In stock list", this.state.inStockDataList);
                } else {
                    this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                }
            });
    } else {
        this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
    }
}
getSTBDetails(item) {
    console.log("item", item);
    if (item.Customer) {
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, domainName, searchText } = this.state;
      API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("response", responsejson);
          if (responsejson.message == "no data") {
            this.setState({ spinnerVisible: false });
          } else {
            localStorage.setItem("currentlco", item.Lcocode);
            this.setState({ spinnerVisible: false });
            this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
          }
        });
    }
    else {
      this.setState({ isShowNodataDialog: true })
    }

  }


  render() {
    return (
      <Rootcontainer onScroll={e => this.handleScroll(e)}>
         <LoadingOverlay


className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
overlay={true}
active={this.state.isSpinnerVisible}
spinner={<img src={loadinggif} className="spinnerContent" color="green"  />}
styles={{ content: { width:"80%",minHeight:"65%" } }}

></LoadingOverlay>
        <Drawer
          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you want{" "}
                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                ?
              </div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowLogOutDialog: false });
                }}
              >
                Cancel
              </Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger"
                className="mt-3 pull-right"
                onClick={() => {
                  // this.props.hindNodataDialog();
                  this.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>
       

        {/* <Loader
                           visible={this.state.isShowStockLoader}
                           style={{ textAlign: "center", marginBottom: 10, }}
                           type="ThreeDots"
                           color="#00BFFF"
                           height="10"
                           width="20"
                       /> */}

        <RootDiv>
          <RootLeft>
            <RootTitle>Name :</RootTitle>

            <RootValue
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.onClickProfile();
              }}
            >
              {this.state.lcoName}
            </RootValue>
            <div className="lcocode">
              (<span style={{ color: "black" }}>Lco code:</span>{" "}
              {this.state.lcocode})
            </div>
          </RootLeft>

          <RootRight>
            <RootTitle>Balance :</RootTitle>

            <RootValue>
              {"\u20B9"} {this.state.lcoBalance}
            </RootValue>
          </RootRight>
        </RootDiv>
        <MainContainer>
          <Row className="notify-div">
            {this.state.creditRow.map((data, i) => {
              return (
                <React.Fragment>
                  {/* <Col xs={12} lg={3} md={4} sm={6} className="mb-3">
                    <Card key={i}>
                      <Card.Title className="pt-3 pl-3">
                        <div>
                          <MdAddShoppingCart className="mr-3" />
                          {data.values}
                        </div>
                      </Card.Title>
                      <Card.Body className="pt-0">
                        <div>
                          <MdMotorcycle className="mr-3" />
                          {data.fn}
                        </div>
                        <div>
                          <MdAccessTime className="mr-3" />
                          {data.created}
                        </div>
                        <div>
                          <MdPerson className="mr-3" />
                          {data.user}
                        </div>
                        <div style={{ color: "red" }}>
                          <MdError className="mr-3" />
                          {data.error}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col> */}

                  <Col lg={3} md={4} sm={6} className="notification mb-4">
                    <Card key={i}>
                      <Card.Title
                        className="pt-3 pl-4"
                        style={{
                          color: " #0a4168"
                        }}
                      >
                        <div
                          className="icon"
                          style={{ margin: "0px", fontSize: "20px" }}
                        >
                          <FaUserCircle className="mr-3" />
                          {data.user}
                        </div>
                      </Card.Title>

                      <Card.Body className="pt-0">
                        <div className="icon">
                          <MdTrendingUp className="mr-3" />
                          {/* <MdMotorcycle className="mr-3" /> */}
                          {data.fn}
                        </div>

                        <div className="icon">
                          <MdPerson className="mr-3" />
                          {data.values}
                        </div>
                        <div className="icon">
                          <MdAccessTime className="mr-3" />
                          {data.created}
                        </div>
                        <div
                          style={{
                            color: "#cc0e27c9"
                          }}
                        >
                          <MdError
                            className="mr-3"
                            style={{ height: " 20px", width: "20px" }}
                          />
                          {data.error}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        </MainContainer>

        {this.logOutFunc()}
      </Rootcontainer>
    );
  }
}
