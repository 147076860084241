import React from "react";
import {
  Button,
  ButtonGroup,
  Container,
  ButtonToolbar,
  Jumbotron,
  Card,
  Col,
  Row
} from "react-bootstrap";

import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  SelectedDiv,
  SelectedPackage
} from "./styled";
import { Redirect } from "react-router-dom";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import { FaPenSquare, FaUserPlus, FaPlus, FaCircle } from "react-icons/fa";
import axios from 'axios';
import {
  MdSearch,
  MdPowerSettingsNew,
  MdAddCircle,
  MdRemoveCircle,
  MdNotifications,
  MdTransferWithinAStation,
  MdKeyboardReturn
} from "react-icons/md";
import DialogBoxs from "./DialogBox";
import GeneralValues from '../../GobelElements/Values';
import Loader from 'react-loader-spinner';
import API from "../../Api";
export default class Instock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloggedOut: false,
      isShowLogOutWarning: false,
      inStockListSearchText: "",
      inStockDataList: [],
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
    };
  }
  hindLogOutDialog() {
    this.setState({ isShowLogOutWarning: false });
  }

  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }

  stockListSearch(text) {

    var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
    if (text.length > 2) {
      this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

      API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("response", responseJson);
          debugger;
          if (responseJson.count) {


            this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
            console.log("In stock list", this.state.inStockDataList);
          } else {
            this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
          }
        });
    } else {
      this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
    }
  }
  getSTBDetails(item) {
    console.log("item", item);
    if (item.Customer) {
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, domainName, searchText } = this.state;
      API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("response", responsejson);
          if (responsejson.message == "no data") {
            this.setState({ spinnerVisible: false });
          } else {
            localStorage.setItem("currentlco", item.Lcocode);
            this.setState({ spinnerVisible: false });
            this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
          }
        });
    }
    else {
      this.setState({ isShowNodataDialog: true })
    }

  }

  getCSVFile() {
    // var url1=  'https://my.jaknetwork.in/stb/dumptinvoice/?download=yes&lcocode=TEST001&d1=2019-09-13&d2=2019-09-17';
    // fetch(url1,{
    //   method: 'GET',
    //   responseType: 'blob'
    // }).then((response) => {
    //   console.log("repose",response);
    //   debugger;
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', 'download.xlsx');
    //   link.click();
    //   window.URL.revokeObjectURL(url);
    // });



    axios({
      url: 'https://my.jaknetwork.in/stb/dumptinvoice/?download=yes&lcocode=TEST001&d1=2019-09-13&d2=2019-09-14',
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      console.log("data", response);
      debugger;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx');
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }

  // logOutFunc() {
  //     if (this.state.isloggedOut) {
  //         return <Redirect to="/login" />
  //     } else {
  //         return null;
  //     }
  // }
  // logOut() {

  //     localStorage.removeItem("userID");
  //     const username = localStorage.getItem("userID");
  //     if (!username) {
  //         this.setState({ isloggedOut: true });
  //     }
  // }
  render() {
    return (
      <Rootcontainer
        onScroll={e => this.handleScroll(e)}
      // ref={this.nav}
      //onScrollCapture={(e)=>{  this.nextPageData(this.state.customerList, (this.state.pageCount + 1))}}
      >
        
        {/* <Loader
              visible={this.state.isShowStockLoader}
              style={{ textAlign: "center", marginBottom: 10, }}
              type="ThreeDots"
              color="#00BFFF"
              height="10"
              width="20"
            /> */}

        <MainContainer>
          {/* <Container>
            <div className="d-flex flex-column transactions-btn">
              <ButtonGroup
                aria-label="Basic example"
                size="lg"
                className="mt-3"
              >
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.props.moveToSTBCreation();
                  }}
                >
                  <MdAddCircle />
                  STB Creation
                </Button>
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.props.moveToSTBTransfer();
                  }}
                >
                  <MdRemoveCircle />
                  Transfer
                </Button>
                <Button
                  variant="light"
                  size="lg"
                  moveToSTBReturns
                  onClick={() => {
                    this.props.moveToSTBReturns();
                  }}
                >
                  <MdNotifications />
                  Returns
                </Button>
              </ButtonGroup>

              <ButtonGroup
                aria-label="Basic example"
                size="lg"
                className="mt-3"
              >
                <Button
                  variant="light"
                  size="lg"
                  onClick={() => {
                    this.props.moveToCustomerTransfer();
                  }}
                >
                  <MdAddCircle />
                  Customer Transfer
                </Button>
              </ButtonGroup>
            </div>
          </Container> */}

          {/* <Container className="mt-5"> */}
            <Row className="transcation" style={{width:"98vw",marginTop:70}}>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToSTBCreation();
                }}
              >

                 <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #2B6885, #4AB2E2)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <FaPenSquare
                        style={{ marginTop: 40 }}
                      />
                        <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                        STB Creation
                      </Card.Text>
                      
                    </Row>
                  </center>

                </Card>
                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <FaPenSquare
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: GeneralValues.mustard, marginTop: "-15px", marginBottom: 30 }}>
                    STB Creation
                  </Card.Text>
                </Card> */}
              </Col>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToSTBTransfer();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #333B6A, #5765B6)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <MdTransferWithinAStation
                        style={{ marginTop: 40 }}
                      />
                        <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                        Transfer
                      </Card.Text>
                      
                    </Row>
                  </center>

                </Card>
                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <MdTransferWithinAStation
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: GeneralValues.mustard, marginTop: "-15px", marginBottom: 30 }}>
                    Transfer
                  </Card.Text>
                </Card> */}
              </Col>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToSTBReturns();
                }}
              >
                 <Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #9AE7D3, #1891B2)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <MdKeyboardReturn
                        style={{ marginTop: 40 }}
                      />
                        <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                        Returns
                      </Card.Text>
                      
                    </Row>
                  </center>

                </Card>

                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <MdKeyboardReturn
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: GeneralValues.mustard, marginTop: "-15px", marginBottom: 30 }}>
                    Returns



                  </Card.Text>
                </Card> */}
              </Col>
              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToCustomerTransfer();
                }}
              >

<Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #842E2D, #7A54BC)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <MdTransferWithinAStation
                        style={{ marginTop: 40 }}
                      />
                        <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",textTransform:"uppercase"}}>
                        Customer Transfer
                      </Card.Text>
                      
                    </Row>
                  </center>

                </Card>
                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <MdTransferWithinAStation
                      style={{
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: GeneralValues.mustard, marginTop: "-15px" }}>
                    Customer Transfer
                  </Card.Text>
                </Card> */}
              </Col>

              <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                  this.props.moveToCreateCustomerWithSTB();
                }}
              >

<Card
                  className="transaction-card"
                  style={{
                    height: 80,
                    color: "#fff",
                    background: "linear-gradient(to right, #333B6A, #5765B6)"
                  }}
                >
                  <center>
                    <Row style={{ height: 80 }}>
                      <FaUserPlus
                        style={{ marginTop: 40 }}
                      />
                        <Card.Text style={{ color: "#fff", fontSize: 15, fontWeight: "bold",width:"12rem",marginTop:-20,textTransform:"uppercase"}}>
                        Bulk customer & settop box Creation
                      </Card.Text>
                      
                    </Row>
                  </center>

                </Card>

                {/* <Card
                  className="transaction-card"
                  style={{
                    boxShadow: "0.5px 0.5px 2px 2px #958f88",
                    color: "#fff"
                  }}
                >
                  <div className="circle">
                    <FaUserPlus
                      style={{
                        padding: 12,
                        background: GeneralValues.blueColor
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: GeneralValues.mustard, marginTop: "-15px" }}>
                    Bulk customer and settop box Creation
                  </Card.Text>
                </Card> */}
              </Col>

              {/* <Col
                md={3}
                sm={12}
                style={{ margin: "10px 0px 50px", cursor: "pointer" }}
                onClick={() => {
                   this.props.moveToBulkPackAddandRemove();
                }}
              >
                <Card
                  className="transaction-card"
                  style={{
                    background:
                      " linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))"
                  }}
                >
                  <div className="circle">
                    <MdTransferWithinAStation
                      style={{
                        background:
                          "linear-gradient(to right, rgb(255, 67, 91), rgb(255, 73, 54))"
                      }}
                    />
                  </div>

                  <Card.Text style={{ color: "#fff", marginTop: "-15px" }}>
                  Bulk package Add and Remove
                  </Card.Text>
                </Card>
              </Col> */}
            </Row>
          {/* </Container> */}
        </MainContainer>
        <DialogBoxs
          isShowLogOutWarning={this.state.isShowLogOutWarning}
          logOut={this.logOut.bind(this)}
          hindLogOutDialog={this.hindLogOutDialog.bind(this)}
        />

        {this.logOutFunc()}
      </Rootcontainer>
    );
  }
}
