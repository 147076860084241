import React, { Component } from "react";
import logo from "../../assets/logo-3.png";
import "../login/index.css";
import "./login.css";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Container,
  ControlLabel,
  HelpBlock,
  Checkbox,
  Radio,
  Button,

} from "react-bootstrap";

import { Redirect } from "react-router-dom";
import API from "../../Api";
import LoadingOverlay from "react-loading-overlay";
import loadinggif from "../../assets/loding-gif.gif";
import BounceLoader from "react-spinners/BounceLoader";
import "../../assets/index.css";
import Undraw from "react-undraw";
import GeneralValues from '../../GobelElements/Values';
import { Label } from "react-bootstrap";
import refreshIcon from './refresh.png';
import Checkboxs from 'muicss/lib/react/checkbox';
import DialogBoxes from "./DialogBox";
// import DialogBoxes from "./DialogBoxes";
export default class index extends Component {
  constructor(props) {
    console.log("props", props);
    super(props);
    this.state = {
      userName: "",
      userPassword: "",
      domain: "sample",
      isSubmitted: false,
      errorMessage: "",
      spinnerVisible: false,
      randomValue: Math.floor(10000 + Math.random() * 90000),
      captchaVal: "",
      agressTerm: false,
      showTermAndCondition: false
    };
    // console.log("props",props);
    // if(props.match.params.id){
    //   // this.setState({domain:props.match.params.id})
    //   debugger;
    // }
  }
  // componentWillMount(props){
  //   console.log("props",props);
  // }
  onRefreshCaptch() {
    this.setState({ randomValue: Math.floor(10000 + Math.random() * 90000) })
  }
  hindShowTermAndCondition() {
    this.setState({ showTermAndCondition: false })
  }
  changeAdress() {
    if (!this.state.agressTerm) {
      this.setState({ showTermAndCondition: false })
    }
    this.setState({ agressTerm: !this.state.agressTerm })
  }
  submit(e) {
    e.preventDefault();
    //  localStorage.setItem("userID",this.state.userName);
    //  console.log("user",localStorage.getItem("userID"));
    //  this.setState({isSubmitted:true});
    const { userName, userPassword, domain, randomValue, captchaVal } = this.state;
    console.log("data", userName, userPassword, domain);
    localStorage.removeItem("userID");
    localStorage.removeItem("userPassword");
    localStorage.removeItem("domain");
    if (userName && userPassword && domain) {
      if (randomValue == captchaVal) {

        if (this.state.agressTerm) {
          this.setState({ spinnerVisible: true });
          API.signInAPI(userName, userPassword, domain)
            .then(response => response.json())
            .then(responsejson => {
              console.log("responsejson", responsejson);
              debugger;
              if (responsejson.message != "error user not authenticated") {
                localStorage.setItem("userID", this.state.userName);
                localStorage.setItem("userPassword", this.state.userPassword);
                localStorage.setItem("domain", this.state.domain);
                localStorage.setItem(
                  "lcolist",
                  JSON.stringify(responsejson.lcocode)
                );
                localStorage.setItem("currentlco", responsejson.lcocode[0].lcocode);
                // stock
                localStorage.setItem("isShowStock", responsejson.stock);
               // localStorage.setItem("isShowStock", `false`);
                localStorage.setItem("isShowAddorRemoveBtn", responsejson.add_remove_stb);
                 localStorage.setItem("isDistributor", responsejson.distributor);
              //  localStorage.setItem("isDistributor", `true`);
                

                this.setState({ isSubmitted: true, spinnerVisible: false });
              } else {
                this.setState({
                  errorMessage: responsejson.message,
                  spinnerVisible: false
                });
              }
            })
            .catch(error => {
              this.setState({ errorMessage: "unable to process" });
              this.setState({ spinnerVisible: false });
            });
        } else {
          this.setState({ errorMessage: "Please accept the Terms & Conditions" });
        }
      } else {
        this.setState({ errorMessage: "Invaild captcha" });
      }
    }
  }
  isSubmitSuccess() {
    if (this.state.isSubmitted) {
      return <Redirect to="/home" />;
    } else {
      return null;
    }
  }
  render() {
    return (
      <React.Fragment>


        <LoadingOverlay


          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={<img src={loadinggif} className="spinnerContent" color="green" />}

        ></LoadingOverlay>
        <div>
          <div className="header-top-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="logo-area">
                    <a href="#">
                      <img src={logo} style={{ width: 35, height: 35 }} />
                      <label style={{ color: "#fff" }}>Axom Communication and Cable</label> </a>
                  </div>
                </div>


              </div>
            </div>
          </div>



          <div className="notika-status-area">
            <div className="container">
              <div className="row">
                <div className="LoginContainer">
                  <div className="Loginpage">
                    <div className="LoginWhite">
                      <h3>Login</h3>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="form-example-int form-horizental">

                          <div class="row">
                            <div class="form-group form_label">
                              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                <label class="hrzn-fm">User Type</label>
                              </div>
                              <div class="col-lg-6 col-md-7 col-sm-7 col-xs-12">
                                <div class="nk-int-st">
                                  <select className="form-select form-control">
                                    {/* <option>Trade Partner</option>
                                    <option>Corporate User</option> */}
                                    <option>Multi System Operator</option>
                                    <option>Local Cable Operator</option>
                                  </select>

                                </div>
                              </div>
                            </div>
                            <div class="form-group form_label">
                              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 MT_space">
                                <label class="hrzn-fm">User ID</label>
                              </div>
                              <div class="col-lg-6 col-md-7 col-sm-7 col-xs-12 MT_space">
                                <div>
                                  <input
                                    type="text"
                                    class="form-control input-sm"
                                    placeholder="Enter User ID"
                                    value={this.state.userName}
                                    onChange={e => {
                                      this.setState({ userName: e.target.value });
                                    }}
                                    style={{ color: "#000" }}



                                  />
                                </div>
                              </div>

                            </div>
                            <div class="form-group form_label">
                              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 MT_space">
                                <label class="hrzn-fm">Password</label>
                              </div>
                              <div class="col-lg-6 col-md-7 col-sm-7 col-xs-12 MT_space">
                                <div>
                                  <input

                                    class="form-control input-sm"
                                    placeholder="Enter Password"
                                    onChange={e => {
                                      this.setState({ userPassword: e.target.value });
                                    }}
                                    value={this.state.userPassword}
                                    id="Password"

                                    type="password"
                                    style={{ color: "#000" }}


                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 MT_space"><label>Case Senstive</label></div>
                            </div>

                            <div class="form-group form_label">
                              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 MT_space">
                                <label class="hrzn-fm">&nbsp;</label>
                              </div>
                              <div class="col-lg-6 col-md-7 col-sm-7 col-xs-12 MT_space">
                                <div className="CaptchaSpace">
                                  <center>
                                    <label style={{ color: "#fff", alignSelf: "center", fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                                      {this.state.randomValue}
                                    </label>
                                  </center>

                                </div>
                              </div>

                            </div>
                            <div class="form-group form_label">
                              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 MT_space">
                                <label class="hrzn-fm">&nbsp;</label>
                              </div>
                              <div class="col-lg-6 col-md-7 col-sm-7 col-xs-12 MT_space">
                                <div>
                                  <input
                                    type="text"
                                    class="form-control input-sm"
                                    placeholder="Enter captcha"
                                    onChange={e => {
                                      this.setState({ captchaVal: e.target.value });
                                    }}
                                    value={this.state.captchaVal}
                                    id="captcha"
                                  />
                                </div>
                                <p className="Code_text">Type the code from the image</p>
                              </div>
                              <div className="col-lg-3 MT_space">
                                {/* <label>Case Senstive</label> */}
                              </div>
                            </div>

                            <div class="form-group form_label" style={{marginTop:-30,paddingTop:1}}>
                              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 MT_space">
                                <label class="hrzn-fm">&nbsp;</label>
                              </div>
                              <div class="col-lg-6 col-md-7 col-sm-7 col-xs-12 MT_space">
                              <div className="d-inline-flex" >
                                <Checkboxs
                                  style={{ fontSize: 20, color: "green" }}
                                  color="green"

                                  onClick={() => { this.setState({ agressTerm: !this.state.agressTerm }) }}
                                  checked={this.state.agressTerm}

                                />
                                <label style={{ fontSize: 9, marginTop: 8, marginLeft: 5 }}>I Agree Terms and Conditions,Privacy policy</label>
                                
                              </div>
                              </div>
                              <div className="">
                                {/* <label>Case Senstive</label> */}
                                <span
                                  onClick={() => {
                                    this.setState({ showTermAndCondition: true })
                                  }}
                                  style={{ fontSize: 15,  color: "blue",  cursor: "pointer" }}>Read Now</span>
                              </div>
                            </div>

                           
                            <div class="form-group form_label" style={{ height: 10, marginTop: -25 }}>
                              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 MT_space">
                                <label class="">&nbsp;</label>
                              </div>

                              <div class="col-lg-6 col-md-7 col-sm-7 col-xs-12 MT_space">
                                <div>
                                  <span
                                     className="colorred"
                                    style={{ color: "#FF5C5C" }}
                                  >
                                    {this.state.errorMessage}
                                  </span>
                                </div>

                              </div>

                            </div>

                            <div class="form-group form_label">
                              <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 MT_space">
                                <label class="hrzn-fm">&nbsp;</label>
                              </div>

                              <div class="col-lg-6 col-md-7 col-sm-7 col-xs-12 MT_space">
                                <div>
                                  <button
                                    onClick={e => {
                                      this.submit(e);
                                    }}
                                    class="btn btn-success notika-btn-success waves-effect">Login</button>
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="Login_text">

                    <div className="LoginWhite_1">
                      <h3>Impact Updates</h3>

                      <p>We thank all our Business Partners for making us India’s Leading Cable TV Network.</p>
                      <p>Dedicated Helpline number for our valued Business Partners.</p>
                      <p className="BoldNone">
                        East   - 0000 0000 000<br />
                        West   - 0000 0000 000<br />
                        North  - 0000 0000 000<br />
                        South  - 0000 0000 000</p>
                      <p className="colorred">Note : Business Partner, if login first time on this site. Use your quick login user ID and Password.</p>
                      
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <center>
              <img

                src="https://neilpatel.com/wp-content/uploads/2021/02/vrbo-successful-banner-advertising-example.png" style={{ width: "60vw", height: "15vh" }} />
            </center>
          </div>






          <div className="footer-copyright-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="footer-copy-right">
                    <p>Copyright © 2023
                      . All rights reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>








        </div>

        {/* form  */}
        {/* <div id="login-form" style={{backgroundImage:"linear-gradient(#02042C, #063FA1)"}}>
          <div className="container">

           
            <div className="row">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto" style={{width:"200%"}}>
                <div className="card card-signin my-5">
                  <div className="card-body" style={{marginTop:0}}>
                    <center style={{ fontSize: 24, fontWeight: 600 }}>AXOM</center>
                    <center style={{ fontSize: 13 }}>( Lco web app )</center>
                    <img src={GeneralValues.circlelogo} className="login-logo" alt="logo" />
                    <h5 className="card-title text-center">Log In </h5>

                    <form className="form-signin">

                      <div className="form-label-group">
                        
                        <input
                          onChange={e => {
                            this.setState({ userName: e.target.value });
                          }}
                          id="StbNumber"
                          className="form-control"
                          name="Username"
                          placeholder="User Name"
                          value={this.state.userName}
                        />
                        <label htmlFor="StbNumber">User Name</label>
                      </div>
                      <div className="form-label-group">
                        <input
                          onChange={e => {
                            this.setState({ userPassword: e.target.value });
                          }}
                          value={this.state.userPassword}
                          id="Password"
                          className="form-control"
                          type="password"
                          placeholder="Enter Password"
                        />
                        <label htmlFor="Password">Password </label>
                      </div>
                      <div style={{ flexDirection: "row" }}>
                        <div>
                          <label style={{ backgroundColor: "#000", color: "#fff", alignSelf: "center", marginLeft: "40%", padding: 10, fontSize: 18, fontWeight: "bold", borderRadius: 10, overflow: "left" }}>
                            {this.state.randomValue}
                          </label>
                          <img
                            onClick={() => { this.onRefreshCaptch() }}
                            src={refreshIcon} style={{ width: 40, height: 30, marginLeft: 15, paddingRight: 5, paddingLeft: 5 }} />
                        </div>
                        <input
                          onChange={e => {
                            this.setState({ captchaVal: e.target.value });
                          }}
                          value={this.state.captchaVal}
                          id="captcha"
                          className="form-control"

                          placeholder="Enter captcha"
                          style={{ borderRadius: 10 }}
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <label
                          style={{
                            fontSize: 15,
                            color: "red"
                          }}
                        >
                          {this.state.errorMessage}
                        </label>
                      </div>

                      <button
                        onClick={e => {
                          this.submit(e);
                        }}
                        className="btn btn-lg btn-primary btn-block text-uppercase"
                        style={{ backgroundColor: GeneralValues.mustard, color: GeneralValues.garland, borderWidth: 0 }}
                      >
                        Log In
                      </button>
                      <h6 className="mt-4 text-center">
                        <a
                          style={{ textDecoration: "none", color: "gray" }}
                          href="https://impactsms.in/" target="_blank">
                          Powered by Impact sms
                        </a>
                      </h6>
                      <div style={{ textAlign: "center" }}>

                        <label>Version 6.22</label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <DialogBoxes
          showTermAndCondition={this.state.showTermAndCondition}
          hindShowTermAndCondition={this.hindShowTermAndCondition.bind(this)}
          agressTerm={this.state.agressTerm}
          changeAdress={this.changeAdress.bind(this)}
        />

        {this.isSubmitSuccess()}
      </React.Fragment>
    );
  }
}
