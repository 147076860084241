import circleLog from '../assets/uvtv.png';
const Details={
     "url":"https://axom-sms.impactsms.in",
   // "url":"https://demo-daily.impactsms.in",
    "circlelogo":circleLog,
    "blueColor":"#007DC7",
    "mustard":"#040B26",
    "garland":"#fff",
    "bottomNav":"#366cc8"
}
export default Details;