import React from "react";
import { Button, ButtonGroup, Container, Card } from "react-bootstrap";
import {
  Rootcontainer,
  SearchDiv,
  MainContainer,
  RootDiv,
  RootTitle,
  RootBal,
  RootRight,
  Amt,
  MoreDetails,
  RootLeft,
  RootValue,
  CustomerStatus,
  BtnGroupRight,
  STBGroup,
  STBDetails,
  CustomerStatusDiv,
  ActivPackage,
  ChannelBox,
  ChannelName,
  ChannelPrice,
  AddPackageHeading
} from "./styled";
import "./index.css";
import { Redirect } from "react-router-dom";
import {
  MdPermIdentity,
  MdHome,
  MdFiberPin,
  MdCreditCard,
  MdRefresh,
  MdAddCircle,
  MdRemoveCircle,
  MdPowerSettingsNew,
  MdArrowBack,
  MdSearch
} from "react-icons/md";
import loadinggif from "../../assets/loding-gif.gif";
import API from "../../Api";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Drawer from "react-drag-drawer";
import Undraw from "react-undraw";
import GeneralValues from '../../GobelElements/Values';
import { FaPlus,FaCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';
export default class AllStb extends React.Component {
  constructor(props) {
    super(props);
    console.log("data from search", this.props.specifyUserDetails);
    const data = this.props.specifyUserDetails;
    console.log("data in customer details", data);

    this.state = {
      userMetaData: data,
      userSTBDetails: {},

      isShowAddPackageDialogBox: false,
      isShowRemovePackageDialogBox: false,

      spinnerVisible: false,
      pageCount: 3,
      totalPage: 0,
      count: "",
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      pageNo: 3,
      tableDataValue: "",
      isVisibleConformADD_Dialog: false,
      isVisibleConformRemove_Dialog: false,
      isVisibleTransactions_Dialog: false,
      isVisibleRemove_Dialog: false,
      valuePack: 0,
      removeValuePack: 0,
      isShowSuccessDialog: false,
      stbData: [],
      totalCount: 1,
      isShowLogOutDialog: false,
      inStockListSearchText: "",
           inStockDataList: [],
           Selectedstatus:"All",
           isShowPackageDialog:false,
           selectedPackageData:{},
           statusList:["All","Live","Disconnected"],
           searchResult:[],
           searchText:""
    };

    this.getLCO_Details();
    //this.getdata1()
    console.log("try", this.props.tableData);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillMount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const searchQuery = this.props.allSTBQuery;

    if (searchQuery) {
        console.log("render search : ", this.state.searchText)
        console.log("search1234", searchQuery);
        this.setState({ Selectedstatus: searchQuery,isSpinnerVisible:true });
        console.log("dddssdsfd", this.state.searchText);
        debugger;
        setTimeout(()=>{
          this.getdata1();
        },1000)
        
    }

}
  

  getdata1() {
    this.setState({ isSpinnerVisible: true });
    let data = [];
    // if(this.state.initialPageCount <=this.state.totalPageCount){

    const { lcoID, lcoPassword, Selectedstatus } = this.state;
    console.log("Selectedstatus",Selectedstatus);
    debugger;
    API.getSTBList(lcoID, lcoPassword,Selectedstatus)
      .then(response => response.json())
      .then(responsejson => {
        console.log("SearchResponse", responsejson);
        debugger;
        if (!responsejson.message) {
          
               this.setState({isShowSearchList:true,stbData:responsejson,searchResult:responsejson})
          //  this.setState({ isSpinnerVisible: false, isShowSearchList: true,customerList:responsejson.content });
        } else {
          this.setState({ isShowSearchList: false });
          // break
        }
        this.setState({ isSpinnerVisible: false });
      })
      .catch(err => {
        console.log("error", err);
        this.setState({ isSpinnerVisible: false });
        // break
      });
  }
  
  getLCO_Details() {
    debugger;
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then(response => response.json())
      .then(responsejson => {
        this.setState({
          lcoName: responsejson.lconame,
          lcoBalance: responsejson.balance,
          lcocode: responsejson.lcocode
        });
        this.getdata1();
      });
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />;
    } else {
      return null;
    }
  }
  logOut() {
    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  backTOTrandactions() {
    this.props.onMoveToTransactions();
  }
 moveToCustomerDetails(customerNo){
    this.setState({ isSpinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    API.singleCustomerDetailsAPI(lcoID, lcoPassword, "customersearchbasic", customerNo, 1,domainName)
    .then((response) => response.json())
    .then((responsejson) => {
        console.log("Added", responsejson);
        this.props.moveToCustomerDetails("customerdetails", responsejson,"");
        this.setState({ spinnerVisible: false});
       
    })
    .catch((err) => {
        console.log("error", err);
        this.setState({ spinnerVisible: false,});
    });
 }

 searchTheSTB(STB){
    let searchLowerCase = STB.toString().toLowerCase();
    let newData=[];
    for(let item of this.state.stbData){
        var stbLower = item.STB.toString().toLowerCase();
        var m = stbLower.search(searchLowerCase);
                if (m != -1) {
                    newData.push(item);
                }
    }
    this.setState({searchResult:newData});
 }
  render() {
    return (
      <Rootcontainer >
         <LoadingOverlay


className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
overlay={true}
active={this.state.isSpinnerVisible}
spinner={<img src={loadinggif} className="spinnerContent" color="green"  />}
styles={{ content: { width:"80%",minHeight:"65%" } }}

></LoadingOverlay>
        <Drawer
          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction="left"
        >
          <Card>
            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you want{" "}
                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                ?
              </div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success"
                className="mt-3 pull-right"
                onClick={() => {
                  this.setState({ isShowLogOutDialog: false });
                }}
              >
                Cancel
              </Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger"
                className="mt-3 pull-right"
                onClick={() => {
                  // this.props.hindNodataDialog();
                  this.logOut();
                }}
              >
                OK
              </Button>
            </Card.Body>
          </Card>
        </Drawer>

        

        {/* <Loader
                           visible={this.state.isShowStockLoader}
                           style={{ textAlign: "center", marginBottom: 10, }}
                           type="ThreeDots"
                           color="#00BFFF"
                           height="10"
                           width="20"
                       /> */}

        <RootDiv>
          <RootLeft>
            <RootTitle>Name :</RootTitle>

            <RootValue
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.onClickProfile();
              }}
            >
              {this.state.lcoName}
            </RootValue>
            <div className="lcocode">
              (<span style={{ color: "black" }}>Lco code:</span>{" "}
              {this.state.lcocode})
            </div>
          </RootLeft>

          <RootRight>
            <RootTitle>Balance :</RootTitle>

            <RootValue>
              {"\u20B9"} {this.state.lcoBalance}
            </RootValue>
          </RootRight>
        </RootDiv>
        <MainContainer>
            <div style={{marginTop:10,marginBottom:10}}>
            <div className="search-div" style={{marginRight:10}}>
                            <input type="search" placeholder="search..." value={this.state.searchText} onChange={(e) => { 
                                this.setState({ searchText: e.target.value},()=>{
                                    
                                        this.searchTheSTB(this.state.searchText);
                                    
                                }) 
                            }}
                                 />
                            <MdSearch />

                        </div>
            <select title="DRM List" style={{ width: 150 }}
                                          value={this.state.Selectedstatus}
                                          
                                            onChange={(e) => { 
                                                this.setState({ Selectedstatus: e.target.value },()=>{
                                                    this.getdata1();
                                                })
                                             }}

                                        >
                                            {this.state.statusList.map((item, i) => {
                                                return (
                                                    <option value={item}>{item}</option>
                                                );
                                            })}


                                        </select>
                                 
            </div>
          <table className="table table-striped">
            <thead>
              <th>Sno</th>
              <th>Name</th>
              <th>Address</th>
              <th>STB</th>
              <th>VC</th>
              <th>Status</th>
              <th>base Amount</th>
              <th>package</th>
             
            </thead>
            <tbody className="total-records">
              {this.state.searchResult.map((data, i) => {
                return (
                  <tr>
                    <td
                    onClick={()=>{
                        if(data.Customernum){
                            this.moveToCustomerDetails(data.Customernum)
                        }else{
                            this.props.moveToCreateCustomerForm(data.STB);
                        }
                       
                    }}
                    style={{color:data.Customernum ? "black":"green",textDecoration: data.Customernum ?"underline":"none",cursor:"pointer"}}>{data.Customernum ? data.Customernum : "ADD"}</td>
                    <td>{data.Customer ? data.Customer:"--"}</td>
                    <td>{data.Address ? data.Address:"--"}</td>
                    <td>{data.STB}</td>
                    <td>{data.MACVCChipNumber}</td>
                    <td style={{color:data.status == "Live" ? "green":"red"}}>{data.status}</td>
                    <td>{data.base}</td>
          
                    <td style={{maxLines:2,maxHeight:40,overflow:"hidden",textOverflow:"ellipsis"}}>
                                            <div style={{height:40,overflow:"hidden"}}> {data.Product}</div>
                                            {data.Product ? <label onClick={()=>{
                                                this.setState({selectedPackageData:data.Product,isShowPackageDialog:true})
                                            }} style={{color:GeneralValues.mustard,textDecoration:"underline",cursor:"pointer"}}>More</label>:null}
                                             </td>
                    
                  </tr>
                );
              })}
            </tbody>
          </table>
        </MainContainer>

        {this.logOutFunc()}


        <Drawer

open={this.state.isShowPackageDialog}
onRequestClose={this.toggle}
direction='bottom'
>

<Card >


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Packages:</div>

        <div style={{ marginLeft: 50, maxHeight: "30vh", marginRight: 30, color:GeneralValues.mustard, overflow: "auto", marginTop: 20 }}>
            {this.state.selectedPackageData}
        
        </div>


        <Button
            style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
            className="mt-3 pull-right"
            onClick={() => {

              this.setState({isShowPackageDialog:false});

            }}
        >OK</Button>



    </Card.Body>
</Card>


</Drawer>
      </Rootcontainer>
    );
  }
}
