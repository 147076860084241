export const CAS = {
    Dummy3: [
        {
            sno: 1,
            model: "HBOX 5C",
            type: "HD",
            cas: "Dummy3"
        },
        {
            sno: 2,
            model: "HBOX 2 CP",
            type: "HD",
            cas: "Dummy3"
        },
        {
            sno: 3,
            model: "HBOX 2 CE",
            type: "HD",
            cas: "Dummy3"
        },
        {
            sno: 4,
            model: "HBOX 2C",
            type: "HD",
            cas: "Dummy3"
        },
        {
            sno: 5,
            model: "HBOX 2C MINI",
            type: "HD",
            cas: "Dummy3"
        },
        {
            sno: 6,
            model: "MBOX- 6C",
            type: "SD",
            cas: "Dummy3"
        },
        {
            sno: 7,
            model: "MBOX 4C",
            type: "SD",
            cas: "Dummy3"
        },
        {
            sno: 8,
            model: "ASPIRE 8000 (TR120)",
            type: "HD",
            cas: "Dummy3"
        },
        {
            sno: 9,
            model: "ASPIRE 8000 (TR125)",
            type: "HD",
            cas: "Dummy3"
        },
        {
            sno: 10,
            model: "ASPIRE 5000 (TR123)",
            type: "SD",
            cas: "Dummy3"
        },
        {
            sno: 11,
            model: "EXZA (TR122)",
            type: "HD",
            cas: "Dummy3"
        },
        {
            sno: 12,
            model: "CSA025160",
            type: "SD",
            cas: "Dummy3"
        },
        {
            sno: 13,
            model: "YAGA",
            type: "SD",
            cas: "Dummy3"
        },
        {
            sno: 14,
            model: "HM 900C",
            type: "HD",
            cas: "Dummy3"
        },
        {
            sno: 15,
            model: "THD3123",
            type: "HD",
            cas: "Dummy3"
        },

    ],
    Dummy2: [
        {
            sno: 16,
            model: "ASPIRE 5000 (TR123)",
            type: "SD",
            cas: "Dummy2"
        },
        {
            sno: 17,
            model: "LBCA5000-W1 (INDRS117)",
            type: "SD",
            cas: "Dummy2"
        }
    ],
    Dummy1: [
        {
            sno: 18,
            model: "THD3123",
            type: "SD",
            cas: "Dummy1"
        },
        {
            sno: 19,
            model: "HM 900C",
            type: "SD",
            cas: "Dummy1"
        }
    ],
    Phandos_OTT: [
        {
            sno: 20,
            model: "OTT",
            type: "HD",
            cas: "Phandos_OTT"
        }
    ]
}