
import API from '../../Api';
import React, { Component } from 'react';
import logo from '../../assets/logo-3.png';
import loadinggif from '../../assets/loding-gif.gif';
import '../../screens/dashboard/index.css'
import { Card, Button, Col, Row, Container, InputGroup, FormControl, Dropdown } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Drawer from 'react-drag-drawer';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-spinner';
import { MDBCol, MDBFormInline, MDBBtn, MDBNavbarBrand, MDBNavbarToggler, MDBNavbar, MDBCollapse, MDBNavbarNav } from
  "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

import { Rootcontainer, RootDiv, RootTitle, RootBal, RootRight, RootLeft, RootValue } from './styled'
import {
  MdPersonPinCircle,
  MdPersonPin,
  MdLiveTv,
  MdSatellite,
  MdPowerSettingsNew,
  MdCast,
  MdBusinessCenter,
  MdSearch,
  MdNotInterested,
  MdInbox,
  MdAdd,
  MdNotifications

} from 'react-icons/md';
import DialogBoxs from './DialogBoxs';

import { IoMdCash, IoMdSearch, IoIosCheckmark, IoIosClose } from "react-icons/io";
import { FaUserAlt, FaRupeeSign, FaCircle, FaPlus } from 'react-icons/fa';
import { TiLocationOutline, TiLocation } from "react-icons/ti";

import Undraw from 'react-undraw';
import GeneralValues from '../../GobelElements/Values';


export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloggedOut: false,
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      dashboardData: {},
      errorMessage: "",
      spinnerVisible: false,
      lcoBalance: "",
      lcocode: "",
      lcocity: "",
      lcoName: "",
      isShowLowBalance: false,
      searchText: "",
      isShowNodataDialog: false,
      isShowLogOutDialog: "",
      lcoList: JSON.parse(localStorage.getItem("lcolist")),
      kk: [],
      lco: localStorage.getItem("currentlco"),
      lcoSearchText: "",
      lcoSearchResult: [],
      isShowLcoList: false,
      modData: [],
      inStockListSearchText: "",
      inStockCurrentPage: 1,
      inStockDataList: [],
      totalCountInStock: 0,
      isShowStockLoader: false,
      selectedCustomer: {},
      isShowLcoSearch: localStorage.getItem("isShowStock"),
      isShowDistributorSearch:localStorage.getItem("isDistributor"),
      isShowRechargeWarningDialog: false,
      isShowMantainanceDialog: false,
      maintIMG: "",        
      maintText: "",
      advtURL: "",
      isShowAdvertisementDialog: false,
      advtDuration: 0,
      paygateway: ""
      
    };
    console.log("username", localStorage.getItem("userID"), localStorage.getItem("lcolist"));
    let arr = [];
    for (var i = 0; i < this.state.lcoList.length; i++) {
      arr.push({ "id": this.state.lcoList[i].lconame + " " + this.state.lcoList[i].lcocode, "value": this.state.lcoList[i].lcocode, "name": this.state.lcoList[i].lconame })
    }
    console.log("data in kk", arr);
    this.setState({ modData: arr });
    // this.state.kk = arr;
    this.state.modData = arr;
    console.log("data in kk", arr, this.state.modData);
    // this.getDashboardDetails();
    this.getLCO_Details();
    this.getADVT()


    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // console.log("date in", date,arrayPackageDetails );
    console.log("loc list", JSON.parse(localStorage.getItem("lcolist")), this.state.kk);
  }
  componentDidMount() {
    this.setTheValue();
  }
  componentWillMount() {
    this.getDashboardDetails();
    this.checkLcoIsLow();
  }
  setTheValue() {

  }
  hindRechargeWarningDialog() {
    this.setState({ isShowRechargeWarningDialog: false })
  }
  checkLcoIsLow() {
    if (this.state.lcoBalance > 200) {
      this.setState({ isShowLowBalance: true });
    }
  }

  hindLogOutDialog() {
    this.setState({ isShowLogOutDialog: false });
  }
  hindNodataDialog() {
    this.setState({ isShowNodataDialog: false });
  }
  hindAdvertisementDialog() {
    debugger;
    this.setState({ isShowAdvertisementDialog: false })
  }
  checkToShowTheLcoList(text) {
    var len = text.length;
    console.log("length", len, this.state.modData[0].id);
    if (len) {
      var dummy = [];
      var length = this.state.lcoList.length;
      var searchText = text.toString().toLowerCase();
      console.log("data", length, len);
      for (var i = 0; i < length; i++) {

        var val = ""
        val = this.state.modData[i].id.toString().toLowerCase();
        // var lower = val.toString();
        // console.log("lower",val,searchText);

        var n = val.search(searchText);
        if (n != -1) {
          dummy.push(this.state.modData[i]);
        }
      }
      console.log("dummy", dummy);
      this.setState({ isShowLcoList: true, lcoSearchResult: dummy });
    } else {
      this.setState({ isShowLcoList: false });
    }
  }
  getADVT() {



    const { lcoID, lcoPassword, domainName, searchText } = this.state;
    console.log("vl", lcoID, lcoPassword, domainName);
    debugger;
    API.getAdvt(lcoID, lcoPassword, domainName)
      .then((response) => response.json())
      .then((responsejson) => {
        console.log("responser from advt", responsejson);
        debugger;
        if (responsejson.url) {
          this.state.advtURL = responsejson.url
          this.setState({ advtURL: responsejson.url, isShowAdvertisementDialog: true, advtDuration: parseInt(responsejson.duration) });

        }
        let myInterval = setInterval(() => {
          this.setState({ advtDuration: this.state.advtDuration - 1 })
          if (this.state.advtDuration == 0) {
            clearInterval(myInterval);
            this.setState({ isShowCancelBtn: true })
          }
        }, 1000)


      }).catch((error) => {
        console.log("Error in advt", error);
        debugger;
      });

  }
  getSTBDetails(item) {
    console.log("item", item);
    if(this.props.content != "customerdetails"){
    if (item.Customer) {
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, domainName, searchText } = this.state;
      API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("response", responsejson);
          debugger;
          this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "",spinnerVisible: false,inStockListSearchText:""  });
          if (responsejson.message == "no data") {
            this.setState({ });
          } else {
            localStorage.setItem("currentlco", item.Lcocode);
            // this.setState({ spinnerVisible: false });
            this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
          }
        });
    }
    else {

      this.setState({ isShowNodataDialog: true, selectedCustomer: item })
    }
  }else{
    this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "",spinnerVisible: false,inStockListSearchText:""  });
    this.props.customerGlobalRefreshOn(item);
  }

  }
  moveTOUnmappedStbCreationFormScreen() {

    console.log("this.state.selectedCustomer.SNo", this.state.selectedCustomer.SNo, this.state.selectedCustomer);
    debugger;
    this.props.moveToCreateCustomerForm(this.state.selectedCustomer.SNo)
  }

  // componentWillReceiveProps(){
  //   const searchQuery = this.props.searchQuery;
  //   if(searchQuery){
  //     this.state({})
  //   }
  // }
  getLCO_Details() {
    // const { lcoID, lcoPassword, domainName } = this.state;
    const { lcoID, lcoPassword, domainName } = this.state;

    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then((response) => response.json())
      .then((responsejson) => {
console.log("responsejson getLCO_Details",responsejson);
debugger;
        if (responsejson.message == "error user not authenticated") {
          this.logOut()
        } else {
          this.setState({
            lcoName: responsejson.lconame, lcoBalance: responsejson.balance,
            lcocode: responsejson.lcocode,
            kk: [{ "name": "mani" }],
            paygateway: responsejson.paygateway
          })
          // var arr=[];
          // for(var i =0;i<this.state.lcoList.length;i++){
          //    arr.push({"name":this.state.lcoList[i].lconame,"value":this.state.lcoList[i].lcocode})
          // }
          // console.log("data in kk",arr);
          // // this.setState({kk:arr});
          // this.state.kk =arr;
          // console.log("data in kk",arr,this.state.kk);
        }

      });
  }


  getDashboardDetails() {
    this.setState({ spinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    console.log("data", lcoID, lcoPassword, domainName);
    API.dashboardAPI(lcoID, lcoPassword, "dashboard", domainName)
      .then((response) => response.json())
      .then((responsejson) => {
        console.log("responser", responsejson);
        debugger;



        this.setState({
          dashboardData: responsejson, spinnerVisible: false, errorMessage: "",
          isShowMantainanceDialog: responsejson.maint,
          maintText: responsejson.mainttext,
          maintIMG: responsejson.maintimage

        });
        this.getLCO_Details();

        console.log("DashBoard", this.state.dashboardData);


      })
      .catch((err) => {
        console.log("error", err);
        this.setState({ errorMessage: "Unable to load !", spinnerVisible: false });
      });
  }

  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />
    } else {
      return null;
    }
  }
  logOut() {

    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  onSelectLco(text) {
    this.props.changeInRefresh()
    localStorage.setItem("currentlco", text);
    this.getLCO_Details();
    this.getDashboardDetails();
    this.setState({ isShowLcoList: false, lcoSearchResult: [], lcoSearchText: "" })
  }

  nextPageData(data, pageNo) {
    // debugger;
    let lengthOfACustomerList = this.state.inStockDataList.length;
    console.log("lenght of the list", this.state.totalCountInStock);
    if (lengthOfACustomerList < this.state.totalCountInStock) {
      // console.log("pageCount", pageNo);
      // this.setState({ animating: true });
      this.setState({ isShowStockLoader: true })
      const { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
      API.stockListSearch(lcoID, lcoPassword, "liststock", inStockListSearchText, pageNo, domainName)

        .then((response) => response.json())
        .then((responsejson) => {
          if (responsejson.count) {
            console.log("SearchResponse", responsejson);
            let oldData = data;
            let newData = oldData.concat(responsejson.content);
            let count = this.state.inStockCurrentPage + 1;

            this.setState({ inStockDataList: newData, inStockCurrentPage: count, isShowStockLoader: false });
            // console.log("data", this.state.customerList);
            // if (newData.length < responsejson.count) {

            //     this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
            // }
          }
        })
        .catch((err) => {

          console.log("error", err);
          // this.setState({ isSpinnerVisible: false });
          // break


        });
    }
  }

  stockListSearch(text) {

    const { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
    if (text.length > 2) {
      this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

      API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("response", responseJson);
          if (responseJson.count) {


            this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
            console.log("In stock list", this.state.inStockDataList);
          } else {
            this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
          }
        });
    } else {
      this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
    }
  }
  handleScroll(e) {
    // console.log("scorller",e.target.scrollHeight,e.target.scrollTop,e.target.clientHeight);
    const bottom = e.target.scrollHeight - (e.target.scrollTop) === e.target.clientHeight;
    // console.log("lco",bottom);
    if (bottom) {

      this.nextPageData(this.state.inStockDataList, (this.state.inStockCurrentPage + 1));
    }
  }

  isShowLcoSearchFunc() {
    // if (this.state.isShowLcoSearch != "false" || this.state.isShowDistributorSearch != "false") {
      if (this.state.lcoList.length > 1) {
      return (
        <input type="text" text="Search" placeholder="Lco Search....              "
          onChange={(e) => {

            this.setState({ lcoSearchText: e.target.value })
            this.checkToShowTheLcoList(e.target.value);
          }}
          // onKeyPress={(e) => {

          //   // if (e.keyCode == 13 || e.which == 13) {
          //   //   this.getSTBDetails();
          //   // }
          // }}
          value={this.state.lcoSearchText}
          className="input-STB"
          style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold", marginRight: 15 }} />
      );
    } else {
      return null;
    }
  }

  render() {


    return (
      //   <LoadingOverlay
      //   className="spinner"
      //   overlay={true}
      //   active={this.state.spinnerVisible}
      //   spinner={<BounceLoader className="spinnerContent" color="green" />}

      // >

      <Rootcontainer>

        <DialogBoxs
          isShowNodataDialog={this.state.isShowNodataDialog}
          hindNodataDialog={this.hindNodataDialog.bind(this)}
          isShowLogOutDialog={this.state.isShowLogOutDialog}
          hindLogOutDialog={this.hindLogOutDialog.bind(this)}
          logOut={this.logOut.bind(this)}
          moveToCreateCustomerForm={this.props.moveToCreateCustomerForm.bind(this)}
          selectedCustomer={this.state.selectedCustomer}
          moveTOUnmappedStbCreationFormScreen={this.moveTOUnmappedStbCreationFormScreen.bind(this)}
          isShowRechargeWarningDialog={this.state.isShowRechargeWarningDialog}
          hindRechargeWarningDialog={this.hindRechargeWarningDialog.bind(this)}
          lcoID={this.state.lcoID}
          lcocode={this.state.lcocode}
          isShowMantainanceDialog={this.state.isShowMantainanceDialog}
          maintIMG={this.state.maintIMG}
          maintText={this.state.maintText}
          getDashboardDetails={this.getDashboardDetails.bind(this)}
          advtURL={this.state.advtURL}
          isShowAdvertisementDialog={false}
          advtDuration={this.state.advtDuration}
          hindAdvertisementDialog={this.hindAdvertisementDialog.bind(this)}
          paygateway={this.state.paygateway}


        />
        {/* <LoadingOverlay

className={this.state.spinnerVisible  ? "displaySpinner" : ""}
overlay={true}
active={this.state.spinnerVisible }
spinner={<img src={loadinggif} className="spinnerContent" color="green" />}
styles={{ content: { width:"80%",minHeight:"65%" } }}
></LoadingOverlay> */}

        <Drawer

          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: 'bold', textAlign: "center" }}>Do you want <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span> ?</div>

              <Undraw className="undrawimg" name="monitor" />

              <Button
                style={{ marginLeft: 20 }}
                className="success" className="mt-3 pull-right"
                onClick={() => {


                  this.setState({ isShowLogOutDialog: false })

                }}
              >Cancel</Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger" className="mt-3 pull-right"
                onClick={() => {

                  // this.props.hindNodataDialog();
                  this.logOut();

                }}
              >OK</Button>



            </Card.Body>
          </Card>

        </Drawer>

        <Drawer

          open={this.state.isShowLowBalance}
          onRequestClose={this.toggle}
          direction='left'

        >
          {/* <div>Hey Im inside a drawer!</div> */}
          <Card >

            <Card.Body>
              <div style={{ fontSize: 20, fontWeight: 'bold' }}> Low Balance !</div>

              <button
                onClick={() => this.setState({ isShowLowBalance: false })}
                style={{ background: "red", color: 'white', padding: 15, marginTop: 20, textAlign: "right", display: "block", width: 60, height: 60, marginLeft: 256, borderRadius: 20 }}>OK</button>

            </Card.Body>
          </Card>

        </Drawer>



        <div className="header-div" style={{ backgroundColor: GeneralValues.blueColor}}>
          {/* <h3 style={{ marginLeft: '80px', }}>DASHBOARD</h3> */}
          <h3 style={{ marginLeft: '80px',marginRight:"-20px",textTransform:"uppercase",fontSize:15,marginTop:15 }}>Axom Communication and Cable</h3>


          <img src={GeneralValues.circlelogo} style={{ float: "left", marginLeft: "-272px",marginRight:20 }} className="logo-img" alt="logo" />

          <MdPowerSettingsNew onClick={() => { this.setState({ isShowLogOutDialog: true }) }} value="Logout" className="fontSize login" />



          <div style={{ width: 130, height: 30, display: "flex", marginLeft: "73%", marginTop: 10, justifyContent: "flex-end" }}>
            {/* <Dropdown  style={{marginRight:"45vw",backgroundColor:"transparent"}}>
         <Dropdown.Toggle style={{backgroundColor:"transparent",borderColor:"white",borderWidth: 3,}}  id="dropdown-basic" >
    lco list
  </Dropdown.Toggle>
         <Dropdown.Menu style={{overflow:"auto",maxHeight:"40vh"}}>
           {this.state.lcoList.map((item,i)=>{
             return(
              <Dropdown.Item onClick={()=>{this.onSelectLco(item.lcocode)}}>
              {item.lconame}
              </Dropdown.Item>
             );
           })}
           </Dropdown.Menu>
           
         </Dropdown> */}
            {this.isShowLcoSearchFunc()}
            <div
              onScrollCapture={(e) => { console.log("e", e) }}
              style={{
                position: "absolute",
                display: this.state.isShowLcoList ? "block" : "none",
                listStyle: "none",
                backgroundColor: "white",
                padding: 0,
                boxShadow: "0px 1px 3px grey",
                zIndex: 9,
                width: "20vw",
                top: 50,
                overflow: "auto",
                maxHeight: "30vh",
                marginRight: "370px"
                // marginLeft: "-300px !important"
              }}
            //  onScroll={(e)=>console.log("scrolling",e)}
            >
              <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>Lco Lis:</label>
              <label 
                onClick={()=>{
                  this.setState({isShowLcoList:false});
                }}
              style={{ fontSize: 15, fontWeight: "bold", color: "red", marginLeft: 15 }}>Close</label>
              <ul

                style={{ listStyle: "none", padding: 10 }}>
                {this.state.lcoSearchResult.map((item, i) => {

                  return (
                    <li
                      onScroll={(e) => console.log("scrolling", e)}
                      onClick={() => { this.onSelectLco(item.value) }}
                      style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                      {item.name} <span style={{ fontSize: 12, color: "#d61818", paddingLeft: 1 }}
                      >({item.value})</span></li>
                  );
                })}
              </ul>

            </div>



            <input type="text" text="Search" placeholder="Global Search..."
              onChange={(e) => {
                this.setState({ inStockListSearchText: e.target.value,isShowInStockList:false })
                // this.stockListSearch(e.target.value);
              }}

              value={this.state.inStockListSearchText}
              className="input-STB"
              style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold" }} />
               {/* <button className="name" style={{ width: 100, background: "radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)" }} onClick={() => {
              // this.setState({ isShowRechargeWarningDialog: true })
              // this.stockListSearch(this.state.inStockListSearchText);
            }}>
              Search
            </button> */}
            {/* {this.isShowInStockList()} */}
            <div style={{
              position: "absolute",
              display: this.state.isShowInStockList ? "block" : "none",
              listStyle: "none",
              backgroundColor: "white",
              padding: 0,
              boxShadow: "0px 1px 3px grey",
              zIndex: 9,
              width: "22vw",
              top: 50,
              overflow: "auto",
              maxHeight: "30vh",

            }}
              onScroll={(e) => this.handleScroll(e)}>
              <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
              <label 
                onClick={()=>{
                  this.setState({isShowInStockList:false});
                }}
              style={{ fontSize: 15, fontWeight: "bold", color: "red", marginLeft: 15 }}>Close</label>
              <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
              <ul style={{ listStyle: "none", padding: 10 }}>

                {this.state.inStockDataList.map((item, i) => {

                  return (


                    <li className="search-list"
                      onClick={() => {
                        // if (item.Customer) {
                        this.getSTBDetails(item)
                        // } else {
                        //   this.moveTOUnmappedStbCreationFormScreen(item)
                        // }

                      }}
                      style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                      <div>
                        <lable style={{ display: "block" }}>
                          Name:
                          {item.Customer ? item.Customer : <FaPlus style={{
                            background: " #0a3f65",
                            color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                          }} />}

                          <FaCircle style={{
                            display: item.status ? "inline-block" : "none",
                            height: "12px", float: "right", marginTop: "8px",
                            color: item.status == "Live" ? "green" : "red",
                          }} />


                        </lable>

                        <lable style={{ display: "block" }}>STB: {item.SNo}

                          <div style={{
                            fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                            padding: "0px 7px", height: "25px",
                            backgroundColor: item.paid === "paid" ? "green" : "red",
                          }}>{item.paid}  </div>
                        </lable>

                        <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}

                          <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
                          </div>
                        </lable>
                      </div>
                    </li>

                  );
                })}
              </ul>

              {/* <Loader
                visible={this.state.isShowStockLoader}
                style={{ textAlign: "center", marginBottom: 10, }}
                type="ThreeDots"
                color="#00BFFF"
                height="10"
                width="20"
              /> */}
            </div>

            <button className="name" onClick={() => {
              this.stockListSearch(this.state.inStockListSearchText);
              // if (this.state.inStockListSearchText) {
              //   this.props.moveToSearchWithQuery(this.state.inStockListSearchText)
              // }
            }}>
              <IoMdSearch className="search" />
            </button>
            {this.state.paygateway ? 
            <button className="name" style={{ width: 100, background: "linear-gradient(to right, rgb(32, 28, 71), rgb(43, 40, 83), rgb(48, 43, 99))" }} onClick={() => {
              this.setState({ isShowRechargeWarningDialog: true })
            }}>
              Topup
            </button> :null}
            <button className="name" style={{ width: 100, background: "linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))", borderRadius: 150 / 2 }} onClick={() => {
              this.props.onClickNotifications()
            }}>
              <MdNotifications style={{ width: 20, height: 20 }} />
            </button>
          </div>


        </div>



        {/* <label className="errorBlock">{this.state.errorMessage}</label> */}
        
        

       



           






         
        {/* </div> */}
        {this.logOutFunc()}


      </Rootcontainer>

      // </LoadingOverlay>

    )
  }
}