import React, { useEffect, useState } from "react";
import './index.css';
import './bootstrap.min.css';
import './style.css';

import { Outlet, Link } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { MdSearch } from 'react-icons/md';
import { MdArrowCircleUp } from 'react-icons/md';
import { MdNotifications } from 'react-icons/md';
import { MdOutlineDashboard } from 'react-icons/md';
import { MdOutlineClose } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdShoppingBag } from 'react-icons/md';
import { MdTransferWithinAStation } from 'react-icons/md';
import { MdContactPage } from 'react-icons/md';
import { MdApps } from 'react-icons/md';
import API from "../../../Api";
import GeneralValues from '../../../GobelElements/Values';
import loadinggif from '../../../assets/loding-gif.gif';
import {
    Button,
    ButtonGroup,
    Container,
    ButtonToolbar,
    Jumbotron,
    Card,
    Col,
    Row
} from "react-bootstrap";
import Drawer from 'react-drag-drawer';
import LoadingOverlay from 'react-loading-overlay';

const Template = () => {
    const [isShowOthers, setIsShowOthers] = useState(false);
    const [isShowStockOperations, setIsShowStockOperations] = useState(false);
    const [isShowReports, setIsReports] = useState(false);
    const [lcoID, setLcoID] = useState(localStorage.getItem("userID"));
    const [lcoPassword, setLcoPassword] = useState(localStorage.getItem("userPassword"));
    const [domainName, setDomainName] = useState(localStorage.getItem("domain"));
    const [CurrentLcoCode, setCurrentLcoCode] = useState(localStorage.getItem("currentlco"));
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [isShowPackageDialog, setIsShowPackageDialog] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [selectedPackageData, setSelectedPackageData] = useState([]);
    const [selectedTimeLine, setSelectedTimeLine] = useState("Today");
    const [onlyActiveCustomer, setOnlyActiveCustomer] = useState(false);

    const [lcocode, setLcocode] = useState("");
    const [lcoList, setLcoList] = useState([]);
    const [searchLcoList, setSearchLcoList] = useState([]);
    const [selectedLco, setSelectedLco] = useState({});
    const [isShowLcoListDialog, setIsShowLcoListDialog] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isStockOperation, setIsStockOperation] = useState(localStorage.getItem("isShowStock"));

    useEffect(() => {
        // getExtendListFunc();
        let isStock = localStorage.getItem("isShowStock");
        if (isStock) {
            console.log("LCo list", JSON.parse(localStorage.getItem("lcolist")));
            setLcoList(JSON.parse(localStorage.getItem("lcolist")));
            setSearchLcoList(JSON.parse(localStorage.getItem("lcolist")));
        }
    }, [])
    const dateFilter = (ArrayOfData, type) => {
        let filterData = []
        if (type == "Today") {
            let start = new Date();
            let endDate = new Date();

            start.setHours(0, 0, 0, 0);
            //endDate.setDate(endDate.getDate() + 20);
            endDate.setHours(23, 59, 0, 0);
            console.log("date start", start)
            console.log("date end", endDate)
            debugger;

            for (let obj of ArrayOfData) {
                let userEndDate = new Date(obj.End);
                console.log("userEndDate", userEndDate);
                console.log("userEndDate  >= start   && userEndDate <= endDate", userEndDate >= start, userEndDate <= endDate);
                if (userEndDate >= start && userEndDate <= endDate) {
                    if (onlyActiveCustomer) {
                        if (obj.status == "Live") {
                            filterData.push(obj);
                        }
                    } else {
                        filterData.push(obj);
                    }

                }

            }
            setSearchResult(filterData)

        } else if (type == "Tomorrow") {
            let start = new Date();
            let endDate = new Date();

            start.setHours(0, 0, 0, 0);
            endDate.setDate(endDate.getDate() + 1);

            endDate.setHours(23, 59, 0, 0);
            console.log("date start", start)
            console.log("date end", endDate)
            debugger;

            for (let obj of ArrayOfData) {
                let userEndDate = new Date(obj.End);
                console.log("userEndDate", userEndDate);
                console.log("userEndDate  >= start   && userEndDate <= endDate", userEndDate >= start, userEndDate <= endDate);
                if (userEndDate >= start && userEndDate <= endDate) {
                    if (onlyActiveCustomer) {
                        if (obj.status == "Live") {
                            filterData.push(obj);
                        }
                    } else {
                        filterData.push(obj);
                    }
                }

            }
            setSearchResult(filterData)
        } else if (type == "Next 2 Days") {
            let start = new Date();
            let endDate = new Date();

            start.setHours(0, 0, 0, 0);
            endDate.setDate(endDate.getDate() + 2);

            endDate.setHours(23, 59, 0, 0);
            console.log("date start", start)
            console.log("date end", endDate)
            debugger;

            for (let obj of ArrayOfData) {
                let userEndDate = new Date(obj.End);
                console.log("userEndDate", userEndDate);
                console.log("userEndDate  >= start   && userEndDate <= endDate", userEndDate >= start, userEndDate <= endDate);
                if (userEndDate >= start && userEndDate <= endDate) {
                    if (onlyActiveCustomer) {
                        if (obj.status == "Live") {
                            filterData.push(obj);
                        }
                    } else {
                        filterData.push(obj);
                    }
                }

            }
            setSearchResult(filterData)
        } else if (type == "Next 3 Days") {
            let start = new Date();
            let endDate = new Date();

            start.setHours(0, 0, 0, 0);
            endDate.setDate(endDate.getDate() + 3);

            endDate.setHours(23, 59, 0, 0);
            console.log("date start", start)
            console.log("date end", endDate)
            debugger;

            for (let obj of ArrayOfData) {
                let userEndDate = new Date(obj.End);
                console.log("userEndDate", userEndDate);
                console.log("userEndDate  >= start   && userEndDate <= endDate", userEndDate >= start, userEndDate <= endDate);
                if (userEndDate >= start && userEndDate <= endDate) {
                    if (onlyActiveCustomer) {
                        if (obj.status == "Live") {
                            filterData.push(obj);
                        }
                    } else {
                        filterData.push(obj);
                    }
                }

            }
            setSearchResult(filterData)
        } else if (type == "All") {

            if (onlyActiveCustomer) {
               for(let obj of ArrayOfData){
                if(obj.status == "Live"){
                    filterData.push(obj);
                }
               }
            } else {
                setSearchResult(ArrayOfData);
            }
            
        }
    }
    const getExtendListFunc = () => {

        setSpinnerVisible(true);
        // const { lcoID, lcoPassword, domainName } = this.state;
        API.getExtendListWithLcoCode(lcoID, lcoPassword, domainName,CurrentLcoCode)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson);
                debugger;

                // var ArrayOfData = Object.keys(responseJson).reduce((prevVal,key)=>{
                //     var p = { ...responseJson.content[key],toggleMode:false };
                //     prevVal.push(p);

                //     return prevVal;
                // },[]);
                var ArrayOfData = [];

                for (var i in responseJson) {
                  //  responseJson[i].toggleMode = false;
                    ArrayOfData.push(responseJson[i]);
                }
                setSpinnerVisible(false);
                setDataList(ArrayOfData);
                dateFilter(ArrayOfData, selectedTimeLine);

                // setSearchResult(ArrayOfData)
                //this.setState({ spinnerVisible: false, DataList: ArrayOfData, searchResult: ArrayOfData });
                console.log("arrray", ArrayOfData);
                debugger;

            }).catch((error) => {
                console.log("Error", error);
                setSpinnerVisible(false);
            })
    }
    const isSuspend = (val) => {
        if (val) {
            return (
                <label style={{ color: "red", fontWeight: "bold", fontSize: 13, marginLeft: 7, display: "block" }}>Suspend</label>
            );
        } else {
            return null;
        }
    }
   const dowloadCSvm =(data)=> {
        console.log("data", data);
        let csvRow = [];
        let A = [['STB', 'Model', 'Customer', 'Customer Number', 'MACVCChipNumber', "Base", 'DRM', 'Start', 'End', 'status', 'Phone number', 'Lcocode', 'package', 'Address']];
        for (var m of data) {
            A.push([`'${m.STB}`, m.Model, m.Customer, `'${m.Customernum}`, `'${m.MACVCChipNumber}`, m.base, m.cas, `'${m.Start}`, `'${m.End}`, m.status, m.Phone ? `'${m.Phone}` : '--', m.Lcocode, m.Product.join('; '), m.Address.split(',').join("_")])
        }
        console.log("A", A);
        for (var i = 0; i < A.length; i++) {
            csvRow.push(A[i].join(","))
        }
        console.log("csvRow", csvRow);
        var csvString = csvRow.join(",\n");
        console.log("csvString", csvString);
        var a = document.createElement('a');
        a.href = 'data:attachment/csv,' + csvString;
        a.target = "_Blank"
        a.download = `Monthly_Leger.csv`;
        document.body.appendChild(a);
        a.click();
    }
    const handleFilter = (text) => {
        console.log(text);
       
        if (text !== "") {
          let searchedLcoList = lcoList.filter((item) =>
           (item.lconame.toLowerCase().includes(text.toLowerCase()) || item.lcocode.toLowerCase().includes(text.toLowerCase())) ? item : null
          );
          setSearchLcoList(searchedLcoList);
    
        } else {
            setSearchLcoList(lcoList);
        }
      };

    return (
        <div>
 <LoadingOverlay

className={spinnerVisible ? "displaySpinner" : ""}
overlay={true}
active={spinnerVisible}
spinner={<img src={loadinggif} className="spinnerContent" color="green" />}
styles={{ content: { width: "80%", minHeight: "65%" } }}
></LoadingOverlay>



            <div className="breadcomb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="breadcomb-list space_align">
                                <div className="col-lg-12 col_lg_ctrl">
                                    <div className="form-example-int form-example-st">
                                        <div className="row">
                                            {/* <div className="col-lg-2">
                                        <div className="nk-int-st">
                                                <label>Lco Code</label>
                                            </div>
                                            <div className="nk-int-st">
                                                <input type="text" class="form-control form_gray input-sm"  />
                                            </div>
                                            </div> */}


                                            <div className="col-lg-2">
                                                <div className="nk-int-st">
                                                    <label>Select Line Man Name</label>
                                                </div>
                                                <div className="nk-int-st">
                                                    <select style={{ color: "#000" }} className="form-control form_black">
                                                        <option selected>All Linemen</option>
                                                        {/* <option>3 Start Cable Network (ID - 50393)</option>
                                                        <option>4 Start Cable Tv Service (ID - 50395)</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="nk-int-st">
                                                    <label>Due Upto Next Days</label>
                                                </div>
                                                <div className="nk-int-st">
                                                    <select
                                                        onChange={(e) => {
                                                            console.log(e.target.value);
                                                            setSelectedTimeLine(e.target.value);
                                                            dateFilter(dataList, e.target.value)
                                                        }}
                                                        style={{ color: "#000" }} className="form-control form_black">

                                                        <option selected>Today</option>
                                                        <option>Tomorrow</option>
                                                        <option>Next 2 Days</option>
                                                        <option >Next 3 Days</option>
                                                        <option >All</option>
                                                        {/* <option>4 Start Cable Tv Service (ID - 50395)</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-3 MT_20">

                                                <div className="nk-int-st">
                                                    <input type="radio" id="rad1" name="fav_language" value="CSS" />
                                                    <label>Due Upto Next Days</label>
                                                </div>

                                            </div> */}
                                             {isStockOperation ?   <div class="col-lg-3">
                                                <div class="nk-int-st">
                                                    <label>Select LCO</label>
                                                </div>
                                                <div class="nk-int-st">
                                                    <input
                                                        onChange={(e) => {
                                                            console.log(e.target.value);
                                                            // setEndDate(e.target.value)
                                                        }}
                                                        placeholder="...."
                                                        onClick={() => {
                                                            setIsShowLcoListDialog(!isShowLcoListDialog);
                                                        }}
                                                        value={selectedLco ? selectedLco.lcocode : "...."}
                                                        style={{ color: "#000" }} type="button" class="form-control form_gray input-sm" />
                                                </div>
                                            </div>:null}
                                            <div className="col-lg-3 MT_20">

                                                <div className="nk-int-st">
                                                    <input
                                                        onChange={(e) => {

                                                            setOnlyActiveCustomer(!onlyActiveCustomer);
                                                            dateFilter(dataList, selectedTimeLine)
                                                        }}
                                                        type="checkbox" id="rad2" name="fav_language" value="CSS" selected />
                                                    <label>Active Customer</label>

                                                </div>
                                             

                                            </div>
                                            <div class="col-lg-3">
                                                <button
                                                    onClick={() => {
                                                        getExtendListFunc()
                                                    }}
                                                    style={{ marginTop: 10 }} class="btn btn-success notika-btn-success">Show Report</button>
                                            </div>
                                           
                                         
                                           
                                            
                               
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                            <div class="bsc-tbl-st bg_white tableScroll">
                                <div className="col-lg-12 col_lg_ctrl">
                                    <div class="form-example-int MT20 MTLeft MB10">
                                        <button 
                                        
                                        onClick={()=>{
                                            dowloadCSvm(searchResult);
                                        }}
                                        className="btn btn-success notika-btn-success">Export to Excel</button>

                                    </div>
                                </div>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>

                                            <th> STB </th>
                                            <th>Name</th>
                                            <th>Address</th>
                                            <th>Due</th>
                                            <th>Status</th>
                                            <th>Durations</th>
                                            <th>Products</th>
                                            <th>Phone</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchResult.map((data, i) => {
                                            return (
                                                <tr>


                                                    <td>{data.STB} {isSuspend(data.suspend)}</td>
                                                    <td>{data.Customer}</td>
                                                    <td>{data.Address}</td>
                                                    <td>{data.base}</td>
                                                    <td>{data.status}</td>
                                                    <td style={{ color: "red" }}>{data.Start} <label style={{ color: "black" }}>to</label> {data.End}</td>
                                                    <td style={{ maxLines: 2, height: 40, overflow: "hidden", textOverflow: "ellipsis" }}>
                                                        <div style={{ height: 40, overflow: "hidden" }}> {data.Product}</div>
                                                        {data.Product ? <label onClick={() => {
                                                            setSelectedPackageData(data.Product);
                                                            setIsShowPackageDialog(true)
                                                            //this.setState({ selectedPackageData: data.Product, isShowPackageDialog: true })
                                                        }} style={{ color: GeneralValues.mustard, textDecoration: "underline" }}>More</label> : null}
                                                    </td>
                                                    <td>{data.Phone}</td>







                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            <Drawer

                open={isShowPackageDialog}
                //onRequestClose={toggle}
                direction='bottom'
            >

                <Card >


                    <Card.Body className="pl-3 pr-3">
                        <div style={{ fontSize: 18, fontWeight: "bold", color: "red" }}>Packages:</div>

                        <div style={{ marginLeft: 50, maxHeight: "30vh", marginRight: 30, color: "rgb(26, 172, 195)", overflow: "auto", marginTop: 20 }}>
                            {selectedPackageData ?
                                selectedPackageData.map((item, i) => {
                                    return (
                                        <label style={{ fontSize: 13, marginRight: 10 }}>{`${item},`}</label>
                                    );
                                }) : null
                            }
                        </div>


                        <Button
                            style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
                            className="mt-3 pull-right"
                            onClick={() => {
                                setIsShowPackageDialog(false);


                            }}
                        >OK</Button>



                    </Card.Body>
                </Card>


            </Drawer>

            <Drawer
                open={isShowLcoListDialog}
                // onRequestClose={this.toggle}
                direction="left"
            >
                <Card>
                    <Card.Body className="pl-3 pr-3">
                        <div style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>LCO</div>
                        <input
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                handleFilter(e.target.value);
                            }}
                            value={searchText}
                            style={{ color: "#000",marginTop:10}} type="type" class="form-control form_gray input-sm" />

                        <ul

                            style={{ listStyle: "none", padding: 10, height: "45vh",overflow:"auto",marginTop:10}}>
                            {searchLcoList.map((item, i) => {

                                return (
                                    <li
                                        onScroll={(e) => console.log("scrolling", e)}
                                        onClick={() => { 
                                            setIsShowLcoListDialog(!isShowLcoListDialog);
                                            setSelectedLco(item);
                                            setCurrentLcoCode(item.lcocode);
                                            
                                         }}
                                        style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                        {item.lconame} <span style={{ fontSize: 12, color: "#d61818", paddingLeft: 1 }}
                                        >({item.lcocode})</span></li>
                                );
                            })}
                        </ul>
                        <Button
                            style={{ backgroundColor: "red", borderColor: "red" }}
                            className="mt-3 pull-right"
                            onClick={() => {
                                setIsShowLcoListDialog(false);
                            }}
                        >
                            cancel
                        </Button>
                        {/* <Button
                            style={{
                                backgroundColor: "green",
                                borderColor: "green",
                                marginRight: 15
                            }}
                            className="mt-3 pull-right"
                            onClick={() => {
                                setIsShowLcoListDialog(false);
                            }}
                        >
                            OK
                        </Button> */}


                    </Card.Body>
                </Card>
            </Drawer>


            


        </div>
    )
}

export default Template;