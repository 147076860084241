import React from "react";
import {
    Button,
    ButtonGroup,
    Container,
    ButtonToolbar,
    Jumbotron,
    Card,
    Col,
    Row
} from "react-bootstrap";
import Checkboxs from 'muicss/lib/react/checkbox';

import {
    Rootcontainer,
    SearchDiv,
    MainContainer,
    RootDiv,
    RootTitle,
    RootBal,
    RootRight,
    Amt,
    MoreDetails,
    RootLeft,
    RootValue,
    SelectedDiv,
    SelectedPackage
} from "./styled";
import loadinggif from '../../assets/loding-gif.gif';
import { Redirect } from "react-router-dom";
import logo from "../../assets/logo-3.png";
import LoadingOverlay from "react-loading-overlay";
import { FaPlus, FaCircle } from "react-icons/fa";
import axios from 'axios';
import {
    MdPowerSettingsNew,
    MdCompareArrows,

} from "react-icons/md";
import Loader from 'react-loader-spinner';
import DialogBoxs from './DialogBoxes';
import API from "../../Api";
import modifyData from '../../ModificationForOthers';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import GeneralValues from '../../GobelElements/Values';
import { CSVLink, CSVDownload } from "react-csv";
// import CsvDownloader from 'react-csv-downloader';
import { downloadListHeader } from './constants';

import CsvLink from 'react-csv-export';
// import { CSVDownloader } from 'react-papaparse';
export default class FullDataProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isloggedOut: false,
            isShowLogOutWarning: false,
            isSpinnerVisible: false,
            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            spinnerVisible: false,
            lcoName: "",
            lcoBalance: "",
            CurrentLcoCode: localStorage.getItem("currentlco"),
            lcoCode: "",
            DataList: [],
            SelectedDataList: [],
            isSelectedAll: false,
            totalValueOfSelectedSTB: 0,
            isShowPayNowWarningDialog: false,
            isShowProcessDialog: false,
            noOfDataGoingToProcess: 0,
            noOfcompletedData: 0,
            unProcessedData: [],
            isShowSelectedSTBListDialog: false,
            isShowUnProcessedDataListDialog: false,
            searchResult: [],
            searchText: "",
            isEmptyList: false,
            isLowBalance: false,
            isShowWarningForDisconnect: false,
            isShowBulkSearch: false,
            isInValidDataList: [],
            isShowInvalidSTBListInBulkSearch: false,
            isShowExtend: false,
            isShowExtendDialog: false,
            extendDays: 0,
            listSearch: ["All", "Resume", "Suspend"],
            isShowSuspendWarning: false,
            isShowResumeWarning: false,
            endDate: new Date(),
            startDate: new Date(),
            isShowPackageDialog: false,
            selectedPackageData: "",
            inStockListSearchText: "",
            inStockDataList: [],
            extendSpinnerLoader:false


        };
        const startAndEndDate = this.props.startAndEndDate;
        if (Object.keys(startAndEndDate).length) {
            this.setState({ endDate: startAndEndDate.end, startDate: startAndEndDate.start, isShowExtend: true });
            this.getLCO_Details();
            this.getExtendListFunc();



            console.log("start", startAndEndDate);
            debugger;

        } else {
            debugger;
            this.getLCO_Details();
        }


    }
    UNSAFE_componentWillMount() {
        window.addEventListener('scroll', this.handleScroll);
        const startAndEndDate = this.props.startAndEndDate;

        if (Object.keys(startAndEndDate).length) {
            // setTimeout(()=>{
                this.setState({ endDate: startAndEndDate.end, startDate: startAndEndDate.start, isShowExtend: true,extendSpinnerLoader:true }, () => {
                    this.getSelectedDateList(startAndEndDate.end);
    
                });
                console.log("start", startAndEndDate);
            debugger;
            // },600)
                
        }
        if(this.props.extendOperation == true){
            this.getExtendListFunc();
          //  this.setState({isShowExtend:true});
            this.props.clearExtendOperation()
        }

    }

    hindLogOutDialog() {
        this.setState({ isShowLogOutWarning: false });
    }
    hindPayNowWarningDialog() {
        this.setState({ isShowPayNowWarningDialog: false })
    }
    hindSelectedSTBListDialog() {
        this.setState({ isShowSelectedSTBListDialog: false })
    }
    hindInvalidSTBListInBulkSearch() {
        this.setState({ isShowInvalidSTBListInBulkSearch: false });
    }
    hindBulkSearch() {
        this.setState({ isShowBulkSearch: false });
    }
    hindLowBalance() {
        this.setState({ isLowBalance: false })
    }
    hindEmptyList() {
        this.setState({ isEmptyList: false });
    }
    hindWarningForDisconnect() {
        this.setState({ isShowWarningForDisconnect: false })
    }
    hindSuspendWarning() {
        this.setState({ isShowSuspendWarning: false })
    }
    hindPackageDetailDialog() {
        this.setState({ isShowPackageDialog: false })
    }

    hindProcessDialog() {
        this.setState({ isShowProcessDialog: false, totalValueOfSelectedSTB: 0, SelectedDataList: [], isSelectedAll: false });
        console.log("Un Processed data", this.state.unProcessedData)
        debugger;
        if (this.state.unProcessedData.length) {
            this.setState({ isShowUnProcessedDataListDialog: true });

        } else {
            if (!this.state.isShowExtend) {
                this.getFullDataListFunc();
                this.getLCO_Details();
            } else {
                this.getExtendListFunc();
                this.getLCO_Details();
            }
        }
    }
    hindUnProcessedDataListDialog() {
        this.setState({ isShowUnProcessedDataListDialog: false, unProcessedData: [] })
        this.getFullDataListFunc();

    }

    hindExtendDialog() {
        this.setState({ isShowExtendDialog: false });

    }

    hindResumeWarning() {
        this.setState({ isShowResumeWarning: false });
    }
    componentWillMount() {
        this.getFullDataListFunc();
    }
    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />;
        } else {
            return null;
        }
    }
    logOut() {
        localStorage.removeItem("userID");
        const username = localStorage.getItem("userID");
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }

    searchFunc(text) {
        debugger;
        if (text) {
            var dummy = [];
            var lowerText = text.toString().toLowerCase();
            for (var n in this.state.DataList) {
                var STBLower = this.state.DataList[n].STB.toString().toLowerCase();
                var m = STBLower.search(lowerText);
                if (m != -1) {
                    dummy.push(this.state.DataList[n]);
                }
            }
            this.setState({ searchResult: dummy });


        } else {
            console.log("data", this.state.DataList);
            this.setState({ searchResult: this.state.DataList });
            debugger;

            // this.setState({searchResult:this.state.DataList});
        }
    }

    recursiveFuncPay(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        console.log("data", data)
        debugger;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName, } = this.state;
            API.payNowAPI(lcoID, lcoPassword, data.Customernum, "pay", domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    var n = this.state.noOfcompletedData;
                    console.log("response", responseJson, n, total);
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {
                        debugger;


                        if ((n + 1) <= total) {
                            debugger;
                            this.recursiveFuncPay(this.state.SelectedDataList[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) < total) {
                            this.recursiveFuncPay(this.state.SelectedDataList[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                    var n = this.state.noOfcompletedData;
                    var data1 = this.state.unProcessedData;
                    data1.push({ result: error });
                    this.setState({ unProcessedData: data1 });
                    if ((n + 1) < total) {
                        this.recursiveFuncPay(this.state.SelectedDataList[n + 1])
                    }
                })
        }
    }

    payFunc() {



        this.setState({

            noOfDataGoingToProcess: this.state.SelectedDataList.length,
            noOfcompletedData: 0,

            isShowProcessDialog: true
        });

        this.state.noOfDataGoingToProcess = this.state.SelectedDataList.length;
        this.state.noOfcompletedData = 0;
        console.log("data loc code");
        debugger;

        this.recursiveFuncPay(this.state.SelectedDataList[0]);





    }



    recursiveFuncDisconnect(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        console.log("data", data)
        debugger;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName, } = this.state;
            API.disconnect(lcoID, lcoPassword, "disconnect", data.Customernum, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    var n = this.state.noOfcompletedData;
                    console.log("response", responseJson, n, total);
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {
                        debugger;


                        if ((n + 1) < total) {
                            debugger;
                            this.recursiveFuncDisconnect(this.state.SelectedDataList[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) < total) {
                            this.recursiveFuncDisconnect(this.state.SelectedDataList[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                    var n = this.state.noOfcompletedData;
                    var data1 = this.state.unProcessedData;
                    data1.push({ result: error });
                    this.setState({ unProcessedData: data1 });
                    if ((n + 1) < total) {
                        this.recursiveFuncDisconnect(this.state.SelectedDataList[n + 1])
                    }
                })
        }
    }

    DisconnetFunc() {



        this.setState({

            noOfDataGoingToProcess: this.state.SelectedDataList.length,
            noOfcompletedData: 0,

            isShowProcessDialog: true
        });

        this.state.noOfDataGoingToProcess = this.state.SelectedDataList.length;
        this.state.noOfcompletedData = 0;
        console.log("data loc code");
        debugger;

        this.recursiveFuncDisconnect(this.state.SelectedDataList[0]);





    }


    recursiveFuncExtend(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        console.log("data", data)
        debugger;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName, extendDays } = this.state;
            console.log("Extend", extendDays);
            debugger;
            API.extendAPI(lcoID, lcoPassword, data.Customernum, extendDays, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    var n = this.state.noOfcompletedData;
                    console.log("response", responseJson, n, total);
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {
                        debugger;


                        if ((n + 1) < total) {
                            debugger;
                            this.recursiveFuncExtend(this.state.SelectedDataList[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) <= total) {
                            this.recursiveFuncExtend(this.state.SelectedDataList[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                    var n = this.state.noOfcompletedData;
                    var data1 = this.state.unProcessedData;
                    data1.push({ result: error });
                    this.setState({ unProcessedData: data1 });
                    if ((n + 1) < total) {
                        this.recursiveFuncExtend(this.state.SelectedDataList[n + 1])
                    }
                })
        }
    }

    ExtendFunc(days) {



        this.setState({

            noOfDataGoingToProcess: this.state.SelectedDataList.length,
            noOfcompletedData: 0,

            isShowProcessDialog: true,
            extendDays: days
        });

        this.state.noOfDataGoingToProcess = this.state.SelectedDataList.length;
        this.state.noOfcompletedData = 0;
        this.state.extendDays = days;
        console.log("data loc code");

        debugger;

        this.recursiveFuncExtend(this.state.SelectedDataList[0]);





    }


    recursiveFuncSuspend(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        console.log("data", data)
        debugger;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName } = this.state;
            // console.log("Extend",extendDays);
            debugger;
            API.suspendAPI(lcoID, lcoPassword, data.Customernum, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    var n = this.state.noOfcompletedData;
                    console.log("response", responseJson, n, total);
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {
                        debugger;


                        if ((n + 1) < total) {
                            debugger;
                            this.recursiveFuncSuspend(this.state.SelectedDataList[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) < total) {
                            this.recursiveFuncSuspend(this.state.SelectedDataList[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                    var n = this.state.noOfcompletedData;
                    var data1 = this.state.unProcessedData;
                    data1.push({ result: error });
                    this.setState({ unProcessedData: data1 });
                    if ((n + 1) < total) {
                        this.recursiveFuncSuspend(this.state.SelectedDataList[n + 1])
                    }
                })
        }
    }

    SuspendFunc() {



        this.setState({

            noOfDataGoingToProcess: this.state.SelectedDataList.length,
            noOfcompletedData: 0,

            isShowProcessDialog: true,
            // extendDays : days
        });

        this.state.noOfDataGoingToProcess = this.state.SelectedDataList.length;
        this.state.noOfcompletedData = 0;
        // this.state.extendDays =  days;
        console.log("data loc code");

        debugger;

        this.recursiveFuncSuspend(this.state.SelectedDataList[0]);





    }


    recursiveFuncResume(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        console.log("data", data)
        debugger;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName } = this.state;
            // console.log("Extend",extendDays);
            debugger;
            API.resumeAPI(lcoID, lcoPassword, data.Customernum, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    var n = this.state.noOfcompletedData;
                    console.log("response", responseJson, n, total);
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {
                        debugger;


                        if ((n + 1) < total) {
                            debugger;
                            this.recursiveFuncResume(this.state.SelectedDataList[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) < total) {
                            this.recursiveFuncResume(this.state.SelectedDataList[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                    var n = this.state.noOfcompletedData;
                    var data1 = this.state.unProcessedData;
                    data1.push({ result: error });
                    this.setState({ unProcessedData: data1 });
                    if ((n + 1) < total) {
                        this.recursiveFuncResume(this.state.SelectedDataList[n + 1])
                    }
                })
        }
    }

    ResumeFunc() {



        this.setState({

            noOfDataGoingToProcess: this.state.SelectedDataList.length,
            noOfcompletedData: 0,

            isShowProcessDialog: true,
            // extendDays : days
        });

        this.state.noOfDataGoingToProcess = this.state.SelectedDataList.length;
        this.state.noOfcompletedData = 0;
        // this.state.extendDays =  days;
        console.log("data loc code");

        debugger;

        this.recursiveFuncResume(this.state.SelectedDataList[0]);





    }

    toggleChangeInCustomerAdd(index, data) {

        let oldDataList = this.state.searchResult;
        let oldToggleMode = oldDataList[index].toggleMode;
        oldDataList[index].toggleMode = !oldToggleMode;
        this.setState({ searchResult: oldDataList });

        if (oldDataList[index].toggleMode) {
            var oldSelectedData = this.state.SelectedDataList;
            oldSelectedData.push(oldDataList[index]);
            var total = 0;
            for (var i in oldSelectedData) {
                var price3 = 0;
                if (this.state.isShowExtend) {
                    price3 = oldSelectedData[i].base;
                } else {
                    price3 = oldSelectedData[i].Due;
                }
                total = (parseFloat(price3) + parseFloat(total)).toFixed(2);
            }
            
            total = (parseFloat(total)).toFixed(2);
            debugger;
            this.setState({ SelectedDataList: oldSelectedData, totalValueOfSelectedSTB: total });
            var oldWholeDataList = this.state.DataList;
            for (var x in oldWholeDataList) {
                if (oldWholeDataList[x].STB == data.STB) {
                    oldWholeDataList[x].toggleMode = true
                }
            }
            this.setState({ DataList: oldWholeDataList });
        }
        else {

            var arr = [];
            for (var k in this.state.SelectedDataList) {
                if (data.STB != this.state.SelectedDataList[k].STB) {
                    arr.push(this.state.SelectedDataList[k]);
                }
            }

            var totalval = 0;
            for (var m in arr) {
                var price4 = 0;
                if (this.state.isShowExtend) {
                    price4 = arr[m].base;
                } else {
                    price4 = arr[m].Due;
                }
                totalval = (parseFloat(price4) + parseFloat(totalval)).toFixed(2);
            }
            this.state.SelectedDataList = arr;
            
            totalval = (parseFloat(totalval)).toFixed(2);
            
            debugger;
            this.setState({ isSelectedAll: false, SelectedDataList: arr, totalValueOfSelectedSTB: totalval });

            console.log("array", arr, this.state.SelectedDataList);
            var oldWholeDataList1 = this.state.DataList;
            for (var x in oldWholeDataList1) {
                if (oldWholeDataList1[x].STB == data.STB) {
                    oldWholeDataList1[x].toggleMode = false
                }
            }
            this.setState({ DataList: oldWholeDataList1 });
            debugger;
        }

        // this.setState({selectedItemsCount : this.state.selectedSTB.length});

    }
    toggleChangeInSelectAll() {
        if (!this.state.isShowExtend) {
            var oldData = this.state.isSelectedAll;
            this.setState({ isSelectedAll: !oldData });
            if (!oldData) {
                var olDataList = this.state.DataList;
                var totalall = 0;
                for (var i in olDataList) {
                    olDataList[i].toggleMode = true;
                    totalall = (parseFloat(olDataList[i].Due) + parseFloat(totalall)).toFixed(2)
                }
              
                totalall = (parseFloat(totalall)).toFixed(2);
                this.setState({ DataList: olDataList, SelectedDataList: olDataList, totalValueOfSelectedSTB: totalall, searchResult: olDataList });
            } else {
                var DataList1 = this.state.DataList;
                for (var l in DataList1) {
                    DataList1[l].toggleMode = false;
                }
                this.setState({ DataList: DataList1, SelectedDataList: [], totalValueOfSelectedSTB: 0 });

            }
        } else {
            var oldData1 = this.state.isSelectedAll;
            this.setState({ isSelectedAll: !oldData1 });
            if (!oldData1) {
                // var olDataList1 = this.state.DataList;
                var olDataList1 = this.state.searchResult;
                var totalall1 = 0;
                for (var i1 in olDataList1) {
                    olDataList1[i1].toggleMode = true;
                    totalall1 = (parseFloat(olDataList1[i1].base) + parseFloat(totalall1)).toFixed(2)
                }
                debugger;
                
                totalall1 = (parseFloat(totalall1)).toFixed(2);
                this.setState({ DataList: olDataList1, SelectedDataList: olDataList1, totalValueOfSelectedSTB: totalall1, searchResult: olDataList1 });
            } else {
                // var DataList11 = this.state.DataList;
                var DataList11 = this.state.searchResult;
                for (var l1 in DataList11) {
                    DataList11[l1].toggleMode = false;
                }
                this.setState({ DataList: DataList11, SelectedDataList: [], totalValueOfSelectedSTB: 0 });

            }
        }


    }
    toggleChangeInExtend() {
        var data = this.state.isShowExtend;
        this.setState({ isShowExtend: !data, isSelectedAll: false });
        if (!data) {
              this.getExtendListFunc();

        } else {
            if (Object.keys(this.props.startAndEndDate).length) {
                this.props.resetTheUnpaid();
            }

            this.getFullDataListFunc();
        }
    }
    getLCO_Details() {
        // const { lcoID, lcoPassword, domainName } = this.state;
        const { lcoID, lcoPassword, domainName } = this.state;

        API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("rep", responsejson);

                this.setState({ lcoName: responsejson.lconame, lcoBalance: responsejson.balance, lcoCode: responsejson.lcocode });



            });
    }

    getCSVFile() {
        const { lcoCode, lcoID, lcoPassword, domainName } = this.state;

        this.setState({ isEmptyError: "", spinnerVisible: true });
        debugger;

        axios({

            url: `http://vm.vayumedia.tv/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=unpaid&lcocode=${lcoCode}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            console.log("data", response);
            debugger;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `unpaidlist${lcoCode}.xlsx`);
            link.click();
            window.URL.revokeObjectURL(url);
            this.setState({ spinnerVisible: false });
        }).catch((error) => {
            console.log("error", error);
            this.setState({ spinnerVisible: false, isEmptyError: "Unable to process !" });

        });

    }

    getCSVExtendFile() {
        const { lcoCode, lcoID, lcoPassword, domainName } = this.state;

        this.setState({ isEmptyError: "", spinnerVisible: true });
        debugger;

        axios({

            url: `${GeneralValues.url}/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=expiry&lcocode=${lcoCode}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            console.log("data", response);
            debugger;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `expiry${lcoCode}.xlsx`);
            link.click();
            window.URL.revokeObjectURL(url);
            this.setState({ spinnerVisible: false });
        }).catch((error) => {
            console.log("error", error);
            this.setState({ spinnerVisible: false, isEmptyError: "Unable to process !" });

        });

    }
    getCSVExtendFileForSpecfiedDate() {
        const { lcoCode, lcoID, lcoPassword, domainName } = this.state;

        this.setState({ isEmptyError: "", spinnerVisible: true });
        let url = `${GeneralValues.url}/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=expiry&lcocode=${lcoCode}&start=${this.state.startDate.getFullYear()}-${this.state.startDate.getMonth() + 1}-${this.state.startDate.getDate()}&end=${this.state.endDate.getFullYear()}-${this.state.endDate.getMonth() + 1}-${this.state.endDate.getDate()}`;
        console.log("URL", url);
        debugger;

        axios({

            url: url,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            console.log("data", response);
            debugger;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `expiry${lcoCode} .xlsx`);
            link.click();
            window.URL.revokeObjectURL(url);
            this.setState({ spinnerVisible: false });
        }).catch((error) => {
            console.log("error", error);
            this.setState({ spinnerVisible: false, isEmptyError: "Unable to process !" });

        });

    }

    getFullDataListFunc() {
        this.setState({ spinnerVisible: true });
        const { lcoID, lcoPassword, domainName } = this.state;
        API.getFullDataList(lcoID, lcoPassword, "unpaid", domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson);
                debugger;

                // var ArrayOfData = Object.keys(responseJson).reduce((prevVal,key)=>{
                //     var p = { ...responseJson.content[key],toggleMode:false };
                //     prevVal.push(p);

                //     return prevVal;
                // },[]);
                var ArrayOfData = []
                for (var i in responseJson) {
                    responseJson[i].toggleMode = false;
                    ArrayOfData.push(responseJson[i]);
                }
                if(!this.state.isShowExtend){
                    this.setState({ spinnerVisible: false, DataList: ArrayOfData, searchResult: ArrayOfData });
                }else{
                    this.setState({ spinnerVisible: false});
                }
            
                console.log("arrray", ArrayOfData);

            }).catch(e=>{
                console.log("error",e);
                debugger;
            })
    }

    getExtendListFunc() {
        this.setState({ spinnerVisible: true,extendSpinnerLoader:true });
        const { lcoID, lcoPassword, domainName } = this.state;
        API.getExtendList(lcoID, lcoPassword, domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson);
                debugger;

                // var ArrayOfData = Object.keys(responseJson).reduce((prevVal,key)=>{
                //     var p = { ...responseJson.content[key],toggleMode:false };
                //     prevVal.push(p);

                //     return prevVal;
                // },[]);
                var ArrayOfData = []
                for (var i in responseJson) {
                    responseJson[i].toggleMode = false;
                    ArrayOfData.push(responseJson[i]);
                }
                this.setState({ spinnerVisible: false, DataList: ArrayOfData, searchResult: ArrayOfData,extendSpinnerLoader:false });
                console.log("arrray", ArrayOfData);
                if (Object.keys(this.props.startAndEndDate).length) {
                    // setTimeout(()=>{
                        this.getSelectedDateList(this.props.startAndEndDate.end);
                    // },500);
                    
                }

            }).catch((error) => {
                console.log("Error", error)
            })
    }

    bulkSearchFunc(text) {

        var arrayOfLines = text.split('\n')
        console.log("text", text, arrayOfLines);
        debugger;

        if (arrayOfLines.length) {
            var data = this.state.DataList;
            var inValideList = [];
            for (var i in arrayOfLines) {
                var found = false;
                for (var j in data) {
                    if (((arrayOfLines[i]).toLowerCase()) == ((data[j].STB).toLowerCase())) {
                        data[j].toggleMode = true
                        found = true;
                        this.state.SelectedDataList.push(data[j]);
                        this.state.totalValueOfSelectedSTB += data[j].Due;
                        break;
                    }
                }
                if (!found) {
                    inValideList.push(arrayOfLines[i]);
                }
            }
            console.log("data", data, inValideList);
            debugger;
            this.setState({ DataList: data });

            debugger;
            var tot1 = 0;
            if (this.state.isShowExtend) {
                for (var k of this.state.SelectedDataList) {
                    tot1 = parseFloat(tot1) + k.base
                }
                debugger;
            } else {
                for (var k of this.state.SelectedDataList) {
                    tot1 = parseFloat(tot1) + k.Due
                }


            }
            //tot1=parseFloat(this.state.totalValueOfSelectedSTB)+tot1
            this.setState({ totalValueOfSelectedSTB: tot1 });
            debugger;

            // totalValueOfSelectedSTB
            if (inValideList.length) {
                this.setState({ isInValidDataList: inValideList, isShowInvalidSTBListInBulkSearch: true });
            }
        }


    }

    getSelectedEndDate(e) {
        var { DataList } = this.state;
        console.log("Full List", DataList, e);

        debugger;
        var newList = [];
        for (var data of DataList) {


            let end = new Date(data.End);
            console.log("end", end, end <= e);
            // debugger; 
            if (end <= e) {
                newList.push(data);
                // debugger;

            }
            debugger;
        }
        this.setState({ searchResult: newList });



    }
    getSelectedDateList(e) {

        var { startDate, endDate, DataList } = this.state;
        console.log("Full List", DataList, e, startDate, endDate);

        debugger;
        var newList = [];
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 0, 0)
        for (var data of DataList) {


            let end = new Date(data.End);
            console.log("customer end", end);
            console.log("Start", startDate);
            console.log("end", endDate);
            console.log("customer end", end >= startDate, end <= endDate);
            // debugger;
            // if((e >= end) && (startDate <=end)  ){
            //     newList.push(data); 
            //     // debugger;

            // }

            if (end >= startDate && end <= endDate) {
                newList.push(data);
                // debugger; startDate >=end 

            }
            
        }
        debugger;
        this.setState({ searchResult: newList });



    }


    searchChange(text) {

        var allData = this.state.DataList;
        var newList = [];
        if (text == "Suspend") {

            for (var item of allData) {
                if (item.suspend) {
                    newList.push(item);
                }
            }
            console.log("suspend", newList);
            debugger;
            this.setState({ searchResult: newList })
        } else if (text == "Resume") {
            for (var item1 of allData) {
                if (!item1.suspend) {
                    newList.push(item1);
                }
            }
            console.log("resume", newList);
            debugger;
            this.setState({ searchResult: newList })

        } else {
            this.setState({ searchResult: allData });
        }
    }

    whichBtnPayOrExtend() {
        if (this.state.isShowExtend) {
            return (
                <Button
                    onClick={() => {
                        if (!this.state.SelectedDataList.length) {
                            this.setState({ isEmptyList: true });
                        } else {
                            // var dataBal = parseFloat(this.state.lcoBalance).toFixed(2);


                            // var data2 = parseFloat(this.state.totalValueOfSelectedSTB);
                            // console.log("data", dataBal, data2);

                            // if (dataBal > data2) {
                            this.setState({ isShowExtendDialog: true })
                            //         debugger;
                            //     } else {
                            //         this.setState({ isLowBalance: true });
                            //         debugger;
                            //     }


                        }

                    }}
                    style={{ backgroundColor: "green", borderColor: "green", display: "inline-block" }}>Extend</Button>
            );
        } else {
            return (
                <Button
                    onClick={() => {
                        if (!this.state.SelectedDataList.length) {
                            this.setState({ isEmptyList: true });
                        } else {
                            var dataBal = parseFloat(this.state.lcoBalance).toFixed(2);


                            var data2 = parseFloat(this.state.totalValueOfSelectedSTB);
                            console.log("data", dataBal, data2);

                            if (dataBal > data2) {
                                this.setState({ isShowPayNowWarningDialog: true })
                                debugger;
                            } else {
                                this.setState({ isLowBalance: true });
                                debugger;
                            }


                        }

                    }}
                    style={{ backgroundColor: "green", borderColor: "green", display: "inline-block" }}>Pay now</Button>
            );
        }
    }
    whichDownload() {
        if (this.state.isShowExtend) {
            return (
                <label
                    onClick={() => { this.getCSVExtendFile() }}
                    style={{ cursor: "pointer", textDecoration: "underline", color: "blue", marginLeft: 5, fontSize: 11 }}>Download Total expiry list</label>
            );
        } else {
            return (
                <label
                    onClick={() => { this.getCSVFile() }}
                    style={{ cursor: "pointer", textDecoration: "underline", color: "blue", marginLeft: 15 }}>Download unpaid list</label>
            );
        }
    }
    isShowDateHeader() {
        if (this.state.isShowExtend) {
            return (
                <th>Durations</th>
            );
        } else {
            return null;
        }
    }
    isShowDateValue(item) {
        if (this.state.isShowExtend) {
            return (
                <td style={{ color: "red" }}>{item.Start} <label style={{ color: "black" }}>to</label> {item.End}</td>
            );
        } else {
            return null;
        }

    }
    isShowSuspendAndResume() {
        if (this.state.isShowExtend) {
            return null;
            // return (
            //     <div style={{ display: "inline-block" }}>
            //         <Button
            //             onClick={() => {

            //                 if (this.state.SelectedDataList.length) {
            //                     this.setState({ isShowSuspendWarning: true })
            //                 } else {
            //                     this.setState({ isEmptyList: true });
            //                 }
            //             }}
            //             style={{ backgroundColor: "#582A72", borderColor: "#582A72", display: "inline-block", marginLeft: 15 }}>Suspend</Button>

            //         <Button
            //             onClick={() => {

            //                 if (this.state.SelectedDataList.length) {
            //                     this.setState({ isShowResumeWarning: true })
            //                 } else {
            //                     this.setState({ isEmptyList: true });
            //                 }
            //             }}
            //             style={{ backgroundColor: "#2E4172", borderColor: "#2E4172", display: "inline-block", marginLeft: 15 }}>Resume</Button>

            //     </div>
            // );
        }
    }
    isSuspend(val) {
        if (val) {
            return (
                <label style={{ color: "red", fontWeight: "bold", fontSize: 13, marginLeft: 7, display: "block" }}>Suspend</label>
            );
        } else {
            return null;
        }
    }

    isShowSearchForExtend() {
        if (this.state.isShowExtend) {
            return (
                <select title="ALL" style={{ height: 30, width: 150, marginTop: 7 }}
                    onChange={(e) => {
                        this.searchChange(e.target.value);
                    }}

                >
                    {this.state.listSearch.map((item, i) => {
                        return (
                            <option value={item}>{item}</option>
                        );
                    })}


                </select>
            );
        } else {
            return null;
        }
    }
    isShowDatePickerForExtendDetails() {
        if (this.state.isShowExtend) {
            return (
                <div style={{}}>
                    <label style={{ marginRight: 15 }}> Select date :</label>


                    <DatePicker
                        onChange={(e) => { 
                            
                            this.setState({ startDate: e,isSelectedAll:false}) }}
                        selected={this.state.startDate}



                    />
                    <MdCompareArrows size={35} style={{ marginLeft: 10, marginRight: 10, fontSize: 20 }} />
                    <DatePicker
                        onChange={(e) => {
                            this.setState({ endDate: e }, () => {
                                console.log("e", e);
                                this.setState({isSelectedAll:false});
                               
                                this.getSelectedDateList(e);
                            })

                        }}
                        selected={this.state.endDate}



                    />
                    <label style={{ marginLeft: 15 }}></label>
                    {/* <button
                       onClick={() => {
                        console.log("new check");
                        debugger;
                        let date = new Date();
                        this.setState({
                            startDate: date,
                            endDate: date,
                            searchResult: this.state.DataList

                        })
                    }}
                    style={{marginRight: 15,backgroundColor:"transparent",color:"#000"}}
                    >Clear</button> */}
                    <label

                        onClick={() => {
                            console.log("new check");
                            debugger;
                            let date = new Date();
                            this.setState({
                                startDate: date,
                                endDate: date,
                                searchResult: this.state.DataList

                            })
                        }}
                        style={{ cursor: "pointer", marginRight: 15, color: "gray", textDecoration: "underline" }}>Clear</label>
                </div>
            );
        } else {
            return null;
        }
    }
    stockListSearch(text) {

        var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
        if (text.length > 2) {
            this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

            API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    if (responseJson.count) {


                        this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                        console.log("In stock list", this.state.inStockDataList);
                    } else {
                        this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                    }
                });
        } else {
            this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
        }
    }
    getSTBDetails(item) {
        console.log("item", item);
        if (item.Customer) {
            this.setState({ spinnerVisible: true });
            const { lcoID, lcoPassword, domainName, searchText } = this.state;
            API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("response", responsejson);
                    if (responsejson.message == "no data") {
                        this.setState({ spinnerVisible: false });
                    } else {
                        localStorage.setItem("currentlco", item.Lcocode);
                        this.setState({ spinnerVisible: false });
                        this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
                    }
                });
        }
        else {
            this.setState({ isShowNodataDialog: true })
        }

    }
    dowloadCSvm(data) {
        console.log("data", data);
        let csvRow = [];
        let A = [['STB', 'Model', 'Customer', 'Customer Number', 'MACVCChipNumber', "Base", 'DRM', 'Start', 'End', 'status', 'Phone number', 'Lcocode', 'package', 'Address']];
        for (var m of data) {
            A.push([`'${m.STB}`, m.Model, m.Customer, `'${m.Customernum}`, `'${m.MACVCChipNumber}`, m.base, m.cas, m.Start, m.End, m.status, m.Phone ? `'${m.Phone}` : '--', m.Lcocode, m.Product.join('; '), m.Address.split(',').join("_")])
        }
        console.log("A", A);
        for (var i = 0; i < A.length; i++) {
            csvRow.push(A[i].join(","))
        }
        console.log("csvRow", csvRow);
        var csvString = csvRow.join(",\n");
        console.log("csvString", csvString);
        var a = document.createElement('a');
        a.href = 'data:attachment/csv,' + csvString;
        a.target = "_Blank"
        a.download = `expiryList_${this.state.lcoID}.csv`;
        document.body.appendChild(a);
        a.click();
    }
    render() {
        return (
            <Rootcontainer
            // onScroll={e => this.handleScroll(e)}
            // ref={this.nav}
            //onScrollCapture={(e)=>{  this.nextPageData(this.state.customerList, (this.state.pageCount + 1))}}
            >


                
                {/* <Loader
                            visible={this.state.isShowStockLoader}
                            style={{ textAlign: "center", marginBottom: 10, }}
                            type="ThreeDots"
                            color="#00BFFF"
                            height="10"
                            width="20"
                        /> */}

                <MainContainer>
                    <RootDiv>
                        <RootLeft>
                            <RootTitle>
                                Name :
                    </RootTitle>

                            <RootValue
                                style={{ cursor: "pointer" }}
                                onClick={() => { this.props.onClickProfile() }} >
                                {this.state.lcoName}

                            </RootValue>
                            <div className="lcocode" >

                                (<span style={{ color: "black" }}>Lco code:</span> {this.state.CurrentLcoCode})
                </div>
                        </RootLeft>

                        <RootRight>
                            <RootTitle>
                                Balance :
                            </RootTitle>

                            <RootValue>
                                {'\u20B9'} {this.state.lcoBalance}
                            </RootValue>

                        </RootRight>
                    </RootDiv>

                    <LoadingOverlay

                        className={this.state.spinnerVisible || this.state.extendSpinnerLoader ? "displaySpinner" : ""}
                        overlay={true}
                        active={this.state.spinnerVisible || this.state.extendSpinnerLoader}
                        spinner={<img alt="logo" src={loadinggif} className="spinnerContent" color="green" />}
                        styles={{ content: { width:"80%",minHeight:"65%" } }}
                    ></LoadingOverlay>

                    <div id="fulldataprocess">
                        {this.isShowDatePickerForExtendDetails()}
                        <div style={{ textAlign: "end" }}>


                            <Checkboxs
                                style={{ fontSize: 20, color: "green", display: "inline-block" }}
                                color="green"
                                title="Select All"
                                onChange={() => { this.toggleChangeInExtend() }}
                                checked={this.state.isShowExtend} />
                            <label style={{ marginLeft: 10 }}>Extend Operation</label>
                        </div>

                        <div className="stb-search" style={{ marginRight: 15, marginTop: 15 }}>
                            <input
                                placeholder="STB Search...."
                                value={this.state.searchText}
                                onChange={(e) => {
                                    this.setState({ searchText: e.target.value })
                                    this.searchFunc(e.target.value);
                                }}
                                onKeyPress={(e) => {

                                    if (e.keyCode == 13 || e.which == 13) {
                                        this.searchFunc(this.state.searchText);
                                    }
                                }} />

                            {/* <Button className="btn-primary"
                                onClick={() => {


                                    this.searchFunc()

                                }}
                                style={{ marginLeft: 15 }}>Search</Button> */}
                            <label style={{ fontSize: 20, fontWeight: "bold", marginLeft: "5%" }}>Total Amount : <span style={{ color: "red" }}>{parseFloat(this.state.totalValueOfSelectedSTB).toFixed(2)}</span></label>
                            

                        </div>
                        <div  style={{
                                // display: "inline-block",
                                marginTop:10,
                                // display:"inline",
                               
                                textAlign: "end",
                               
                                

                                //  marginLeft:"25%"
                            }}>

                                {this.whichBtnPayOrExtend()}
                                <Button
                                    onClick={() => {

                                        if (this.state.SelectedDataList.length) {
                                            this.setState({ isShowWarningForDisconnect: true })
                                        } else {
                                            this.setState({ isEmptyList: true });
                                        }
                                    }}
                                    style={{ backgroundColor: "red", borderColor: "red", display: "inline-block", marginLeft: 15 }}>Disconnect</Button>
                                {this.isShowSuspendAndResume()}

                                {this.whichDownload()}

                            </div>

                        <div style={{
                            // display: "flex", 
                            margin: "15px 0px"
                        }}>
                            <Button style={{ fontSize: 14 }} onClick={() => { this.setState({ isShowSelectedSTBListDialog: true }) }}>Show Selected STBs</Button>
                            <div style={{ marginLeft: "63%", marginTop: "-20px" }}>
                                <label style={{ marginRight: 15 }}>STB count: <span style={{ fontWeight: "bold", color: "red" }}>{this.state.searchResult.length}</span></label>
                                <label>Selected Item count: <span style={{ fontWeight: "bold", color: "red" }}>{this.state.SelectedDataList.length}</span></label>
                            </div>
                        </div>
                        <div style={{ marginLeft: 15, display: "flex" }}>
                            <Checkboxs
                                style={{ fontSize: 20, color: "green" }}
                                color="green"
                                title="Select All"
                                onChange={() => { this.toggleChangeInSelectAll() }}
                                checked={this.state.isSelectedAll} />
                            <label style={{ marginTop: 5, marginLeft: 10, width: 100 }}>Select All</label>

                            {this.isShowSearchForExtend()}

                            {/* {this.state.isShowExtend
                                ?
                                <label
                                    onClick={() => { this.getCSVExtendFileForSpecfiedDate() }}
                                    style={{ cursor: "pointer", textDecoration: "underline", color: "blue", marginLeft: 15, width: 150, marginTop: 10 }}>Download below list</label>
                                : null
                            } */}
                            {/* {this.state.isShowExtend
                                ?
                                <label
                                    onClick={() => { this.dowloadCSvm(this.state.searchResult) }}
                                    style={{ cursor: "pointer", textDecoration: "underline", color: "blue", marginLeft: 15, width: 150, marginTop: 10 }}>Download below list</label>
                                : null
                            } */}

                            {/* {this.state.isShowExtend
                                ?
                                <CSVLink
                                    wra
                                    // contentEditable
                                    headers={[
                                        { label: "-------First Name----------", key: "STB" },

                                    ]}

                                    //     target="_blank"
                                    filename={`Search_Result_data(${this.state.lcoID}).xls`}
                                    data={this.state.searchResult} style={{ display: "inline-block", marginLeft: 15 }}>
                                    Download below list
                                </CSVLink>
                                : null
                            } */}
                            <Button
                                onClick={() => { this.setState({ isShowBulkSearch: true }) }}
                                style={{ backgroundColor: "#AA7B39", borderColor: "#AA7B39", marginLeft: this.state.isShowExtend ? "57%" : "80%" }}
                            >Bulk Search</Button>
                            {/* {this.state.isShowExtend
                                ?
                                <CSVDownloader
                                    data={this.state.searchResult}
                                    filename={`expiryList_${this.state.lcoID}`}
                                    // bom={true}
                                    quoteChar='"'
                                    worker={true}
                                    transform={(e) => {
                                        console.log("valye", e);
                                        return 'mani';
                                    }}

                                >
                                    Download
                                </CSVDownloader>
                                : null
                            } */}
                            {this.state.isShowExtend
                                ?
                                <label
                                    onClick={() => { this.dowloadCSvm(this.state.searchResult) }}
                                    style={{ cursor: "pointer", textDecoration: "underline", color: "blue", marginLeft: 15, width: 150, marginTop: 10 }}>Download below list</label>
                                : null
                            }
                            {/* {this.state.isShowExtend
                                ?
                                <CsvLink
                                    data={this.state.searchResult}
                                    fileName={`expiryList_${this.state.lcoID}`}
                                    withTimeStamp
                                    onClick={() => {
                                        console.log("mani");
                                        debugger;
                                    }}
                                >
                                    <button>Export CSV</button>
                                </CsvLink>
                                : null
                            } */}
                            {/* {this.state.isShowExtend
                                ?
                                <CsvDownloader
                                    filename={`expiryList_${this.state.lcoID}`}
                                    extension=".xlsx"

                                    wrapColumnChar="'"
                                    //style={{ cursor: "pointer", textDecoration: "underline", color: "blue", marginLeft: 15, width: 150, marginTop: 10 }}
                                    columns={downloadListHeader}
                                    datas={this.state.searchResult}
                                    
                                >
                                    <label

                                        style={{ cursor: "pointer", textDecoration: "underline", color: "blue", marginLeft: 15, width: 150, marginTop: 10 }}>Download below list</label>
                                </CsvDownloader>
                                : null
                            } */}

                        </div>


                    </div>

                    <table className="table table-striped">

                        <div style={{ textAlign: "center", color: "red" }}>{this.state.CSVError}</div>

                        <thead>
                            <th style={{ width: "3%" }}></th>
                            <th> STB </th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Due</th>
                            <th>Status</th>
                            {this.isShowDateHeader()}
                            <th>Products</th>
                            <th>Phone</th>





                        </thead>
                        <tbody className="total-records">
                            {this.state.searchResult.map((data, i) => {
                                return (
                                    <tr>
                                        <td style={{ width: "3%" }}>


                                            <Checkboxs
                                                style={{ fontSize: 20, color: GeneralValues.mustard, }}
                                                // color="green"


                                                onChange={() => { this.toggleChangeInCustomerAdd(i, data) }}
                                                checked={data.toggleMode} />
                                        </td>

                                        <td>{data.STB} {this.isSuspend(data.suspend)}</td>
                                        <td>{data.Customer}</td>
                                        <td>{data.Address}</td>
                                        <td>{this.state.isShowExtend ? data.base : data.Due}</td>
                                        <td>{data.status}</td>
                                        {this.isShowDateValue(data)}
                                        <td style={{ maxLines: 2, height: 40, overflow: "hidden", textOverflow: "ellipsis" }}>
                                            <div style={{ height: 40, overflow: "hidden" }}> {data.Product}</div>
                                            {data.Product ? <label onClick={() => {
                                                this.setState({ selectedPackageData: data.Product, isShowPackageDialog: true })
                                            }} style={{ color: GeneralValues.mustard, textDecoration: "underline" }}>More</label> : null}
                                        </td>
                                        <td>{data.Phone}</td>





                                    </tr>
                                )
                            })}
                        </tbody>



                    </table>



                </MainContainer>
                <DialogBoxs
                    isShowLogOutWarning={this.state.isShowLogOutWarning}
                    logOut={this.logOut.bind(this)}
                    hindLogOutDialog={this.hindLogOutDialog.bind(this)}
                    hindPayNowWarningDialog={this.hindPayNowWarningDialog.bind(this)}
                    isShowPayNowWarningDialog={this.state.isShowPayNowWarningDialog}
                    isShowProcessDialog={this.state.isShowProcessDialog}
                    noOfDataGoingToProcess={this.state.noOfDataGoingToProcess}
                    noOfcompletedData={this.state.noOfcompletedData}
                    unProcessedData={this.state.unProcessedData}
                    isShowSelectedSTBListDialog={this.state.isShowSelectedSTBListDialog}
                    hindSelectedSTBListDialog={this.hindSelectedSTBListDialog.bind(this)}
                    SelectedDataList={this.state.SelectedDataList}
                    payFunc={this.payFunc.bind(this)}
                    hindProcessDialog={this.hindProcessDialog.bind(this)}
                    isShowUnProcessedDataListDialog={this.state.isShowUnProcessedDataListDialog}
                    hindUnProcessedDataListDialog={this.hindUnProcessedDataListDialog.bind(this)}
                    isEmptyList={this.state.isEmptyList}
                    hindEmptyList={this.hindEmptyList.bind(this)}
                    isLowBalance={this.state.isLowBalance}
                    hindLowBalance={this.hindLowBalance.bind(this)}
                    DisconnetFunc={this.DisconnetFunc.bind(this)}
                    hindWarningForDisconnect={this.hindWarningForDisconnect.bind(this)}
                    isShowWarningForDisconnect={this.state.isShowWarningForDisconnect}
                    isShowBulkSearch={this.state.isShowBulkSearch}
                    hindBulkSearch={this.hindBulkSearch.bind(this)}
                    bulkSearchFunc={this.bulkSearchFunc.bind(this)}
                    isShowInvalidSTBListInBulkSearch={this.state.isShowInvalidSTBListInBulkSearch}
                    hindInvalidSTBListInBulkSearch={this.hindInvalidSTBListInBulkSearch.bind(this)}
                    isInValidDataList={this.state.isInValidDataList}
                    isShowExtendDialog={this.state.isShowExtendDialog}
                    hindExtendDialog={this.hindExtendDialog.bind(this)}
                    lcoBalance={this.state.lcoBalance}
                    totalValueOfSelectedSTB={this.state.totalValueOfSelectedSTB}
                    ExtendFunc={this.ExtendFunc.bind(this)}
                    isShowSuspendWarning={this.state.isShowSuspendWarning}
                    hindSuspendWarning={this.hindSuspendWarning.bind(this)}
                    SuspendFunc={this.SuspendFunc.bind(this)}
                    isShowResumeWarning={this.state.isShowResumeWarning}
                    hindResumeWarning={this.hindResumeWarning.bind(this)}
                    ResumeFunc={this.ResumeFunc.bind(this)}
                    isShowPackageDialog={this.state.isShowPackageDialog}
                    selectedPackageData={this.state.selectedPackageData}
                    hindPackageDetailDialog={this.hindPackageDetailDialog.bind(this)}



                />

                {this.logOutFunc()}
            </Rootcontainer>
        );
    }
}