import React, { useEffect, useState } from "react";

import './index.css';
import './bootstrap.min.css';
import './style.css';

import { Outlet, Link } from "react-router-dom";
import {FaSearch} from 'react-icons/fa';
import {MdEmail} from 'react-icons/md';
import {MdSearch} from 'react-icons/md';
import {MdArrowCircleUp} from 'react-icons/md';
import {MdNotifications} from 'react-icons/md';
import {MdOutlineDashboard} from 'react-icons/md';
import {MdOutlineClose} from 'react-icons/md';
import {MdSettings} from 'react-icons/md';
import {MdShoppingBag} from 'react-icons/md';
import {MdTransferWithinAStation} from 'react-icons/md';
import {MdContactPage} from 'react-icons/md';
import {MdApps} from 'react-icons/md';

const Template = () => {
    const [isShowOthers, setIsShowOthers] = useState(false);
    const [isShowStockOperations, setIsShowStockOperations] = useState(false);
    const [isShowReports, setIsReports] = useState(false);
    return (
        <div>

            
            <div className="breadcomb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="breadcomb-list space_align">
                            <div class="col-lg-12 col_lg_ctrl">
                                    <div class="form-example-int form-example-st">
                                        <div className="row">
                                        
                                        <div class="col-lg-3">
                                        <div class="nk-int-st">
                                                <label>From Date</label>
                                            </div>
                                            <div class="nk-int-st">
                                                <input  style={{color:"#000"}} type="date" class="form-control form_gray input-sm"  />
                                            </div>
                                            </div>
                                            <div class="col-lg-3">
                                            <div class="nk-int-st">
                                                <label>To Date</label>
                                            </div>
                                            <div class="nk-int-st">
                                                <input style={{color:"#000"}} type="date" class="form-control form_gray input-sm"  />
                                            </div>
                                            </div>
                                            <div class="col-lg-3">
                                            <div class="nk-int-st">
                                                <label>Lineman</label>
                                            </div>
                                            <div class="nk-int-st">
                                               <select className="form-control form_black" style={{color:"#000"}}>
                                                <option>
                                                    All Line Men
                                                </option>
                                                <option>
                                                    3 Star Cable Network (ID - 50393)
                                                </option>
                                               </select>
                                            </div>
                                            </div>
                                            <div class="col-lg-6 col_lg_ctrl">
                                    <div class="form-example-int MT20 MTLeft">
                                        <button class="btn btn-success notika-btn-success">Show Report</button>
                                        <button class="btn btn-success notika-btn-success">Show Report Line Man Wise</button>
                                    </div>
                                </div>
                                        </div>
                                        </div>
                                       
                                       
                                    </div>
                                  
                                </div>
                              
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="bsc-tbl-st bg_white table_scroll" style={{width:"160vw"}}>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>SMSID</th>
                                            <th>SR_Name</th>
                                            <th>STBNo</th>
                                            <th>VCNo</th>
                                            <th>Lineman ID</th>
                                            <th>Lineman Name</th>
                                            <th>Invoice No</th>
                                            <th>From Date</th>
                                            <th>To Date</th>
                                            <th>Billed Amount</th>
                                            <th>Discount</th>
                                            <th>LCO Amount</th>
                                            <th>SGST</th>
                                            <th>UTGST</th>
                                            <th>CGST</th>
                                            <th>IGST</th>
                                            <th>KFC</th>
                                            <th>Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>18533820</td>
                                            <td>01332382162</td>
                                            <td>50393</td>
                                            <td>efsdf</td>
                                            <td>cxfcs, , NAGAON,NAGAON , ASSAM,Pin - 782002</td>
                                            <td>40745</td>
                                            <td>-104.901</td>
                                            <td>25/03/2019</td>
                                            <td>25/03/2019</td>
                                            <td>90000</td>
                                            <td>12500</td>
                                            <td>345060</td>
                                            <td>12</td>
                                            <td>45</td>
                                            <td>456</td>
                                            <td>90</td>
                                            <td>123</td>
                                            <td>100000</td>
                                            
                                        </tr>
                                        <tr>
                                        <td>18533820</td>
                                            <td>01332382162</td>
                                            <td>50393</td>
                                            <td>efsdf</td>
                                            <td>cxfcs, , NAGAON,NAGAON , ASSAM,Pin - 782002</td>
                                            <td>40745</td>
                                            <td>-104.901</td>
                                            <td>25/03/2019</td>
                                            <td>25/03/2019</td>
                                            <td>90000</td>
                                            <td>12500</td>
                                            <td>345060</td>
                                            <td>12</td>
                                            <td>45</td>
                                            <td>456</td>
                                            <td>90</td>
                                            <td>123</td>
                                            <td>100000</td>
                                        </tr>
                                        <tr>
                                        <td>18533820</td>
                                            <td>01332382162</td>
                                            <td>50393</td>
                                            <td>efsdf</td>
                                            <td>cxfcs, , NAGAON,NAGAON , ASSAM,Pin - 782002</td>
                                            <td>40745</td>
                                            <td>-104.901</td>
                                            <td>25/03/2019</td>
                                            <td>25/03/2019</td>
                                            <td>90000</td>
                                            <td>12500</td>
                                            <td>345060</td>
                                            <td>12</td>
                                            <td>45</td>
                                            <td>456</td>
                                            <td>90</td>
                                            <td>123</td>
                                            <td>100000</td>
                                        </tr>
                                        <tr>
                                        <td>18533820</td>
                                            <td>01332382162</td>
                                            <td>50393</td>
                                            <td>efsdf</td>
                                            <td>cxfcs, , NAGAON,NAGAON , ASSAM,Pin - 782002</td>
                                            <td>40745</td>
                                            <td>-104.901</td>
                                            <td>25/03/2019</td>
                                            <td>25/03/2019</td>
                                            <td>90000</td>
                                            <td>12500</td>
                                            <td>345060</td>
                                            <td>12</td>
                                            <td>45</td>
                                            <td>456</td>
                                            <td>90</td>
                                            <td>123</td>
                                            <td>100000</td>
                                        </tr>
                                        <tr>
                                        <td>18533820</td>
                                            <td>01332382162</td>
                                            <td>50393</td>
                                            <td>efsdf</td>
                                            <td>cxfcs, , NAGAON,NAGAON , ASSAM,Pin - 782002</td>
                                            <td>40745</td>
                                            <td>-104.901</td>
                                            <td>25/03/2019</td>
                                            <td>25/03/2019</td>
                                            <td>90000</td>
                                            <td>12500</td>
                                            <td>345060</td>
                                            <td>12</td>
                                            <td>45</td>
                                            <td>456</td>
                                            <td>90</td>
                                            <td>123</td>
                                            <td>100000</td>
                                        </tr>
                                        <tr>
                                        <td>18533820</td>
                                            <td>01332382162</td>
                                            <td>50393</td>
                                            <td>efsdf</td>
                                            <td>cxfcs, , NAGAON,NAGAON , ASSAM,Pin - 782002</td>
                                            <td>40745</td>
                                            <td>-104.901</td>
                                            <td>25/03/2019</td>
                                            <td>25/03/2019</td>
                                            <td>90000</td>
                                            <td>12500</td>
                                            <td>345060</td>
                                            <td>12</td>
                                            <td>45</td>
                                            <td>456</td>
                                            <td>90</td>
                                            <td>123</td>
                                            <td>100000</td>
                                        </tr>
                                        <tr>
                                        <td>18533820</td>
                                            <td>01332382162</td>
                                            <td>50393</td>
                                            <td>efsdf</td>
                                            <td>cxfcs, , NAGAON,NAGAON , ASSAM,Pin - 782002</td>
                                            <td>40745</td>
                                            <td>-104.901</td>
                                            <td>25/03/2019</td>
                                            <td>25/03/2019</td>
                                            <td>90000</td>
                                            <td>12500</td>
                                            <td>345060</td>
                                            <td>12</td>
                                            <td>45</td>
                                            <td>456</td>
                                            <td>90</td>
                                            <td>123</td>
                                            <td>100000</td>
                                        </tr>
                                        <tr>
                                        <td>18533820</td>
                                            <td>01332382162</td>
                                            <td>50393</td>
                                            <td>efsdf</td>
                                            <td>cxfcs, , NAGAON,NAGAON , ASSAM,Pin - 782002</td>
                                            <td>40745</td>
                                            <td>-104.901</td>
                                            <td>25/03/2019</td>
                                            <td>25/03/2019</td>
                                            <td>90000</td>
                                            <td>12500</td>
                                            <td>345060</td>
                                            <td>12</td>
                                            <td>45</td>
                                            <td>456</td>
                                            <td>90</td>
                                            <td>123</td>
                                            <td>100000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
          
           
        </div>
    )
}

export default Template;