import React from 'react';
import {
    Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card, Form, Row, Col,
    Dropdown, DropdownButton, Modal
} from 'react-bootstrap';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, SelectedDiv, SelectedPackage, FooterUl, FooterLi
} from './styled';
import { Redirect } from 'react-router-dom';
import logo from '../../../assets/logo-3.png';
import LoadingOverlay from 'react-loading-overlay';
import Checkboxs from 'muicss/lib/react/checkbox';
import { CSVLink, CSVDownload } from "react-csv";
import { FaPlus, FaCircle } from "react-icons/fa";
import {
    MdPowerSettingsNew,
    MdArrowBack
} from 'react-icons/md';
import './index.css';
import DialogBoxs from './DialogBoxs';
import API from '../../../Api';
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
import DateData from './Date';
import CSVReader from "react-csv-reader";
import loadinggif from '../../../assets/loding-gif.gif';
// import date from 'date-and-time';
import { samples } from './CSVSample';
import GeneralValues from '../../../GobelElements/Values';
import Loader from 'react-loader-spinner';
import * as Constants from '../../../constants';
import "react-datepicker/dist/react-datepicker.css";
export default class Instock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            isloggedOut: false,
            isShowLogOutWarning: false,
            isSpinnerVisible: false,
            casList: [],
            typeList: [],
            selectedCas: "",
            STBNumber: "",
            model: Constants.CAS.Dummy3[0].model,
            installDate: "",
            installRate: "",
            Type: "",
            isCSVOperation: false,
            numberOfRowsInCsv: 0,
            isShowSTBCreateWarning: false,
            selectedType: "",
            selectedDay: parseInt(new Date().getDate()),
            selectedMonth: parseInt(new Date().getMonth()) + 1,
            selectedYear: parseInt(new Date().getFullYear()),
            isNoVC: false,
            MACVCNumber: "",
            lcoList: JSON.parse(localStorage.getItem("lcolist")),
            modData: [],
            selectedLco: "",
            lcoSearchResult: [],
            isShowLcoList: false,
            lcoSearchText: "",
            isShowSuccessDialog: false,
            CSVDataList: [],
            CSVLength: 0,
            isShowCsvOperationWarningDialog: false,
            noOfDataGoingToProcess: 0,
            dataGoingToProcess: [],
            noOfcompletedData: 0,
            unProcessedData: [],
            isShowCSVOperationDialog: false,
            isShowUnProcessedDataListDialog: false,
            isEmptyCSVList: false,
            inStockListSearchText: "",
            inStockDataList: [],
            modelList: Constants.CAS.Dummy3,
            installDateValue: new Date()



        }
        this.getCasAndType();
        let arr = [];
        for (var i = 0; i < this.state.lcoList.length; i++) {
            arr.push({ id: this.state.lcoList[i].lconame + " " + this.state.lcoList[i].lcocode, "value": this.state.lcoList[i].lcocode, "name": this.state.lcoList[i].lconame })
        }
        this.state.modData = arr;
        console.log("data in kk", arr, this.state.modData);
        this.setState({ modData: arr });




    }
    handleCSVFileUpload = e => {
        var arrayOfData = [];
        console.log("csv", e);
        var len = e.length;
        var l = e[1];

        console.log("data", l[0]);
        for (var i = 1; i < len; i++) {
            console.log("length", e[i].length);
            if (e[i][0]) {


                var data = e[i];
                var data1 = {
                    SNo: data[0],
                    MACVCChipNumber: data[1],
                    Model: data[2],
                    InstallDate: data[3],
                    Installrate: data[4],
                    cas: data[5],
                    type: data[6],
                    toggleMode: true
                };

                arrayOfData.push(data1);
            } else {
                debugger;
            }
        }
        this.state.CSVDataList = arrayOfData;
        this.state.CSVLength = arrayOfData.length;

        this.setState({ CSVDataList: arrayOfData, CSVLength: arrayOfData.length });

        console.log("data in csv", this.state.CSVDataList, this.state.CSVLength, arrayOfData);






    }

    hindLogOutDialog() {
        this.setState({ isShowLogOutWarning: false });
    }
    hindSTBCreateWarning() {
        this.setState({ isShowSTBCreateWarning: false })
    }
    hindSuccessDialog() {
        this.setState({ isShowSuccessDialog: false })
    }
    hindCsvOperationWarningDialog() {
        this.setState({ isShowCsvOperationWarningDialog: false });
    }
    hindCSVOperationDialog() {
        this.setState({ isShowCSVOperationDialog: false, CSVDataList: [], CSVLength: 0, isCSVOperation: false, noOfcompletedData: 0 });
        var len = this.state.unProcessedData.length;
        if (len) {
            this.setState({ isShowUnProcessedDataListDialog: true });
        }
    }
    hindUnProcessedDataListDialog() {
        this.setState({ isShowUnProcessedDataListDialog: false, unProcessedData: [] });
    }


    getCasAndType() {
        this.setState({ isSpinnerVisible: true });
        const { lcoID, lcoPassword, domainName } = this.state;
        API.STBCasAndType(lcoID, lcoPassword, "stbcastype", domainName)
            .then((response) => response.json())
            .then((resonseJson) => {
                console.log("Repsonse STB Cas and Type", resonseJson, resonseJson[0].cas);
                this.setState({ isSpinnerVisible: false, casList: resonseJson[0].cas, typeList: resonseJson[0].type, selectedCas: resonseJson[0].cas[0], selectedType: resonseJson[0].type[0] });
            });

    }

    createSTBFunc() {
        this.setState({ isShowSTBCreateWarning: false, isSpinnerVisible: true });
        const { lcoID, lcoPassword, domainName, STBNumber, MACVCNumber, selectedCas, selectedType, selectedDay, selectedMonth, selectedYear, lcoSearchText, installRate, model } = this.state;
        var data = "" + parseInt(this.state.installDateValue.getFullYear()) + "-" + (parseInt(this.state.installDateValue.getMonth()) + 1) + "-" + parseInt(this.state.installDateValue.getDate());
        var MACVCNumbertext = this.state.isNoVC ? "None" : MACVCNumber
        API.createSTB(lcoID, lcoPassword, "createstb", STBNumber, MACVCNumbertext, model, selectedType, data, installRate, selectedCas, domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("respone", responseJson);
                if (responseJson.message) {
                    this.setState({
                        isSpinnerVisible: false,
                        selectedCas: this.state.casList[0],
                        selectedType: this.state.typeList[0],
                        STBNumber: "",
                        // model: Constants.CAS.NSTV_direct[0].model,
                        MACVCNumber: "",
                        selectedDay: parseInt(new Date().getDay()),
                        selectedMonth: parseInt(new Date().getMonth()) + 1,
                        selectedYear: parseInt(new Date().getFullYear()),
                        installRate: "0",
                        selectedLco: "",
                        isNoVC: false,
                        isShowSuccessDialog: true,
                        installDateValue: new Date()

                    });
                } else {
                    this.setState({
                        isSpinnerVisible: false,
                        selectedCas: this.state.casList[0],
                        selectedType: this.state.typeList[0],
                        STBNumber: "",
                        // model: Constants.CAS.NSTV_direct[0].model,
                        MACVCNumber: "",
                        selectedDay: parseInt(new Date().getDay()),
                        selectedMonth: parseInt(new Date().getMonth()) + 1,
                        selectedYear: parseInt(new Date().getFullYear()),
                        installRate: "",
                        selectedLco: "",
                        isNoVC: false,
                        EmptyError: responseJson.error,
                        installDateValue: new Date()

                    });
                }
            }).catch((error) => {
                console.log("Error", error);
            })

    }


    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />
        } else {
            return null;
        }
    }
    logOut() {

        localStorage.removeItem("userID");
        const username = localStorage.getItem("userID");
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }
    setCasType(e) {
        console.log("selected value", e.target.value);
        let cas = e.target.value;
        if (cas == "Dummy3") {
            this.setState({ selectedCas: cas, modelList: Constants.CAS.Dummy3, model: Constants.CAS.Dummy3[0].model });
        } else if (cas == "Dummy2") {
            this.setState({ selectedCas: cas, modelList: Constants.CAS.Dummy2, model: Constants.CAS.Dummy2[0].model });
        }  else if (cas == "Dummy1") {
            this.setState({ selectedCas: cas, modelList: Constants.CAS.Dummy1, model: Constants.CAS.Dummy1[0].model });
        } else if (cas == "Phandos_OTT") {
            this.setState({ selectedCas: cas, modelList: Constants.CAS.Phandos_OTT, model: Constants.CAS.Phandos_OTT[0].model });
        }else {
            this.setState({ selectedCas: cas, modelList: [], model: "" });
        }
    }
    setModel(e) {
        console.log("Model", e.target.value);
        this.setState({ model: e.target.value });
    }
    handleDateChange(date) {
        console.log("date", date)
    }
    toggleInCSV() {
        var data = this.state.isCSVOperation;
        this.setState({ isCSVOperation: !data, CSVDataList: [], CSVLength: 0 });

    }
    onSelectLco(lcoID) {
        this.setState({ isShowLcoList: false, lcoSearchText: lcoID });

    }
    checkAnyEmptyfield() {
        const { STBNumber, installRate, model } = this.state;

        if (STBNumber && model) {
            this.setState({ isShowSTBCreateWarning: true, EmptyError: "" });
        } else {
            this.setState({ EmptyError: "Empty Field Found !" });
        }
    }

    checkToShowTheLcoList(text) {
        var len = text.length;
        console.log("length", len, this.state.modData);
        if (len) {
            var dummy = [];
            var length = this.state.lcoList.length;
            var searchText = text.toString().toLowerCase();
            console.log("data", length, len);
            for (var i = 0; i < length; i++) {

                var val = ""
                val = this.state.modData[i].id.toString().toLowerCase();
                // var lower = val.toString();
                // console.log("lower",val,searchText);

                var n = val.search(searchText);
                if (n != -1) {
                    dummy.push(this.state.modData[i]);
                }
            }
            console.log("dummy", dummy);
            this.setState({ isShowLcoList: true, lcoSearchResult: dummy });
        } else {
            this.setState({ isShowLcoList: false });
        }
    }

    toggleChangeInCSV(i) {
        var oldData = this.state.CSVDataList;
        var oldToggleMode = oldData[i].toggleMode;
        oldData[i].toggleMode = !oldToggleMode;
        this.setState({ CSVDataList: oldData });


    }
    recursiveFunc(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName } = this.state;
            API.createSTB(lcoID, lcoPassword, "createstb", data.SNo, data.MACVCChipNumber, data.Model, data.type, data.InstallDate, data.Installrate, data.cas, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    var n = this.state.noOfcompletedData;
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {


                        if ((n + 1) <= total) {
                            this.recursiveFunc(this.state.dataGoingToProcess[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) <= total) {
                            this.recursiveFunc(this.state.dataGoingToProcess[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
        }
    }
    csvData = [
        ["STB",
            "VC",
            "Model",
            "InstallDate (yyyy-mm-dd)",
            "Installrate",
            "cas",
            "type (SD/HD)",],

    ];

    bulkSTBCreation() {
        this.setState({ isShowCsvOperationWarningDialog: false });
        var data = this.state.CSVDataList;
        var dummyNoOfDataGoingToProcess = 0;
        var dummyDataGoingToProcess = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].toggleMode) {
                dummyDataGoingToProcess.push(data[i]);
            }
        }
        this.state.dataGoingToProcess = dummyDataGoingToProcess;
        this.state.noOfDataGoingToProcess = dummyDataGoingToProcess.length;
        this.setState({ isShowCSVOperationDialog: true });
        this.recursiveFunc(this.state.dataGoingToProcess[0]);


    }
    isShowCSVOption() {
        if (this.state.isCSVOperation) {
            return (
                <div>
                    <CSVReader

                        style={{ border: "2px solid #ececec", padding: 5, }}
                        cssClass="react-csv-input"
                        onFileLoaded={e => { this.handleCSVFileUpload(e) }}
                    />
                    <label style={{ fontSize: 15 }}>Total STB in CSV: <label style={{ fontSize: 18, fontWeight: "bold", color: "#d61818" }}>{this.state.CSVLength}</label></label>

                    <Button
                        onClick={() => {
                            if (this.state.CSVDataList.length) {
                                this.setState({ isShowCsvOperationWarningDialog: true, isEmptyCSVList: "" })
                            } else {
                                this.setState({ isEmptyCSVList: "Empty List" });
                            }
                        }}
                        style={{ backgroundColor: "green", borderColor: "green", fontSize: 15, fontWeight: "bold", color: "white",  marginBottom: 15,marginLeft:10,marginTop:15 }}
                    >Create</Button>

                    <CSVLink
                        filename={`STBCreation(${this.state.lcoID}).csv`}
                        data={this.csvData}>Download sample file</CSVLink>
                </div>
            );
        } else {
            return null;
        }
    }
    showContain() {
        if (!this.state.isCSVOperation) {
            return (
                <Form className="form-div" style={{ marginTop: 20 }}>
                    <h4 style={{ fontSize: 15, fontWeight: "bold", alignSelf: "center", color: "red" }}>{this.state.EmptyError}</h4>
                    <h3>Form</h3>

                    <Row>
                        <Col xs={12} md={4} lg={3} sm={6} >
                            <Form.Group>
                                <Form.Label>Choose DRM <span>*</span></Form.Label>
                                <select title="DRM List" style={{ width: "80%" }}
                                    onChange={(e) => { this.setCasType(e) }}

                                >
                                    {this.state.casList.map((item, i) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        );
                                    })}
                                </select>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={3} sm={6} >
                            <Form.Group>
                                <Form.Label>STB <span>*</span></Form.Label>
                                <Form.Control type="text" placeholder="STB Number" onChange={(e) => { this.setState({ STBNumber: e.target.value }) }}

                                    value={this.state.STBNumber} />
                            </Form.Group>

                        </Col>

                        <Col xs={12} md={4} lg={3} sm={6} >
                            <Form.Group>
                                <Form.Label style={{ display: "line-block" }}>VC<span>*      </span>
                                    <Form.Check type="checkbox" label="No VC" checked={this.state.isNoVC} onClick={(e) => { this.setState({ isNoVC: !this.state.isNoVC }) }} style={{ display: "inherit" }} />
                                </Form.Label>

                                <Form.Control type="text" placeholder="MACVC Chip Number" onChange={(e) => {

                                    this.setState({ MACVCNumber: e.target.value });
                                }}

                                    value={this.state.isNoVC ? "None" : this.state.MACVCNumber} />
                            </Form.Group>

                        </Col>
                        <Col xs={12} md={4} lg={3} sm={6} >
                            <Form.Group>
                                <Form.Label>Model <span>*</span></Form.Label>
                                <select title="Model" style={{ width: "90%" }}
                                    onChange={(e) => { this.setModel(e) }}

                                >
                                    {this.state.modelList.map((item, i) => {
                                        return (
                                            <option
                                                onClick={() => { console.log("item", item.type) }}
                                                value={item.model}>{item.model}</option>
                                        );
                                    })}
                                </select>
                            </Form.Group>

                        </Col>
                        <Col xs={12} md={4} lg={3} sm={6} >
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Install Date</Form.Label>
                                <div>
                                    <DatePicker

                                        onChange={(e) => { this.setState({ installDateValue: e }) }}
                                        selected={this.state.installDateValue}
                                        value={this.state.installDateValue}

                                    />
                                </div>

                                {/* <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    <div style={{ float: "left" }}>
                                        <h4 style={{ fontSize: 12 }}>Day</h4>
                                        <select title="DRM List"
                                            value={this.state.selectedDay}
                                            style={{ width: "100%" }}
                                            onChange={(e) => { this.setState({ selectedDay: e.target.value }) }}

                                        >
                                            {DateData.day.map((item, i) => {
                                                return (
                                                    <option value={item}>{item}</option>
                                                );
                                            })}


                                        </select>
                                    </div>
                                    <div style={{ float: "left" }}>
                                        <h4 style={{ fontSize: 12 }}>Month</h4>
                                        <select
                                            value={this.state.selectedMonth}
                                            title="DRM List" style={{ width: "100%" }}
                                            onChange={(e) => { this.setState({ selectedMonth: e.target.value }) }}

                                        >
                                            {DateData.month.map((item, i) => {
                                                return (
                                                    <option value={item}>{item}</option>
                                                );
                                            })}


                                        </select>
                                    </div>
                                    <div style={{ float: "left" }}>
                                        <h4 style={{ fontSize: 12, marginLeft: 5 }}>year</h4>
                                        <select
                                            value={this.state.selectedYear}
                                            title="DRM List" style={{ width: "100%" }}
                                            onChange={(e) => { this.setState({ selectedYear: e.target.value }) }}

                                        >
                                            {DateData.year.map((item, i) => {
                                                return (
                                                    <option value={item}>{item}</option>
                                                );
                                            })}


                                        </select>
                                    </div>
                                </div> */}

                            </Form.Group>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3} sm={6} >
                            <Form.Group>
                                <Form.Label>Install Rate <span>*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter Rate" onChange={(e) => { this.setState({ installRate: e.target.value }) }}

                                    value={this.state.installRate} />
                            </Form.Group>

                        </Col> */}
                        <Col xs={12} md={4} lg={3} sm={6} >
                            <Form.Group>
                                <Form.Label>Type</Form.Label>

                                <select title="Type List" style={{ width: "80%", display: "block" }}
                                    onChange={(e) => { this.setState({ selectedType: e.target.value }) }}

                                >
                                    {this.state.typeList.map((item, i) => {
                                        return (
                                            <option value={item}>{item}</option>
                                        );
                                    })}
                                </select>
                            </Form.Group>

                        </Col>

                        {/* <Col xs={12} md={4} lg={3} sm={6} >
                            <Form.Group>
                                <Form.Label>Lco Search</Form.Label>
                                <Form.Control type="text" placeholder="Lco Search" maxLength="10" minLength="10" required onChange={(e) => { 
                                    this.setState({lcoSearchText:e.target.value});
                                    this.checkToShowTheLcoList(e.target.value)}}

                                    value={this.state.lcoSearchText} />

<div
             onScrollCapture={(e)=>{console.log("e",e)}}
             style={{
               position: "absolute",
               display: this.state.isShowLcoList ? "block":"none",
               listStyle: "none",
               backgroundColor: "white",
               padding: 0,
               boxShadow: "0px 1px 3px grey",
               zIndex: 9,
               width:"20vw",  
               top: 50,
               overflow:"auto",
               maxHeight:"30vh",
               marginLeft:0,
               marginTop:15
             }}
            //  onScroll={(e)=>console.log("scrolling",e)}
             >
             <label style={{fontSize:15,fontWeight:"bold",color:"rgb(26, 172, 195)",marginLeft:10}}>Lco List:</label>
             <ul 
              
             style={{listStyle:"none",padding:10}}>
               {this.state.lcoSearchResult.map((item,i)=>{
                 
                 return(
                   <li 
                   onScroll={(e)=>console.log("scrolling",e)}
                   onClick={()=>{this.onSelectLco(item.value)}}
                   style={{fontSize:15,color:"black",lineHeight:2,borderBottom:"1px solid rgba(26, 172, 195,0.5)",cursor:"pointer"}}>
                   {item.name} <span style={{fontSize:12,color:"#d61818",paddingLeft:1}}
                   >({item.value})</span></li>
                 );
               })}
             </ul>

             </div>
                            </Form.Group>

                        </Col> */}
                    </Row>
                    <div style={{ textAlign: "end", marginRight: 20 }}>
                        <Button
                            onClick={() => { this.checkAnyEmptyfield() }}
                            // style={{ textAlign: "flex-end" }}
                            // variant="success"
                            style={{ backgroundColor: GeneralValues.mustard, borderWidth: 0, textAlign: "flex-end" }}
                        >
                            Submit
</Button>
                    </div>


                </Form>
            );
        } else {
            return (

                <table className="table table-striped">
                    <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>{this.state.isEmptyCSVList}</div>
                    <thead>
                        <th style={{ width: "3%" }}></th>
                        <th> STB</th>
                        <th style={{ width: "17%" }}>VC</th>


                        <th>Model</th>

                        <th>Install Date (yyyy-mm-dd)</th>
                        <th>Install Rate</th>
                        <th>DRM</th>
                        <th>Type</th>

                    </thead>
                    <tbody className="total-records">
                        {this.state.CSVDataList.map((data, i) => {
                            return (
                                <tr>
                                    <td style={{ width: "3%" }}>


                                        <Checkboxs
                                            style={{ fontSize: 20, color: "green" }}
                                            color="green"

                                            onChange={() => { this.toggleChangeInCSV(i) }}
                                            checked={data.toggleMode} />
                                    </td>



                                    <td>{data.SNo}</td>
                                    <td style={{ width: "17%" }}>{data.MACVCChipNumber}</td>
                                    <td>{data.Model}
                                    </td>
                                    <td>{data.InstallDate}</td>

                                    <td>{data.Installrate}</td>
                                    <td>{data.cas}</td>
                                    <td>{data.type}</td>
                                    {/* <td>
                                        <div className="pack-div">
                                           
                                            <ul>
                                                <a>{data2.map((datap, i) => {

                                                    return (



                                                        <li>{datap}</li>


                                                    )

                                                })}

                                                </a>
                                            </ul>
                                        </div>
                                   
                                </td> */}

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            );
        }
    }
    stockListSearch(text) {

        var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
        if (text.length > 2) {
            this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

            API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    if (responseJson.count) {


                        this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                        console.log("In stock list", this.state.inStockDataList);
                    } else {
                        this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                    }
                });
        } else {
            this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
        }
    }
    getSTBDetails(item) {
        console.log("item", item);
        if (item.Customer) {
            this.setState({ spinnerVisible: true });
            const { lcoID, lcoPassword, domainName, searchText } = this.state;
            API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("response", responsejson);
                    if (responsejson.message == "no data") {
                        this.setState({ spinnerVisible: false });
                    } else {
                        localStorage.setItem("currentlco", item.Lcocode);
                        this.setState({ spinnerVisible: false });
                        this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
                    }
                });
        }
        else {
            this.setState({ isShowNodataDialog: true })
        }

    }

    render() {
        return (
            <Rootcontainer
            // onScroll={(e) => this.handleScroll(e)}
            // ref={this.nav}
            //onScrollCapture={(e)=>{  this.nextPageData(this.state.customerList, (this.state.pageCount + 1))}}
            >

<LoadingOverlay


className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
overlay={true}
active={this.state.isSpinnerVisible}
spinner={<img src={loadinggif} className="spinnerContent" color="green" />}
styles={{ content: { width:"80%",minHeight:"65%" } }}

></LoadingOverlay>


              

                {/* <Loader
                            visible={this.state.isShowStockLoader}
                            style={{ textAlign: "center", marginBottom: 10, }}
                            type="ThreeDots"
                            color="#00BFFF"
                            height="10"
                            width="20"
                        /> */}

                <MainContainer>
                    <div
                    // style={{ textAlign: "end" }}
                    >

                        <div style={{ marginLeft: "65%", postion: "relative", marginTop: 10 }}>
                            <Checkboxs
                                style={{ marginLeft: "89%" }}
                                textClassName="bouncechk__text"
                                className="bouncechk__customClassName "
                                text='CSV Operation'
                                modifierClass='green'
                                onChange={() => { this.toggleInCSV() }}
                                checked={this.state.isCSVOperation}
                                style={{ display: "inline", float: 'left', marginRight: 10 }}
                            />
                            <div style={{ display: "inline", float: 'left' }} >CSV Operation</div>
                            {this.isShowCSVOption()}
                        </div>
                    </div>
                    {this.showContain()}








                </MainContainer>
                <DialogBoxs
                    isShowLogOutWarning={this.state.isShowLogOutWarning}
                    logOut={this.logOut.bind(this)}
                    hindLogOutDialog={this.hindLogOutDialog.bind(this)}
                    hindSTBCreateWarning={this.hindSTBCreateWarning.bind(this)}
                    isShowSTBCreateWarning={this.state.isShowSTBCreateWarning}
                    createSTBFunc={this.createSTBFunc.bind(this)}
                    hindSuccessDialog={this.hindSuccessDialog.bind(this)}
                    isShowSuccessDialog={this.state.isShowSuccessDialog}
                    hindCsvOperationWarningDialog={this.hindCsvOperationWarningDialog.bind(this)}
                    isShowCsvOperationWarningDialog={this.state.isShowCsvOperationWarningDialog}
                    hindCSVOperationDialog={this.hindCSVOperationDialog.bind(this)}
                    bulkSTBCreation={this.bulkSTBCreation.bind(this)}
                    noOfcompletedData={this.state.noOfcompletedData}
                    noOfDataGoingToProcess={this.state.noOfDataGoingToProcess}
                    isShowCSVOperationDialog={this.state.isShowCSVOperationDialog}
                    hindUnProcessedDataListDialog={this.hindUnProcessedDataListDialog.bind(this)}
                    isShowUnProcessedDataListDialog={this.state.isShowUnProcessedDataListDialog}
                    unProcessedData={this.state.unProcessedData}

                />


                {this.logOutFunc()}

            </Rootcontainer>

        );
    }
}
