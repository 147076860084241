import React, { useEffect, useState } from "react";

import './index.css';
import './bootstrap.min.css';
import './style.css';
import { Outlet, Link } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { MdSearch } from 'react-icons/md';
import { MdArrowCircleUp } from 'react-icons/md';
import { MdNotifications } from 'react-icons/md';
import { MdOutlineDashboard } from 'react-icons/md';
import { MdOutlineClose } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdShoppingBag } from 'react-icons/md';
import { MdTransferWithinAStation } from 'react-icons/md';

import { MdApps } from 'react-icons/md';
import LoadingOverlay from 'react-loading-overlay';
import loadinggif from '../../../assets/loding-gif.gif';
import Details from '../../../GobelElements/Values';
import axios from 'axios';
import API from "../../../Api";
import "./index.css";
import {
    Button,
    ButtonGroup,
    Container,
    ButtonToolbar,
    Jumbotron,
    Card
} from "react-bootstrap";
import Drawer from "react-drag-drawer";

const Template = () => {
    const [isShowOthers, setIsShowOthers] = useState(false);
    const [isShowStockOperations, setIsShowStockOperations] = useState(false);
    const [isShowReports, setIsReports] = useState(false);
    const [lcoID, setLcoID] = useState(localStorage.getItem("userID"));
    const [lcoPassword, setLcoPassword] = useState(localStorage.getItem("userPassword"));
    const [domainName, setDomainName] = useState(localStorage.getItem("domain"));
    const [CurrentLcoCode, setCurrentLcoCode] = useState(localStorage.getItem("currentlco"));
    const [isStockOperation, setIsStockOperation] = useState(localStorage.getItem("isShowStock"));
    
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [isShowPackageDialog, setIsShowPackageDialog] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [lcocode, setLcocode] = useState("");
    const [lcoList, setLcoList] = useState([]);
    const [searchLcoList, setSearchLcoList] = useState([]);
    const [selectedLco, setSelectedLco] = useState({});
    const [isShowLcoListDialog, setIsShowLcoListDialog] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        getLCO_Details();
        let isStock = localStorage.getItem("isShowStock");
        if (isStock) {
            console.log("LCo list", JSON.parse(localStorage.getItem("lcolist")));
            setLcoList(JSON.parse(localStorage.getItem("lcolist")));
            setSearchLcoList(JSON.parse(localStorage.getItem("lcolist")))
        }

    }, [])
    const getLCO_Details = () => {
        debugger;

        setSpinnerVisible(true);
        API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
            .then(response => response.json())
            .then(responsejson => {

                setSpinnerVisible(false);
                setLcocode(responsejson.lcocode);
                console.log("isStockOperation",isStockOperation);
                debugger;
               
                if (!isStockOperation) {
                 setCurrentLcoCode(responsejson.lcocode)
                }

            });
    }

    const getDataFrom = () => {
        if(startDate && endDate && Object.keys(selectedLco).length ){
            setErrorMessage("");
        console.log(`${Details.url}/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=disconnected&status=All&lcocode=${CurrentLcoCode}&d1=${startDate}&d2=${endDate}&json=yes`);
        debugger;
        setSpinnerVisible(true);
        axios({
            url: `${Details.url}/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=disconnected&status=All&lcocode=${CurrentLcoCode}&d1=${startDate}&d2=${endDate}&json=yes`,
            method: 'GET',
            // responseType: 'blob', // important
        }).then((response) => {
            console.log("data", typeof response.data);
            debugger;
            const regex = /NAN/gi;
            // let stringData = response.data.replaceAll(regex, `""`)
            // console.log("data", stringData);
            setSpinnerVisible(false);
            debugger;
            setDataList(response.data);
            // debugger;
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', `${name}.xlsx`);
            // link.click();
            // window.URL.revokeObjectURL(url);
            // this.setState({ isSpinnerVisible: false });
        }).catch((error) => {
            setSpinnerVisible(false);
            console.log("error", error);
            // this.setState({ isSpinnerVisible: false, isEmptyError: "Unable to process !" });

        });
    }else{
        setErrorMessage("Please fill all fields!");
    }
    }
    const download = () => {
        if(startDate && endDate && Object.keys(selectedLco).length ){
            setErrorMessage("");
        console.log(`${Details.url}/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=disconnected&status=All&lcocode=${CurrentLcoCode}&d1=${startDate}&d2=${endDate}`);
        debugger;
        setSpinnerVisible(true);
        axios({
            url: `${Details.url}/api/reportsdownload/?username=${lcoID}&password=${lcoPassword}&command=disconnected&status=All&lcocode=${CurrentLcoCode}&d1=${startDate}&d2=${endDate}`,
            method: 'GET',
             responseType: 'blob', // important
        }).then((response) => {
           
            console.log("data", response);
            debugger;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `SubscriberPackage.xlsx`);
            link.click();
            window.URL.revokeObjectURL(url);
            //this.setState({ isSpinnerVisible: false });
            setSpinnerVisible(false)
        }).catch((error) => {
            setSpinnerVisible(false);
            console.log("error", error);
            // this.setState({ isSpinnerVisible: false, isEmptyError: "Unable to process !" });

        });
    }else{
        setErrorMessage("Please fill all fields!");
    }
    }
    const handleFilter = (text) => {
        console.log(text);
       
        if (text !== "") {
          let searchedLcoList = lcoList.filter((item) =>
           (item.lconame.toLowerCase().includes(text.toLowerCase()) || item.lcocode.toLowerCase().includes(text.toLowerCase())) ? item : null
          );
          setSearchLcoList(searchedLcoList);
    
        } else {
            setSearchLcoList(lcoList);
        }
      };
    return (
        <div>
            <LoadingOverlay

                className={spinnerVisible ? "displaySpinner" : ""}
                overlay={true}
                active={spinnerVisible}
                spinner={<img src={loadinggif} className="spinnerContent" color="green" />}
                styles={{ content: { width: "80%", minHeight: "65%" } }}
            ></LoadingOverlay>



            <div className="breadcomb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="breadcomb-list space_align">
                                <div class="col-lg-12 col_lg_ctrl">
                                    <div class="form-example-int form-example-st">
                                        <label style={{color:"red"}}>{errorMessage}</label>
                                        <div className="row">

                                            <div class="col-lg-3">
                                                <div class="nk-int-st">
                                                    <label>From Date</label>
                                                </div>
                                                <div class="nk-int-st">
                                                    <input
                                                        onChange={(e) => {
                                                            console.log(e.target.value);
                                                            setStartDate(e.target.value)
                                                        }}
                                                        style={{ color: "#000" }} type="date" class="form-control form_gray input-sm" />
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="nk-int-st">
                                                    <label>To Date</label>
                                                </div>
                                                <div class="nk-int-st">
                                                    <input
                                                        onChange={(e) => {
                                                            console.log(e.target.value);
                                                            setEndDate(e.target.value)
                                                        }}
                                                        style={{ color: "#000" }} type="date" class="form-control form_gray input-sm" />
                                                </div>
                                            </div>
                                          {isStockOperation ?   <div class="col-lg-3">
                                                <div class="nk-int-st">
                                                    <label>Select LCO</label>
                                                </div>
                                                <div class="nk-int-st">
                                                    <input
                                                        onChange={(e) => {
                                                            console.log(e.target.value);
                                                            setEndDate(e.target.value)
                                                        }}
                                                        placeholder="...."
                                                        onClick={() => {
                                                            setIsShowLcoListDialog(!isShowLcoListDialog);
                                                        }}
                                                        value={selectedLco ? selectedLco.lcocode : "...."}
                                                        style={{ color: "#000" }} type="button" class="form-control form_gray input-sm" />
                                                </div>
                                            </div>:null}

                                            <div class="col-lg-3">
                                                <div class="nk-int-st">
                                                    <label>Lineman</label>
                                                </div>
                                                <div class="nk-int-st">
                                                    <select style={{ color: "#000" }} className="form-control form_black">
                                                        <option>
                                                            All Line Men
                                                        </option>
                                                        <option>
                                                            3 Star Cable Network (ID - 50393)
                                                        </option>
                                                    </select>
                                                </div>

                                            </div>
                                            <div class="col-lg-3">
                                                <button
                                                    onClick={() => {
                                                        getDataFrom();
                                                    }}
                                                    style={{ marginTop: 10 }} class="btn btn-success notika-btn-success">Show Report</button>
                                            </div>
                                            <div class="col-lg-3">
                                                <button
                                                    onClick={() => {
                                                        download();
                                                    }}
                                                    style={{ marginTop: 10 }} class="btn btn-primary notika-btn-primary">Download</button>
                                            </div>


                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="bsc-tbl-st bg_white table_scroll" style={{ width: "160vw", height: "40vh" }}>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ fontSize: 12 }}>STB</th>
                                            <th style={{ fontSize: 12 }}>MACVCC</th>
                                            <th style={{ fontSize: 12 }}>Model</th>
                                            <th style={{ fontSize: 12 }}>Lco Code</th>
                                            <th style={{ fontSize: 12 }}>Status</th>
                                            <th style={{ fontSize: 12 }}>Customer Number</th>
                                            <th style={{ fontSize: 12 }}>Name</th>
                                            <th style={{ fontSize: 12 }}>Address</th>
                                            <th style={{ fontSize: 12 }}>Base</th>
                                            <th style={{ fontSize: 12 }}>Cas</th>
                                            <th style={{ fontSize: 12 }}>Product</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataList.map((data, i) => {
                                            return (
                                                <tr>
                                                    <td style={{ fontSize: 12 }}>{data.STB}</td>
                                                    <td style={{ fontSize: 12 }}>{data.MACVCChipNumber}</td>
                                                    <td style={{ fontSize: 12 }}>{data.Model}</td>
                                                    <td style={{ fontSize: 12 }}>{data.Lcocode}</td>
                                                    <td style={{ fontSize: 12 }}>{data.status}</td>
                                                    <td style={{ fontSize: 12 }}>{data.Customernum}</td>
                                                    <td style={{ fontSize: 12 }}>{data.Customer}</td>
                                                    <td style={{ fontSize: 12 }}>{data.Address}</td>
                                                    <td style={{ fontSize: 12 }}>{data.base}</td>
                                                    <td style={{ fontSize: 12 }}>{data.cas}</td>
                                                    <td style={{ fontSize: 12 }}>{data.Product}</td>

                                                </tr>


                                            )
                                        })}




                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Drawer
                open={isShowLcoListDialog}
                // onRequestClose={this.toggle}
                direction="left"
            >
                <Card>
                    <Card.Body className="pl-3 pr-3">
                        <div style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>LCO</div>
                        <input
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                handleFilter(e.target.value);
                            }}
                            value={searchText}
                            style={{ color: "#000",marginTop:10}} type="type" class="form-control form_gray input-sm" />

                        <ul

                            style={{ listStyle: "none", padding: 10, height: "45vh",overflow:"auto",marginTop:10}}>
                            {searchLcoList.map((item, i) => {

                                return (
                                    <li
                                        onScroll={(e) => console.log("scrolling", e)}
                                        onClick={() => { 
                                            setIsShowLcoListDialog(!isShowLcoListDialog);
                                            setSelectedLco(item);
                                            setCurrentLcoCode(item.lcocode);
                                            
                                         }}
                                        style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                        {item.lconame} <span style={{ fontSize: 12, color: "#d61818", paddingLeft: 1 }}
                                        >({item.lcocode})</span></li>
                                );
                            })}
                        </ul>
                        <Button
                            style={{ backgroundColor: "red", borderColor: "red" }}
                            className="mt-3 pull-right"
                            onClick={() => {
                                setIsShowLcoListDialog(false);
                            }}
                        >
                            cancel
                        </Button>
                        {/* <Button
                            style={{
                                backgroundColor: "green",
                                borderColor: "green",
                                marginRight: 15
                            }}
                            className="mt-3 pull-right"
                            onClick={() => {
                                setIsShowLcoListDialog(false);
                            }}
                        >
                            OK
                        </Button> */}


                    </Card.Body>
                </Card>
            </Drawer>



        </div>
    )
}

export default Template;